import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { censusPageFilters } from "../schemas/censusPage";
import { censusPageSorts } from "../schemas/censusPage";
import { censusPageLabels } from "../schemas/censusPage";
import { CensusPage } from "../schemas/censusPage";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { padZeroes } from "./CensusPageDetail";
import Stack from "../../shared/Stack";

export const getCensusPageDisplayName = (record: CensusPage) =>
  `${record.reel}-${padZeroes(record.page, 4)}`;

export function renderCensusPageHit(record: CensusPage) {
  const displayName = getCensusPageDisplayName(record);

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <Stack direction="row">
        <PText>Census Year: {record.year}</PText>
        <PText>{record.counties.map((cty) => cty.value).join(", ")}</PText>
      </Stack>
    </>
  );
}

export default function CensusPageSearchPage(
  props: BaseSearchPageComponentProps<CensusPage>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={censusPageLabels}
          sortFields={censusPageSorts}
          filterFields={censusPageFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<CensusPage>(props, "census/pages")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "census/pages")
          }
          recordName="Census Page"
          contextualPrefs={props.contextualPrefs}
          renderSearchHit={{
            render: renderCensusPageHit,
            hitRenderMedia: "image",
            accessibilityLabel: (record) =>
              `View details for census page ${getCensusPageDisplayName(
                record
              )}`,
          }}
        />
      </Layout>
    </Page>
  );
}
