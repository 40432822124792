import { v4 as uuid } from "uuid";
import * as searchutils from "./searchutils";

import { Select, TextField } from "@shopify/polaris";
import Stack from "../../shared/Stack";

interface FilterComponentProps {
  selectedField: string;
  filterFields: searchutils.SearchFilterFields;
  label?: string;
  labelInline?: boolean;
  labelHidden?: boolean;
  disabled?: boolean;
}

interface FilterFieldSelectProps extends FilterComponentProps {
  onFieldSelect: (selected: string) => void;
}

/**
 * Provides a selector for a SearchFilterFields config.
 * @param selectedField The field to create options for.
 * @param filterFields The SearchFilterFields config.
 * @param onFieldSElect Function to call when the field is selected.
 * @param label Optional label override, defaults to "Available filter fields".
 * @param labelHidden Pass to hide the label.
 * @param labelInline Pass to put the label into the selector.
 * @param disabled Pass to disable the input.
 * @returns
 */
export function FilterFieldSelect({
  selectedField,
  filterFields,
  onFieldSelect,
  label,
  labelInline,
  labelHidden,
  disabled,
}: FilterFieldSelectProps) {
  return (
    <Select
      label={label || "Available filter fields"}
      labelInline={labelInline}
      labelHidden={labelHidden}
      disabled={disabled}
      placeholder="select field"
      value={selectedField}
      options={Object.entries(filterFields).map(([k, field]) => ({
        label: field.label,
        value: k,
      }))}
      onChange={onFieldSelect}
    />
  );
}

interface FilterOperatorSelectProps extends FilterComponentProps {
  operator: searchutils.FilterParamOperator;
  setOperator: (op: searchutils.FilterParamOperator) => void;
  setTerm?: (term: string) => void;
}

/**
 * Provides an appropriate selector of filter operation options for the selected
 * field.
 * @param selectedField The field to create options for.
 * @param filterFields The SearchFilterFields config.
 * @param operator The FilterParamOperator variable.
 * @param setOperator Function to call when the operator is changed.
 * @param setTerm Function to change the term variable, pass if you want this
 * component to be able to change the term in the case of selecting the isnull or
 * notnull operators (which have the term embedded in them).
 * @param label Optional label override, defaults to "Operation".
 * @param labelHidden Pass to hide the label.
 * @param labelInline Pass to put the label into the selector.
 * @param disabled Pass to disable the input.
 * @returns FilterOperatorSelect component.
 */
export function FilterOperatorSelect({
  selectedField,
  filterFields,
  operator,
  setOperator,
  setTerm,
  label,
  labelHidden,
  labelInline,
  disabled,
}: FilterOperatorSelectProps) {
  return (
    <Select
      label={label || "Operation"}
      labelHidden={labelHidden}
      labelInline={labelInline}
      value={operator}
      disabled={disabled}
      options={searchutils.getOperatorOptionsForFieldAndType(
        selectedField,
        filterFields,
      )}
      onChange={(selected) => {
        setOperator(selected as searchutils.FilterParamOperator);
        if (["isnull", "notnull"].includes(selected) && setTerm) {
          setTerm("NULL");
        }
      }}
    />
  );
}

interface FilterTermInputProps {
  term: string | undefined;
  setTerm: (value: string) => void;
  label?: string;
  labelHidden?: boolean;
  disabled?: boolean;
}

/**
 * General term input used for filters and strings
 * @param term The term to use as the value.
 * @param setTerm The function to call when the term is changed.
 * @param label Optional label override, defaults to "Filter term".
 * @param labelHidden Pass to hide the label.
 * @param disabled Pass to disable the input.
 * @returns FilterTermInput component.
 */
export function FilterTermInput({
  term,
  setTerm,
  label,
  labelHidden,
  disabled,
}: FilterTermInputProps) {
  return (
    <TextField
      type="text"
      value={term}
      label={label || "Filter value"}
      onChange={(value) => setTerm(value)}
      labelHidden={labelHidden}
      disabled={disabled}
      autoComplete="off"
    />
  );
}

// Specialized term inputs:
/**
 * Term input used for simple boolean filters.
 * @param term The term to use as the value.
 * @param setTerm The function to call when the term is changed.
 * @param label Optional label override, defaults to "Filter term".
 * @param labelHidden Pass to hide the label.
 * @param disabled Pass to disable the input.
 * @returns FilterBooleanTermInput component.
 */
export function FilterBooleanTermInput({
  term,
  setTerm,
  label,
  labelHidden,
  disabled,
}: FilterTermInputProps) {
  return (
    <Select
      label={label || "select: "}
      labelInline
      labelHidden={labelHidden}
      value={term}
      options={["Yes", "No"]}
      onChange={(value) => setTerm(value)}
      placeholder=" "
      disabled={disabled}
    />
  );
}

export interface FilterSelectTermInputProps extends FilterTermInputProps {
  validTerms?: any[];
  labelInline?: boolean;
  placeholder?: string;
}

/**
 * Term input used for filter with values selected from a pre-generated list.
 * @param term The term to use as the value.
 * @param setTerm The function to call when the term is changed.
 * @param label Optional label override, defaults to "Filter term".
 * @param labelHidden Pass to hide the label.
 * @param disabled Pass to disable the input.
 * @param validTerms The list of terms to use as the options in the selector.
 * @param labelInline Pass to put the label into the selector.
 * @param placeholder Sets the placeholder string to use if the selected term is
 * undefined.
 * @returns FilterSelectTermInput component.
 */
export function FilterSelectTermInput({
  term,
  setTerm,
  validTerms,
  label,
  labelInline,
  labelHidden,
  disabled,
  placeholder,
}: FilterSelectTermInputProps) {
  return (
    <Select
      label={label || "Select term"}
      labelInline={labelInline}
      labelHidden={labelHidden}
      value={term}
      options={validTerms}
      onChange={(value) => setTerm(value)}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

export interface FilterDatePartTermInputProps {
  setDay: (value: number) => void;
  setMonth: (value: number) => void;
  setYear: (value: number) => void;
  day?: number;
  month?: number;
  year?: number;
  yearMinimum?: number;
  stackDirection?: "row" | "column";
  disabled?: boolean;
}

/**
 * Term input for dates that can be entered in parts (i.e. there's a field for day,
 * month, and year).
 * @param setDay Function to call when the day changes.
 * @param setMonth Function to call when the month changes.
 * @param setYear Function to call when the year changes.
 * @param day The day variable.
 * @param month The month variable.
 * @param year The year variable.
 * @param yearMinimum Minimum allowed year value.
 * @param stackDirection Whether to stack the input fields in a "row" or "column".
 * @param disabled Pass to disable the input.
 * @returns FilterDatePartTermInput component.
 */
export function FilterDatePartTermInput({
  setDay,
  setMonth,
  setYear,
  day,
  month,
  year,
  yearMinimum,
  stackDirection,
  disabled,
}: FilterDatePartTermInputProps) {
  return (
    <Stack direction={stackDirection}>
      <TextField
        type="number"
        min={1}
        max={31}
        value={day?.toString()}
        label="Day"
        labelHidden={stackDirection === "row"}
        placeholder={stackDirection === "row" ? "Day" : undefined}
        onChange={(value) => setDay(parseInt(value))}
        autoComplete="off"
        disabled={disabled}
      />
      <TextField
        type="number"
        min={1}
        max={12}
        value={month?.toString()}
        label="Month"
        labelHidden={stackDirection === "row"}
        placeholder={stackDirection === "row" ? "Month" : undefined}
        onChange={(value) => setMonth(parseInt(value))}
        autoComplete="off"
        disabled={disabled}
      />
      <TextField
        type="number"
        value={year?.toString()}
        label="Year"
        labelHidden={stackDirection === "row"}
        placeholder={stackDirection === "row" ? "Year" : undefined}
        min={yearMinimum || 1850}
        max={9999}
        onChange={(value) => setYear(parseInt(value))}
        autoComplete="off"
        disabled={disabled}
        requiredIndicator
      />
    </Stack>
  );
}

/**
 * Generates a human readable label to display the contents of a SearchFilterParam
 * as a simple string.
 * @param op The SearchFilterParam's operator.
 * @param field The SearchFilterParam's field.
 * @param filterFields The SearchFilterFields spec to pull config for the passed
 * field from.
 * @param term The SearchFilterParam's term.
 * @param skipOpLabel Pass true to leave the op value as is and not apply rules
 * for making it more human-readable.
 * @returns A string
 */
export function genFilterLabel(
  op: searchutils.FilterParamOperator,
  field: string,
  filterFields: searchutils.SearchFilterFields,
  term: string | null | undefined,
  skipOpLabel: boolean = false,
) {
  const selectedFieldSpec = filterFields[field];
  const fieldType = selectedFieldSpec.type;
  const fieldLabel = selectedFieldSpec.label;
  let opLabel: string = op;
  if (!skipOpLabel) {
    let opResult = searchutils.getOperatorLabelByType(
      op,
      selectedFieldSpec.validTerms && !selectedFieldSpec.overrideArrayLabeling
        ? "boolean"
        : fieldType,
    );
    if (opResult) {
      opLabel = opResult;
    }
  }
  return `${fieldLabel} ${opLabel} ${term}`;
}

/**
 * Processes the pieces necessary to assemble a SearchFilterParam object and
 * generates one, if possible. If term, day, month, and year are all undefined,
 * it will return undefined.
 * @param op The FilterParamOperator to filter with.
 * @param selectedField The field to filter on.
 * @param filterFields The SearchFilterFields config.
 * @param term The term to filter for.
 * @param day The day part of a date term.
 * @param month The month part of a date term.
 * @param year The year part of a date term.
 * @returns A SearchFilterParam object.
 */

export function processDynamicFilterTermInputs(
  op: searchutils.FilterParamOperator,
  selectedField: string,
  filterFields: searchutils.SearchFilterFields,
  term?: string,
  day?: number,
  month?: number,
  year?: number,
): searchutils.SearchFilterParam | undefined {
  var finalTerm: string | null | undefined = null;
  var finalOperator = op;
  const selectedFieldSpec = filterFields[selectedField];
  const fieldType = selectedFieldSpec.type;
  if (fieldType === "date" && year) {
    finalTerm = searchutils.parseDatePartsToFilterDate(op, year, month, day);
  } else if (fieldType === "boolean") {
    finalTerm = term === "Yes" ? "true" : "false";
  } else {
    finalTerm = term;
  }
  if (["isnull", "notnull"].includes(op)) {
    finalOperator = op === "isnull" ? "=" : "!=";
  }
  const label = genFilterLabel(op, selectedField, filterFields, finalTerm);
  if (finalTerm) {
    return {
      uuid: uuid(),
      op: finalOperator,
      field: selectedField,
      term: finalTerm,
      label: label,
    };
  }
}
