import {
  UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import {
  getCollectionsImgResRuleset,
  getCollectionsImgResRulesetHistory,
  getCollectionsSearchConfig,
  updateCollectionsImgResRuleset,
  updateCollectionsSearchConfig,
} from "../api/endpoints";
import { getMedia, useDeleteRecord, useRecord } from "../api/genericEndpoints";
import {
  CatalogueItem,
  CollectionsMultimedia,
  CollectionsSearchConfig,
  catalogueItemSchema,
} from "../schemas/catalogueItem";
import {
  ImgResRule,
  ImgResRulesetInput,
  ImgResRulesetRecord,
  genRecordFromResRulesArray,
} from "../schemas/core";
import { FilterParamLike, SearchFilterFields } from "../search/searchutils";

const endpoint = "collections";

export function useCatalogueItem(accessToken: string, id?: string) {
  return useRecord<CatalogueItem>(
    endpoint,
    catalogueItemSchema,
    accessToken,
    id,
  );
}

export function useCatalogueItemMedia(
  accessToken: string,
  hash?: string | null,
  setProgressState?: (
    progress: number,
    elapsed: string,
    timeRemaining: string,
  ) => void,
) {
  return useQuery(
    [endpoint, accessToken, hash],
    async () =>
      hash
        ? await getMedia(accessToken, hash, endpoint, setProgressState)
        : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCatalogueItemMedias(
  accessToken: string,
  multimedias: CollectionsMultimedia[],
) {
  return useQueries(
    multimedias.map((mm) => {
      return {
        queryKey: [endpoint, "media", accessToken, mm.hash],
        queryFn: async () => {
          const result = await getMedia(accessToken, mm.hash, endpoint);
          return { multimedia: mm, content: result };
        },
      };
    }),
  );
}

export function useDeleteCatalogueItem() {
  return useDeleteRecord(endpoint);
}

export function useCollectionsSearchConfig(accessToken: string) {
  return useQuery(
    ["collectionsSearchConfig", accessToken],
    async () => await getCollectionsSearchConfig(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateCollectionsSearchConfig() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: CollectionsSearchConfig;
    }) => {
      return updateCollectionsSearchConfig(accessToken, inputData);
    },
    {
      onSuccess: (result) => {
        return queryClient.invalidateQueries(["collectionsSearchConfig"]);
      },
    },
  );
}

export function useCollectionsImgResConfig(
  accessToken: string,
  filterFields: SearchFilterFields,
): UseQueryResult<Record<string, ImgResRule<FilterParamLike>>> {
  return useQuery(
    ["collectionsImgResRuleset", accessToken],
    async () => {
      const apiRuleSet = await getCollectionsImgResRuleset(accessToken);
      return genRecordFromResRulesArray(apiRuleSet.resRules, filterFields);
    },
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCollectionsImgResConfigHistory(
  accessToken: string,
  filterFields: SearchFilterFields,
): UseQueryResult<ImgResRulesetRecord<FilterParamLike>[]> {
  return useQuery(
    ["collectionsImgResRulesetHistory", accessToken],
    async () => {
      const apiRulesetHistory =
        await getCollectionsImgResRulesetHistory(accessToken);
      const result = apiRulesetHistory.map((apiRuleset) => ({
        ...apiRuleset,
        resRules: genRecordFromResRulesArray(apiRuleset.resRules, filterFields),
      }));
      return result;
    },
  );
}

export function useUpdateCollectionsImgResRuleset() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      newRuleset,
    }: {
      accessToken: string;
      newRuleset: ImgResRulesetInput;
    }) => {
      return updateCollectionsImgResRuleset(accessToken, newRuleset);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["collectionsImgResRuleset"]);
      },
    },
  );
}
