import { useQuery } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { getPermissions, getPermissionsByRole } from "../api/endpoints";

export function usePermissions(accessToken: string) {
  return useQuery(
    ["permissions"],
    async () => (accessToken ? await getPermissions(accessToken) : null),
    { staleTime: RQUERY_STALE_TIME }
  );
}

export function usePermissionsByRole(accessToken: string) {
  return useQuery(
    ["permissionsByRole", accessToken],
    async () => (accessToken ? await getPermissionsByRole(accessToken) : null),
    { staleTime: RQUERY_STALE_TIME }
  );
}
