import { useNavigate, useParams } from "react-router-dom";
import { Heading, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { DetailSection, DetailFields } from "../Detail";
import { useDeleteUser, useUser } from "../hooks/userHooks";
import { CurrentUserData, SYSADMIN_ROLE_ID, userLabels } from "../schemas/core";
import Skeleton from "../Skeleton";
import Card from "../../shared/Card";
import { useRolesByUser } from "../hooks/roleHooks";
import { Button, List } from "@shopify/polaris";
import { useState } from "react";
import { DeletePrompt, DeleteSuccessPrompt } from "../utils/DeleteComponents";
import { hasPermissions } from "./authutils";
import Stack from "../../shared/Stack";

interface UserDetailPageProps {
  currentUserData: CurrentUserData;
}

export default function UserDetailPage(props: UserDetailPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: user } = useUser(props.currentUserData.accessToken, uuid);
  const { data: userRoles } = useRolesByUser(
    props.currentUserData.accessToken,
    uuid,
  );
  const deleteMutation = useDeleteUser();
  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const deletePrompt = user && (
    <DeletePrompt
      id={user.id}
      active={deletePromptActive}
      onDelete={(id: string) => {
        deleteMutation.mutate(
          { accessToken: props.currentUserData.accessToken, uuid: id },
          {
            onSuccess: (result) => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
      togglePrompt={toggleDeletePrompt}
    />
  );

  const deleteSuccessPrompt = user && (
    <DeleteSuccessPrompt
      id={user.id}
      active={deleteConfirmActive}
      onAck={() => navigate("../../")}
    />
  );

  const skeleton = !user && <Skeleton lines={4} />;

  const detail = user && (
    <Stack>
      <Stack spacing={1}>
        <Heading>{user.name}</Heading>
        <Subheading>{user.id}</Subheading>
      </Stack>
      <DetailFields
        record={user}
        spec={{
          name: null,
          email: null,
          createDt: { dateType: "datetime" },
          lastLoginDt: { dateType: "datetime" },
        }}
        labels={userLabels}
      />
    </Stack>
  );

  const deleteBtn = !user?.roleIds.includes(SYSADMIN_ROLE_ID) &&
    hasPermissions(props.currentUserData, "remove_users") && (
      <Button tone="critical" onClick={toggleDeletePrompt}>
        Delete
      </Button>
    );

  return (
    <BreadcrumbPage breadcrumbAction="up">
      {deletePrompt}
      {deleteSuccessPrompt}
      <Card>
        <DetailSection
          onEditBtnClick={
            hasPermissions(props.currentUserData, "add_edit_users")
              ? () => navigate("./edit")
              : undefined
          }
          actions={deleteBtn}
        >
          {skeleton || detail}
          <Stack>
            <Subheading>Roles</Subheading>
            <List>
              {uuid &&
                userRoles &&
                userRoles[uuid].map((role) => (
                  <List.Item key={role.id}>{role.name}</List.Item>
                ))}
            </List>
          </Stack>
        </DetailSection>
      </Card>
    </BreadcrumbPage>
  );
}
