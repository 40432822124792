// NOTE: Hand them 20 results and tell them to refine their query at first

import { TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from "@shopify/app-bridge/actions";
import { Banner, Layout, Page } from "@shopify/polaris";
import axios from "axios";
import { useEffect, useReducer } from "react";
import AppSkeleton from "./Skeleton";
import { API_URL } from "../env";
import { MemberSearch } from "./MemberSearch";
import { Heading } from "../shared/TextComponents";

interface AppState {
  auth?: "pending" | "ok" | "rejected" | "error";
}

type Action =
  | { type: "start-auth" }
  | { type: "fetched-session-token"; token: string }
  | { type: "get-session-token-failed"; error: any }
  | { type: "fetched-shopify-api-oauth-scope"; scope: string }
  | { type: "get-shopify-api-oauth-scope-failed"; error: any };

const appReducer = (state: AppState, action: Action) => {
  let new_state = { ...state };
  switch (action.type) {
    case "start-auth":
      new_state.auth = "pending";
      break;
    case "fetched-session-token":
      break;
    case "get-session-token-failed":
      new_state.auth = "rejected";
      break;
    case "fetched-shopify-api-oauth-scope":
      new_state.auth = "ok";
      break;
    case "get-shopify-api-oauth-scope-failed":
      if ([401, 404].includes(action.error.response?.status)) {
        new_state.auth = "rejected";
      } else {
        new_state.auth = "error";
      }
      break;
  }
  console.log("ACTION: " + action.type, { state, action, new_state });
  return new_state;
};

interface AppProps {
  apiUrl: string;
  shopifyApiOAuthScopePath: string;
  shop: string | null;
}

function App({ apiUrl, shopifyApiOAuthScopePath, shop }: AppProps) {
  const app = useAppBridge();
  const initialState = {};

  const [state, dispatch] = useReducer(appReducer, initialState);
  const { auth } = state;

  useEffect(() => {
    const checkTokens = async () => {
      console.log(await app.getState());

      dispatch({ type: "start-auth" });
      // Get a session token
      let sessionToken = null;
      try {
        sessionToken = await getSessionToken(app);
        dispatch({ type: "fetched-session-token", token: sessionToken });
      } catch (e) {
        dispatch({ type: "get-session-token-failed", error: e });
        return;
      }

      // Use the session token to ask our API which scopes are permitted
      // (really, just checking if they have an API token or they need to be
      // sent through the OAuth flow)
      try {
        const response = await axios.get(apiUrl + shopifyApiOAuthScopePath, {
          headers: { Authorization: `Bearer ${sessionToken}` },
          timeout: 5000, // ms
        });
        dispatch({
          type: "fetched-shopify-api-oauth-scope",
          scope: response.data,
        });
      } catch (e) {
        dispatch({ type: "get-shopify-api-oauth-scope-failed", error: e });
      }
    };
    checkTokens();
  }, [app, apiUrl, shopifyApiOAuthScopePath]);

  if (auth === "rejected") {
    Redirect.create(app).dispatch(
      Redirect.Action.REMOTE,
      // FIXME
      API_URL + "/shopify" + window.location.search,
    );
  }

  if (!auth || ["pending", "rejected"].includes(auth)) {
    return (
      <Page title="MNHS Shopify Tools">
        <Heading>Authenticating...</Heading>
        <AppSkeleton />
      </Page>
    );
  }

  if (auth === "ok") {
    return (
      <>
        <Page title="Search for MNHS Members">
          <TitleBar title="Member Search" />
          <Layout>
            <Layout.Section>
              <MemberSearch shop={shop} />
            </Layout.Section>
          </Layout>
        </Page>
      </>
    );
  }

  return (
    <Page title="MNHS Shopify Tools">
      <Layout>
        <Layout.Section>
          <Banner title="Something went wrong!" tone="critical">
            <p>
              {/* TODO: Make this message one of the configurable messages a la 
              the emails. */}
              Sorry! Please try again in a few minutes. If the problem persists,
              submit a help desk ticket and direct it to the Business
              Applications Team.
            </p>
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default App;
