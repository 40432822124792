import { Button, Select, TextField } from "@shopify/polaris";
import { Role } from "../schemas/core";
import Stack from "../../shared/Stack";
import { ReactNode } from "react";

interface UserFilterInputProps {
  filterValue: string | undefined;
  onFilterValueChange: (value: string) => void;
  onClear: () => void;
  hasRoleValue?: string | undefined;
  onHasRoleSelect?: (selected: string) => void;
  roles?: Role[] | null;
  children?: ReactNode;
}

export default function UserFilterInput({
  filterValue,
  onFilterValueChange,
  onClear,
  hasRoleValue,
  onHasRoleSelect,
  roles,
  children,
}: UserFilterInputProps) {
  const roleSelect = roles && (
    <Select
      label="Has Role"
      value={hasRoleValue}
      onChange={onHasRoleSelect}
      labelHidden
      placeholder="Has Role"
      options={roles
        .sort((a, b) => (a.name < b.name ? -1 : a.name < b.name ? 1 : 0))
        .map((role) => ({
          label: role.name,
          value: role.id,
        }))}
    />
  );

  return (
    <Stack>
      <TextField
        value={filterValue}
        onChange={onFilterValueChange}
        autoFocus
        label="Filter users"
        placeholder="Filter users:"
        type="text"
        autoComplete="off"
        labelHidden
        clearButton={!roleSelect}
        onClearButtonClick={onClear}
        connectedRight={roleSelect}
      />
      {roleSelect && (
        <Stack direction="row" justify="space-between">
          <Button
            onClick={() => {
              onClear();
            }}
          >
            Clear
          </Button>
          {children}
        </Stack>
      )}
    </Stack>
  );
}
