import { yupResolver } from "@hookform/resolvers/yup";
import { ChoiceList, Layout, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Card from "../../shared/Card";
import { Subheading } from "../../shared/TextComponents";
import * as api from "../api/endpoints";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  ControlledCountyFormField,
  FormFields,
  FormSection,
} from "../Form";
import {
  useBirthRecord,
  useBirthRecordImage,
  useDeleteBirthRecord,
  useUpdateBirthRecord,
} from "../hooks/birthRecordHooks";
import {
  BirthRecord,
  BirthRecordInput,
  birthRecordInputSchema,
  birthRecordLabels,
} from "../schemas/birthRecord";
import { CurrentUserData } from "../schemas/core";
import Skeleton from "../Skeleton";
import ImageDropSuite from "../utils/ImageDropSuite";

export default function BirthRecordForm(props: BaseFormCompProps<BirthRecord>) {
  const [imageLoading, setImageLoading] = useState(false);
  const [front, setFront] = useState<string | null>();
  // const [back, setBack] = useState<string>()
  // const [additional, setAdditional] = useState<string[]>()

  const birthRecord = props.record;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<BirthRecordInput>({
    resolver: yupResolver(birthRecordInputSchema),
    // defaultValues: birthRecordInputSchema.cast(birthRecord),
  });

  useEffect(() => {
    if (!birthRecord) {
      return;
    }
    setFront(birthRecord.imageFrontSHA256);
    reset(birthRecordInputSchema.cast(birthRecord));
  }, [setFront, birthRecord, reset]);

  const { data: imageFront } = useBirthRecordImage(
    props.currentUserData.accessToken,
    front,
  );

  const onSubmit = (inputData: BirthRecordInput) => {
    inputData.imageFrontSHA256 = front || undefined;
  };

  return (
    <>
      <FormSection
        currentUserData={props.currentUserData}
        handleSubmit={handleSubmit}
        inputSchema={birthRecordInputSchema}
        initialValues={birthRecord}
        mutateHook={useUpdateBirthRecord}
        deleteHook={useDeleteBirthRecord}
        onSubmit={onSubmit}
        onMutateSuccess={props.onSubmitSuccess}
        onDeleteSuccess={props.onDeleteSuccess}
      >
        <ImageDropSuite
          currentUserData={props.currentUserData}
          addImageEndpoint={api.addBirthRecordImage}
          front={{
            src: imageFront?.content,
            hash: front,
            setHash: setFront,
            loading: imageLoading,
            setLoading: setImageLoading,
          }}
        />
        <FormFields
          control={control}
          spec={{
            // TODO: seriesCode should be a dropdown...
            seriesCode: { type: "text" },
            certificateNumber: { type: "text" },
            firstName: { type: "text" },
            middleName: { type: "text" },
            familyName: { type: "text", required: true },
            suffix: { type: "text" },
            mothersMaidenName: { type: "text" },
            birthDateStart: { type: "date", required: true },
            birthDateEnd: { type: "date" },
          }}
          labels={birthRecordLabels}
          errors={{
            seriesCode: errors.seriesCode,
            familyName: errors.familyName,
          }}
          reset={reset}
          getValues={getValues}
        />
        <ControlledCountyFormField
          control={control}
          error={errors.county}
          label={birthRecordLabels["county"]}
          required={true}
        />
        <Subheading>Alternate Names</Subheading>
        <AccumulativeTextField
          fieldName={"alternateNames"}
          control={control}
          register={register}
          errors={errors.alternateNames as FieldError}
        />
        <Controller
          name="confidentiality"
          control={control}
          defaultValue="confidential"
          render={({ field }) => (
            <ChoiceList
              title={"Confidentiality"}
              choices={[
                { label: "Confidential", value: "confidential" },
                { label: "Public", value: "public" },
                { label: "Unpublished", value: "unpublished" },
              ]}
              selected={[field.value]}
              onChange={(selected) => field.onChange(selected[0])}
            />
          )}
        />
      </FormSection>
    </>
  );
}

interface BirthRecordFormPageProps {
  currentUserData: CurrentUserData;
}

export function BirthRecordAddPage(props: BirthRecordFormPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <BirthRecordForm
              currentUserData={props.currentUserData}
              onSubmitSuccess={(id) => navigate(`../${id}`)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export function BirthRecordEditPage(props: BirthRecordFormPageProps) {
  // This was expedient but maybe there's a better way?
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: birthRecord } = useBirthRecord(
    props.currentUserData.accessToken,
    uuid,
  );

  const skeleton = !birthRecord && <Skeleton lines={16} />;

  const form = birthRecord && (
    <BirthRecordForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={birthRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
