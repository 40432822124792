import { Layout, SkeletonPage, SkeletonBodyText } from "@shopify/polaris";
import Card from "../shared/Card";

// TODO: Make it look more similar to what will eventually load?

const AppSkeleton = () => (
  <SkeletonPage primaryAction>
    <Layout>
      <Layout.Section>
        <Card>
          <SkeletonBodyText />
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);

export default AppSkeleton;
