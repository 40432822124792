import { Button, Divider, Layout, Page, Tooltip } from "@shopify/polaris";
import { DefaultPageProps } from "../utils/shared";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText } from "../../shared/TextComponents";
import {
  requestSearchResultsEmail,
  searchRecords,
} from "../api/genericEndpoints";
import { BirthRecord } from "../schemas/birthRecord";
import { useQuery } from "react-query";
import { SearchResults } from "../search/SearchResults";
import { renderBirthRecordHit } from "./BirthRecordSearch";
import { getVitalRecordsDisplayName } from "./shared";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useExecAnnualRelease } from "../hooks/birthRecordHooks";
import { SearchQuery } from "../search/searchutils";

export function BirthRecordReleasePage(props: DefaultPageProps) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const releaseYear =
    (currentMonth === 0 ? currentYear - 1 : currentYear) - 100;

  const mutation = useExecAnnualRelease();

  const query: SearchQuery = {
    query: "",
    filters: [
      { field: "confidentiality", op: "=", term: "confidential" },
      { field: "birthStartYear", op: "=", term: releaseYear.toString() },
    ],
    sorts: [],
  };

  const { data: recordsForRelease, isLoading } = useQuery({
    queryKey: ["searchBirthRecords", "yearlyRelease", page],
    queryFn: async () =>
      searchRecords<BirthRecord>(
        {
          accessToken: props.currentUserData.accessToken,
          ...query,
          pageNum: page,
          pageSize: 50,
        },
        "birth-records"
      ),
  });

  const prevPage = () => setPage((old) => Math.max(old - 1, 1));

  const nextPage = () =>
    setPage((old) => (recordsForRelease?.hasNext ? old + 1 : old));

  return (
    <Page>
      <Layout sectioned>
        <Card>
          <Stack spacing={4}>
            <Heading>Annual Release of Birthrecords</Heading>
            <PText>Year available for release: {releaseYear}</PText>
            <PText>Check the results below and release when ready.</PText>
            <Stack direction="row">
              <Tooltip content="Click to get the results emailed to you as a CSV file.">
                <Button
                  onClick={() =>
                    requestSearchResultsEmail(
                      query,
                      props.currentUserData.accessToken,
                      "birth-records"
                    )
                  }
                >
                  Get Results as CSV
                </Button>
              </Tooltip>
            </Stack>
            <Divider />
            <SearchResults
              result={recordsForRelease}
              isLoading={isLoading}
              renderHit={{
                render: renderBirthRecordHit,
                hitRenderMedia: "person",
                accessibilityLabel: (record) =>
                  `View details for ${getVitalRecordsDisplayName(record)}`,
              }}
              hitOnClick={(hit: BirthRecord) => navigate(`../${hit.id}`)}
              onPrevious={prevPage}
              onNext={nextPage}
            />
            <Button
              variant="primary"
              tone="success"
              onClick={() =>
                mutation.mutate({
                  accessToken: props.currentUserData.accessToken,
                  year: releaseYear,
                })
              }
            >
              Release
            </Button>
          </Stack>
        </Card>
      </Layout>
    </Page>
  );
}
