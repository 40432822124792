import * as yup from "yup";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";
import { Labels } from "./core";

export const findAidSchema = yup.object({
  id: yup.string().required(),
  title: yup.string().required(),
  filename: yup.string().required(),
  content: yup.string().required(),
  findaidHash: yup.string().required(),
  associatedMedia: yup
    .array()
    .of(
      yup.object({
        filepath: yup.string().required(),
        filename: yup.string().required(),
        hash: yup.string().nullable(),
      })
    )
    .required(),
  createDt: yup.date().required(),
  lastUpdateDt: yup.date().required(),
});

export type FindingAid = yup.InferType<typeof findAidSchema>;

export const findAidInputSchema = findAidSchema.shape({
  id: yup.string().nullable(),
});

export type FindingAidInput = yup.InferType<typeof findAidInputSchema>;

export const findaidLabels: Labels<FindingAid> = {
  id: "UUID",
  title: "Title",
  filename: "Filename",
  content: "HTML Content",
  findaidHash: "XML Hash",
  associatedMedia: "Associated Media",
  createDt: "Create Date",
  lastUpdateDt: "Last Update Date",
};

export const findaidSorts: SearchSortFields<FindingAid> = {
  filename: { label: findaidLabels["filename"] },
  title: { label: findaidLabels["title"] },
};

export const findaidFilters: SearchFilterFields = {
  filename: { label: findaidLabels["filename"], type: "string" },
  hasMissingMedia: { label: "Missing Media?", type: "boolean" },
};
