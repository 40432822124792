import { Button, Card as PolarisCard } from "@shopify/polaris";
import React, { ReactNode } from "react";
import Stack from "./Stack";
import { PText } from "./TextComponents";
import { useNavigate } from "react-router-dom";

interface CardProps {
  innerCard?: boolean;
  children?: React.ReactNode;
}

export default function Card({ innerCard = false, children }: CardProps) {
  const padding = innerCard ? "500" : "1000";
  return <PolarisCard padding={padding}>{children}</PolarisCard>;
}

interface NavCardProps {
  heading: string;
  url: string;
  children?: ReactNode;
  buttonLabel?: string;
  disabled?: boolean;
}

/**
 * Simple inner card that displays a heading and body text, as well as a button
 * to navigate to a url elsewhere in the app.
 * @param heading Heading to display on the card.
 * @param children Component to display within the card.
 * @param url Url to navigate to when action button is clicked.
 * @param buttonLabel Optional label to override the default label ("Proceed").
 * @param disabled Pass to disable the button.
 */
export function NavCard({
  heading,
  children,
  url,
  buttonLabel = "Proceed",
  disabled,
}: NavCardProps) {
  const navigate = useNavigate();

  return (
    <Card innerCard>
      <Stack spacing={1}>
        <PText weight="bold">{heading}</PText>
        {children}
        <Stack direction="row" justify="flex-end">
          <Button
            variant="primary"
            tone="success"
            onClick={() => navigate(url)}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
