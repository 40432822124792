import { useQuery } from "react-query";

import { RQUERY_STALE_TIME } from "../../env";
import * as api from "../api/genericEndpoints";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import {
  censusEventSchemaList,
  CensusRecord,
  CensusRecordInput,
  censusSchema,
} from "../schemas/census";
import {
  CensusPage,
  CensusPageInput,
  censusPageSchema,
  nearbyPagesSchema,
} from "../schemas/censusPage";
import { SearchResult, searchResultSchema } from "../schemas/core";
import { useRMAMedia } from "../api/genericEndpoints";

const endpoint = "census";
const pageEndpoint = `${endpoint}/pages`;

export function useCensusRecord(accessToken: string, uuid?: string) {
  return api.useRecord<CensusRecord>(endpoint, censusSchema, accessToken, uuid);
}

export function useUpdateCensusRecord() {
  return api.useUpdateRecord<CensusRecordInput>(endpoint);
}

export function useDeleteCensusRecord() {
  return api.useDeleteRecord(endpoint);
}

export function useCensusEvents(accessToken: string, uuid?: string) {
  return api.useRMAEvents<CensusRecord>(
    endpoint,
    censusEventSchemaList,
    accessToken,
    uuid,
  );
}

export function useCensusPage(accessToken: string, uuid?: string | null) {
  return api.useRecord<CensusPage>(
    pageEndpoint,
    censusPageSchema,
    accessToken,
    uuid,
  );
}

export function useCensusPageAssocRecords(accessToken: string, uuid?: string) {
  const getAssocRecords = async (): Promise<SearchResult<CensusRecord>> => {
    const resp = await axiosInstance.get(
      `/${pageEndpoint}/${uuid}/assoc-records`,
      baseAxiosConfig(accessToken),
    );
    const records = await searchResultSchema.validate(resp.data);
    return records;
  };
  return useQuery(
    [pageEndpoint, "assocRecords", accessToken, uuid],
    async () => (accessToken && uuid ? await getAssocRecords() : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useCensusPageNearbyPages(accessToken: string, uuid?: string) {
  const getNearbyPages = async () => {
    const resp = await axiosInstance.get(
      `/${pageEndpoint}/${uuid}/nearby-pages`,
      baseAxiosConfig(accessToken),
    );
    const result = await nearbyPagesSchema.validate(resp.data);
    return result;
  };
  return useQuery(
    [pageEndpoint, "nearbyPages", accessToken, uuid],
    async () => (accessToken && uuid ? await getNearbyPages() : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateCensusPage() {
  return api.useUpdateRecord<CensusPageInput>(pageEndpoint);
}

export function useDeleteCensusPage() {
  return api.useDeleteRecord(pageEndpoint);
}
export function useCensusImage(accessToken: string, pageUuid?: string | null) {
  return useRMAMedia(accessToken, pageEndpoint, pageUuid);
}

export function useCensusPageImage(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, pageEndpoint, hash);
}
