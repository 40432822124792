import { useNavigate } from "react-router-dom";
import { Layout, Page, Spinner } from "@shopify/polaris";
import { useQuery } from "react-query";
import Card from "../../shared/Card";
import BulkEditGrid, { BulkEditPageProps } from "../BulkEditGrid";
import { searchRecords } from "../api/genericEndpoints";
import { useBulkUpdateDeathRecords } from "../hooks/deathRecordHooks";
import {
  DeathRecord,
  deathRecordLabels,
  deathRecordSchema,
} from "../schemas/deathRecord";
import { CONFIDENTIALITY } from "../schemas/core";

export function DeathRecordBulkEditPage(props: BulkEditPageProps<DeathRecord>) {
  const navigate = useNavigate();

  const { data: results } = useQuery(
    ["deathRecord"],
    async () =>
      searchRecords<DeathRecord>(
        {
          accessToken: props.currentUserData.accessToken,
          ...props.searchState.genSearchQuery(),
          pageNum: 1,
          pageSize: 100,
        },
        "death-records"
      ),
    { keepPreviousData: true }
  );

  const grid = results && (
    <BulkEditGrid
      currentUserData={props.currentUserData}
      records={results.hits}
      validationSchema={deathRecordSchema}
      columns={[
        {
          field: "certificateId",
          type: "text",
          required: true,
          overrideDisabled: true,
        },
        "birthDate",
        "birthPlace",
        "firstName",
        "middleName",
        { field: "familyName", type: "text", required: true },
        "suffix",
        "mothersMaidenName",
        {
          field: "confidentiality",
          type: "select",
          required: true,
          selectConfig: {
            options: CONFIDENTIALITY,
            defaultValue: "confidential",
          },
        },
        { field: "deathDateStart", type: "date", required: true },
        { field: "deathDateEnd", type: "date" },
        { field: "county", type: "county" },
      ]}
      labels={{
        ...deathRecordLabels,
        deathDateStart: "Death Date Start",
        deathDateEnd: "Death Date End",
      }}
      bulkEditHook={useBulkUpdateDeathRecords}
      onBulkEditSuccess={() => navigate("../dash?tab=history")}
    />
  );

  const spinner = !results && <Spinner />;

  return (
    <Page>
      <Layout>
        <Card>{grid || spinner}</Card>
      </Layout>
    </Page>
  );
}
