import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Layout, Page, Spinner, Thumbnail } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import file_placeholder_text from "../../assets/file_placeholder_text.png";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  FormFields,
  FormSection,
} from "../Form";
import Skeleton from "../Skeleton";
import { addGoldStarPDF } from "../api/endpoints";
import * as api from "../api/shared";
import {
  useDeleteGoldStar,
  useGoldStar,
  useUpdateGoldStar,
} from "../hooks/goldStarHooks";
import { goldStarLabels } from "../schemas/goldStar";
import { GoldStarRecordInput } from "../schemas/goldStar";
import { goldStarInputSchema } from "../schemas/goldStar";
import { GoldStarRecord } from "../schemas/goldStar";
import FileDrop, { FileDropResult } from "../utils/FileDrop";
import { DefaultPageProps } from "../utils/shared";

export default function GoldStarForm(props: BaseFormCompProps<GoldStarRecord>) {
  const [pdfLoading, setPDFLoading] = useState(false);
  const [pdf, setPDF] = useState<string | null>();
  const [pdfFilename, setPDFFilename] = useState<string | null>();

  const goldStarRecord = props.record;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<GoldStarRecordInput>({
    resolver: yupResolver(goldStarInputSchema),
    // defaultValues: goldStarInputSchema.cast(goldStarRecord),
  });

  useEffect(() => {
    if (!goldStarRecord) {
      return;
    }
    setPDF(goldStarRecord.pdfSHA256);
    reset(goldStarInputSchema.cast(goldStarRecord));
  }, [setPDF, goldStarRecord, reset]);

  const onSubmit = (inputData: GoldStarRecordInput) => {
    inputData.pdfSHA256 = pdf || undefined;
  };

  const pdfDrop = !pdf && (
    <FileDrop
      mimeType="application/pdf"
      onDrop={async (files) => {
        setPDFLoading(true);
        const result: Record<string, FileDropResult> = {};
        for (const sha256 in files) {
          const serverSHA256 = await addGoldStarPDF(
            props.currentUserData.accessToken,
            files[sha256]
          );
          if (serverSHA256 instanceof api.Error) {
            result[sha256] = {
              serverSHA256: null,
              error: serverSHA256.message,
            };
          } else {
            result[sha256] = {
              serverSHA256,
              error: null,
            };
            setPDF(serverSHA256);
            setPDFFilename(files[sha256].name);
          }
        }
        setPDFLoading(false);
        return result;
      }}
      onRemove={() => {}}
    />
  );

  const pdfDisplay = pdf && (
    <Stack align="flex-start">
      <Stack direction="row" align="center">
        <Thumbnail
          source={file_placeholder_text}
          alt={`Uploaded file ${pdfFilename}`}
        />
        <PText>{pdfFilename}</PText>
      </Stack>
      <Button
        tone="critical"
        variant="primary"
        onClick={() => {
          setPDF(null);
          setPDFFilename(null);
        }}
      >
        Remove PDF
      </Button>
    </Stack>
  );

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={goldStarInputSchema}
      initialValues={goldStarRecord}
      mutateHook={useUpdateGoldStar}
      deleteHook={useDeleteGoldStar}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <Subheading>Associated PDF</Subheading>
      {pdfLoading ? <Spinner /> : pdfDrop || pdfDisplay}
      <FormFields
        control={control}
        spec={{
          prefix: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text", required: true },
          suffix: { type: "text" },
          relatedName: { type: "text" },
          deathDateStart: { type: "date", required: true },
          deathDateEnd: { type: "date" },
          birthPlace: { type: "text" },
          birthDate: { type: "text" },
          residencePriorToService: { type: "text" },
          notes: { type: "text", lines: 6 },
        }}
        labels={goldStarLabels}
        errors={{ familyName: errors.familyName }}
        reset={reset}
        getValues={getValues}
      />
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
    </FormSection>
  );
}

export function GoldStarAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <GoldStarForm
              currentUserData={props.currentUserData}
              onSubmitSuccess={(id) => navigate(`../${id}`)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export function GoldStarEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: goldStarRecord } = useGoldStar(
    props.currentUserData.accessToken,
    uuid
  );

  const skeleton = !goldStarRecord && <Skeleton lines={15} />;

  const form = goldStarRecord && (
    <GoldStarForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={goldStarRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
