import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import { goldStarFilters } from "../schemas/goldStar";
import { goldStarSorts } from "../schemas/goldStar";
import { goldStarLabels } from "../schemas/goldStar";
import { GoldStarRecord } from "../schemas/goldStar";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import GoldStarRecordDetail from "./GoldStarDetail";
import GoldStarForm from "./GoldStarForm";
import { getVitalRecordsDisplayName } from "./shared";

export function renderGoldStarHit(record: GoldStarRecord) {
  const displayName = getVitalRecordsDisplayName(record);
  const { deathDateStart } = record;

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        {deathDateStart && new Date(deathDateStart).toLocaleDateString()}
        {record.deathDateEnd ? " (Inexact Date)" : null}
      </PText>
      <PText>{record.residencePriorToService}</PText>
    </>
  );
}

export default function GoldStarSearchPage(
  props: BaseSearchPageComponentProps<GoldStarRecord>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={goldStarLabels}
          renderSearchHit={{
            render: renderGoldStarHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            GoldStarRecordDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            GoldStarForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={goldStarSorts}
          filterFields={goldStarFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<GoldStarRecord>(props, "gold-star")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "gold-star")
          }
          recordName="Gold Star Roll Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_remove_gold_star_roll_records"
          )}
        />
      </Layout>
    </Page>
  );
}
