import { Button, Pagination, Spinner } from "@shopify/polaris";
import { ArrowLeftIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Stack from "../shared/Stack";
import { PText } from "../shared/TextComponents";

interface ZoomableImageProps {
  src: Blob;
  alt: string;
  fileName?: string | null;
}

export default function ZoomableImage(props: ZoomableImageProps) {
  const url = URL.createObjectURL(props.src);

  // If we ever need to cover an arbitrary variety of image mimetypes it would be
  // worth it to consider adding a mimetype-parsing package as a dependency. For
  // right now the mimetypes we'll need to display are pretty well known so this
  // should be fine and we can avoid adding a dependency.
  const mimeTypeMap: Record<string, string> = {
    "image/jpeg": ".jpg",
    "image/tiff": ".tiff",
    "image/png": ".png",
  };
  let ext = mimeTypeMap[props.src.type];
  let filename: string | null = null;
  // Ensure the filename's extension (if present) is overriden by the Blob's
  // mimetype:
  if (props.fileName) {
    let parts = props.fileName.split(".");
    if (parts.length > 1) {
      parts.pop();
    }
    if (ext) {
      filename = `${parts.join(".")}${ext}`;
    } else {
      console.error(
        `Unexpected mimetype ${props.src.type}. Download file could not be created`,
      );
    }
  }

  return (
    <Stack>
      <TransformWrapper>
        <TransformComponent>
          <img width="100%" src={url} alt={props.alt} />
        </TransformComponent>
      </TransformWrapper>
      <Stack direction="row" justify="flex-end" spacing={4}>
        <Button variant="plain" onClick={() => window.open(url)}>
          Open in New Tab
        </Button>
        {filename ? (
          <Button variant="plain" url={url} download={filename}>
            Download
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

interface ImageMediaData {
  src?: Blob | null;
  alt: string;
  fileName?: string | null;
  isLoading?: boolean;
}

interface ZoomableImageGalleryProps {
  images: ImageMediaData[];
}

export function ZoomableImageGallery({ images }: ZoomableImageGalleryProps) {
  const length = images.length;
  const [imgIdx, setImageIdx] = useState(0);
  const prevIdx = () => setImageIdx((old) => Math.max(old - 1, 0));
  const nextIdx = () => setImageIdx((old) => (old <= length ? old + 1 : old));

  const imageObjects = images.map((img) => {
    if (img.isLoading) {
      return <Spinner />;
    } else if (img.src) {
      return (
        <ZoomableImage src={img.src} alt={img.alt} fileName={img.fileName} />
      );
    } else {
      return (
        <Stack direction="row">
          <PText>Unable to load image.</PText>
        </Stack>
      );
    }
  });

  return (
    <Stack>
      <Stack direction="row" justify="center">
        <Button
          variant="plain"
          icon={ArrowLeftIcon}
          disabled={imgIdx === 0}
          onClick={prevIdx}
        />
        {imageObjects[imgIdx]}
        <Button
          variant="plain"
          icon={ArrowRightIcon}
          disabled={imgIdx + 1 >= length}
          onClick={nextIdx}
        />
      </Stack>
      <Stack direction="row" justify="center">
        <Pagination
          hasPrevious={imgIdx > 0}
          hasNext={imgIdx + 1 < length}
          onNext={nextIdx}
          onPrevious={prevIdx}
          label={`Image ${imgIdx + 1} / ${length}`}
        />
      </Stack>
    </Stack>
  );
}
