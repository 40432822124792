import { Button } from "@shopify/polaris";
import { ArrowLeftIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { PText } from "../../shared/TextComponents";

import { SearchResult } from "../schemas/core";
import Stack from "../../shared/Stack";

interface SearchResultsNavProps<T> {
  initialIdx: number;
  searchResult: SearchResult<T>;
  renderDetail: (record: T, onEditBtnClick: () => void) => JSX.Element;
  renderForm: (
    record: T,
    onSubmitSuccess: () => void,
    onDeleteSuccess: () => void
  ) => JSX.Element;
  onPrevious: () => void;
  onNext: () => void;
  toggleNavMode: () => void;
  editModeActive: boolean;
  invalidateQuery: () => void;
}

export default function SearchResultsNav<T>(props: SearchResultsNavProps<T>) {
  const [idx, setIdx] = useState(props.initialIdx);
  const [editing, setEditing] = useState(false);

  const prevResult = () => {
    if (idx <= 0) {
      if (props.searchResult.hasPrevious) {
        props.onPrevious();
        setIdx(props.searchResult.pageSize - 1);
      }
    } else {
      setIdx((old) => old - 1);
    }
  };

  const nextResult = () => {
    if (idx + 1 === props.searchResult.hits.length) {
      if (props.searchResult.hasNext) {
        props.onNext();
        setIdx(0);
      }
    } else {
      setIdx((old) => old + 1);
    }
  };

  const onDelete = () => {
    props.invalidateQuery();
    prevResult();
  };

  const toggleEditing = () => setEditing((current) => !current);

  const navArrows = (
    <Stack direction="row">
      <Button onClick={prevResult} icon={ArrowLeftIcon} />
      <PText>{`Record ${
        (props.searchResult.pageNum - 1) * props.searchResult.pageSize + idx + 1
      } of ${props.searchResult.totalHitCount}`}</PText>
      <Button onClick={nextResult} icon={ArrowRightIcon} />
    </Stack>
  );

  const detail = props.renderDetail(
    props.searchResult.hits[idx],
    toggleEditing
  );

  const form = props.renderForm(
    props.searchResult.hits[idx],
    toggleEditing,
    onDelete
  );

  return (
    <>
      <Stack>
        <Stack direction="row">
          <Button onClick={props.toggleNavMode}>Back to Search</Button>
        </Stack>
        {navArrows}
        {props.editModeActive || editing ? form : detail}
        {navArrows}
      </Stack>
    </>
  );
}
