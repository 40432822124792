import {
  Form as PolarisForm,
  FormLayout,
  TextField,
  Button,
  Divider,
} from "@shopify/polaris";
import { DefaultPageProps } from "./utils/shared";
import BreadcrumbPage from "./BreadcrumbPage";
import Card from "../shared/Card";
import {
  useSearchFieldBoosts,
  useUpdateSearchFieldBoosts,
} from "./hooks/searchFieldBoostsHooks";
import { Heading, PText, Subheading } from "../shared/TextComponents";
import { CurrentUserData } from "./schemas/core";
import { SearchFieldBoosts } from "./search/searchutils";
import { SearchFieldBoostsFields } from "./search/searchutils";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import Stack from "../shared/Stack";

interface SearchFieldBoostsFormProps {
  boosts: SearchFieldBoosts;
  currentUserData: CurrentUserData;
}

function SearchFieldBoostsForm(props: SearchFieldBoostsFormProps) {
  const [boostFields, setBoostFields] = useState<SearchFieldBoostsFields>(
    props.boosts.fields,
  );

  const mutation = useUpdateSearchFieldBoosts();

  const submit = async () => {
    mutation.mutate({
      accessToken: props.currentUserData.accessToken,
      boosts: { dataset: props.boosts.dataset, fields: boostFields },
    });
  };

  // Commenting this out instead of deleting cause it might be useful for
  // rendering subject trees:
  //   const BoostComponent = (
  //     key: string,
  //     value: DeeplyNestedRecord<JSX.Element> | JSX.Element
  //   ): JSX.Element => {
  //     let result: JSX.Element;
  //     if (React.isValidElement(value)) {
  //       result = value;
  //     } else {
  //       result = (
  //         <Stack>
  //           <Subheading>{key}</Subheading>
  //           {Object.entries(value).map(([k, v]) => BoostComponent(k, v))}
  //         </Stack>
  //       );
  //     }
  //     return result;
  //   };

  const sortedFields = Object.entries(boostFields)
    .map(([key, value]) => ({ key: key, value: value }))
    .sort((a, b) => (a.key < b.key ? -1 : a.key < b.key ? 1 : 0));

  return (
    <PolarisForm onSubmit={submit}>
      <FormLayout>
        <Stack>
          <Stack direction="row" justify="space-between">
            <Subheading>Field</Subheading>
            <Subheading>Weight</Subheading>
          </Stack>
          {/* TODO: Add a filter here? */}
          {sortedFields.map(({ key, value }) => (
            <Stack key={uuid()}>
              <Stack direction="row" justify="space-between">
                <PText>
                  {/* Non-text fields will have .keyword appended to them, but 
                  we don't want to display that to users of the client. */}
                  {/* splitting on " " instead of "_" because of the "." */}
                  {key
                    .replace(".keyword", "")
                    .replaceAll(".", " \u25CF ")
                    .replaceAll("_", " ")
                    .split(" ")
                    .map((s) => {
                      return s.length > 1
                        ? s[0].toLocaleUpperCase() + s.slice(1)
                        : s.toLocaleUpperCase();
                    })
                    .join(" ")}
                </PText>
                <TextField
                  type="number"
                  label={key}
                  autoComplete="off"
                  labelHidden
                  value={String(value)}
                  onChange={(value) =>
                    setBoostFields((prev) => ({
                      ...prev,
                      [key]: parseInt(value),
                    }))
                  }
                  min={0}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack direction="row" justify="flex-end">
          <Button
            submit
            tone="success"
            variant="primary"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
        </Stack>
      </FormLayout>
    </PolarisForm>
  );
}

interface SearchFieldBoostsPageProps extends DefaultPageProps {
  dataset: string;
  title: string;
}

export function SearchFieldBoostsPage(props: SearchFieldBoostsPageProps) {
  const { data: boosts } = useSearchFieldBoosts(`${props.dataset}`);

  return (
    <BreadcrumbPage breadcrumbs="both" breadcrumbAction="up">
      <Card>
        <Stack>
          <Heading>Manage {props.title} weights</Heading>
          <PText>
            Increase a field's weight to increase its "best match" score when a
            match is found in that field.
          </PText>
          <PText>
            If you see a "Normalized" field below, that indicates that the field
            has a sub-field that contains a normalized version of the text in
            that field, removing any special characters like accent marks.
          </PText>
          <PText>
            To exclude a field from free text search entirely, set its weight to
            0.
          </PText>
          <Divider />
          {boosts && (
            <SearchFieldBoostsForm
              currentUserData={props.currentUserData}
              boosts={boosts}
            />
          )}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
