import { TextField, Form, Icon, Button, Spinner } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import Stack from "../../shared/Stack";

interface SearchInputProps {
  onSubmit: (query: string) => void;
  query: string;
  setQuery: (query: string) => void;
  queryLoading: boolean;
  newSearch: () => void;
}

export default function SearchInput(props: SearchInputProps) {
  return (
    <Form
      onSubmit={(_event) => {
        props.onSubmit(props.query);
      }}
    >
      <Stack>
        <TextField
          autoFocus
          onChange={(value) => {
            props.setQuery(value);
          }}
          label="Search"
          labelHidden
          value={props.query}
          prefix={<Icon source={SearchIcon} tone="base" />}
          placeholder="Search"
          clearButton
          onClearButtonClick={() => {
            props.setQuery("");
            props.onSubmit("");
          }}
          autoComplete="off"
        />
        <Stack direction="row" justify="space-between">
          <Button onClick={props.newSearch}>New Search</Button>
          <Stack direction="row">
            {props.queryLoading && <Spinner size="small" />}
            <Button
              submit
              variant="primary"
              tone="success"
              disabled={props.queryLoading}
            >
              Search
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
}
