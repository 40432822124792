import {
  Button,
  Divider,
  ResourceItem,
  ResourceList,
  Spinner,
} from "@shopify/polaris";
import { ReactNode, useState } from "react";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import { useQueryComments, useUpdateComment } from "../hooks/commentHooks";
import { birthRecordSorts } from "../schemas/birthRecord";
import { Comment, commentFilters, commentLabels } from "../schemas/comment";
import { DatasetNavMap } from "../schemas/core";
import FilterParamDisplay from "../search/FilterParamDisplay";
import Pagination from "../search/Pagination";
import { FilterParamLike, SearchSortParams } from "../search/searchutils";
import SortParamDisplay from "../search/SortParamDisplay";
import { DefaultPageProps } from "../utils/shared";
import { CommentBlockEmailModal } from "./CommentBlockedEmails";
import CommentDetail from "./CommentDetail";
import { CommentFormModal } from "./CommentForm";

export function CommentFeedPage(props: DefaultPageProps) {
  const [page, setPage] = useState(1);
  const [modalActive, setModalActive] = useState(false);
  const [blockPromptActive, setBlockPromptActive] = useState(false);
  const [emailToBlock, setEmailToBlock] = useState<string | undefined>();
  const [selectedCmt, setSelectedCmt] = useState<Comment | undefined>();
  const [sorts, setSorts] = useState<SearchSortParams<Comment>>({
    commentDt: { field: "commentDt", direction: "desc" },
  });
  const [filters, setFilters] = useState<FilterParamLike[]>([]);

  const {
    data: recentComments,
    isLoading,
    isRefetching,
  } = useQueryComments(
    props.currentUserData.accessToken,
    page,
    50,
    Object.values(sorts),
    filters,
  );

  const mutation = useUpdateComment();

  const blockEmailModal = (
    <CommentBlockEmailModal
      currentUserData={props.currentUserData}
      email={emailToBlock}
      setEmail={setEmailToBlock}
      blockPromptActive={blockPromptActive}
      setBlockPromptActive={setBlockPromptActive}
    />
  );

  const createGoToRecordUrl = (cmt: Comment) => {
    // TEMP: Link directly to production because all the comments are in staging
    // but not all the records they link to. This is the most expedient way for
    // Collections to test data validity.
    let urlRoot = "https://vital-records.mnhs.io/rma/";
    // let urlRoot = "/rma/"
    return urlRoot + `${DatasetNavMap[cmt.dataset]}/${cmt.assignedTo}`;
  };

  // Sorts
  const sortDisplay = (
    <SortParamDisplay
      sorts={sorts}
      setSorts={setSorts}
      sortFields={birthRecordSorts}
      labels={commentLabels}
    />
  );

  // Filters
  const filterDisplay = (
    <FilterParamDisplay
      filters={filters}
      setFilters={setFilters}
      filterFields={commentFilters}
      disallowEdit
      // Disallowing conjuncts for now since the API can't handle them atm.
      disallowConjuncts
    />
  );

  // Comments display
  const renderItem = (item: Comment): ReactNode => (
    <ResourceItem
      id={item.id}
      accessibilityLabel={`Detail for comment by user ${item.userName} `}
      // ResourceItem onClick doesn't play nice with the CommentDetail buttons:
      onClick={() => {}}
    >
      <CommentDetail
        comment={item}
        editIsLoading={mutation.isLoading}
        onEditBtnClick={() => {
          setSelectedCmt(item);
          setModalActive(true);
        }}
        onBlockClick={
          hasPermissions(props.currentUserData, "add_cmt_blocked_emails")
            ? () => {
                setEmailToBlock(item.email);
                setBlockPromptActive(true);
              }
            : undefined
        }
        onSubmitEdit={
          hasPermissions(props.currentUserData, "edit_comments")
            ? (input) =>
                mutation.mutate({
                  accessToken: props.currentUserData.accessToken,
                  inputData: input,
                })
            : undefined
        }
        additionalActions={
          <Button
            onClick={() => window.open(createGoToRecordUrl(item))}
            // onClick={() => navigate(createGoToRecordUrl(item))}
          >
            Go To Record
          </Button>
        }
      />
    </ResourceItem>
  );

  const pagination = (
    <Pagination
      results={recentComments}
      setPage={setPage}
      queryRefetching={isRefetching}
    />
  );

  const editCommentModal = selectedCmt && (
    <CommentFormModal
      promptTitle="Edit Comment"
      currentUserData={props.currentUserData}
      record={selectedCmt}
      modalActive={modalActive}
      setModalActive={setModalActive}
      hideAccessLevel
    />
  );

  const feed = recentComments && (
    <ResourceList
      resourceName={{ singular: "comment", plural: "comments" }}
      items={recentComments.results}
      renderItem={renderItem}
    />
  );

  const spinner = isLoading && (
    <Stack direction="row" justify="center">
      <Spinner />
    </Stack>
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          {blockEmailModal}
          {editCommentModal}
          <Heading>Comments Activity Feed</Heading>
          <Divider />
          {sortDisplay}
          <Divider />
          {filterDisplay}
          <Divider />
          {pagination}
          {spinner}
          {feed}
          {pagination}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
