import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getSearchFieldBoosts,
  updateSearchFieldBoosts,
} from "../api/endpoints";
import { RQUERY_STALE_TIME } from "../../env";
import { SearchFieldBoosts } from "../search/searchutils";

export function useSearchFieldBoosts(dataset?: string) {
  return useQuery(
    ["searchFieldBoosts", dataset],
    async () => (dataset ? await getSearchFieldBoosts(dataset) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateSearchFieldBoosts() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      boosts,
    }: {
      accessToken: string;
      boosts: SearchFieldBoosts;
    }) => {
      return updateSearchFieldBoosts(accessToken, boosts);
    },
    {
      onSuccess: (result) => {
        return queryClient.invalidateQueries([
          "searchFieldBoosts",
          result.dataset,
        ]);
      },
    },
  );
}
