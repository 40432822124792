import {
  addRMAMedia,
  useDeleteRecord,
  useRecord,
  useRMAEvents,
  useRMAMedia,
  useUpdateRecord,
} from "../api/genericEndpoints";
import { APIError } from "../api/shared";
import {
  wwiServiceEventSchemaList,
  WWIServiceRecord,
  WWIServiceRecordInput,
  wwiServiceSchema,
} from "../schemas/wwiService";

const endpoint = "wwi-service";

export function useWWIServiceRecord(accessToken: string, uuid?: string) {
  return useRecord<WWIServiceRecord>(
    endpoint,
    wwiServiceSchema,
    accessToken,
    uuid,
  );
}

export function useUpdateWWIServiceRecord() {
  return useUpdateRecord<WWIServiceRecordInput>(endpoint);
}

export function useDeleteWWIServiceRecord() {
  return useDeleteRecord(endpoint);
}

export function useWWIServiceEvents(accessToken: string, uuid?: string) {
  return useRMAEvents<WWIServiceRecord>(
    endpoint,
    wwiServiceEventSchemaList,
    accessToken,
    uuid,
  );
}

export async function addWWIServicePDF(
  accessToken: string,
  pdfFile: File,
): Promise<string | APIError> {
  const result = await addRMAMedia(accessToken, pdfFile, endpoint);
  return result;
}

export function useWWIServicePDF(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
