import {
  useDeleteRecord,
  useRecord,
  useRMAEvents,
  useRMAMedia,
  useUpdateRecord,
} from "../api/genericEndpoints";
import {
  goldStarEventSchemaList,
  GoldStarRecord,
  GoldStarRecordInput,
  goldStarSchema,
} from "../schemas/goldStar";

const endpoint = "gold-star";

export function useGoldStar(accessToken: string, uuid?: string) {
  return useRecord<GoldStarRecord>(endpoint, goldStarSchema, accessToken, uuid);
}

export function useUpdateGoldStar() {
  return useUpdateRecord<GoldStarRecordInput>(endpoint);
}

export function useDeleteGoldStar() {
  return useDeleteRecord(endpoint);
}

export function useGoldStarEvents(accessToken: string, uuid?: string) {
  return useRMAEvents<GoldStarRecord>(
    endpoint,
    goldStarEventSchemaList,
    accessToken,
    uuid,
  );
}
export function useGoldStarPDF(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
