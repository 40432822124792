import { useQueries } from "react-query";
import {
  getMedia,
  useDeleteRecord,
  useRecord,
  useRMAMedia,
} from "../api/genericEndpoints";
import { WWISolPhotoRecord, wwiSolPhotoSchema } from "../schemas/wwiSolPhoto";

const endpoint = "wwi-soldiers-photos";

export function useWWISoldiersPhoto(accessToken: string, uuid?: string) {
  return useRecord<WWISolPhotoRecord>(
    endpoint,
    wwiSolPhotoSchema,
    accessToken,
    uuid,
  );
}

export function useWWISolPhotoMedia(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}

export function useWWISolPhotoMedias(
  accessToken: string,
  imageSHA256s: string[],
) {
  return useQueries(
    imageSHA256s.map((hash) => {
      return {
        queryKey: [endpoint, "media", accessToken, hash],
        queryFn: async () => {
          const result = await getMedia(accessToken, hash, endpoint);
          return result;
        },
      };
    }),
  );
}

export function useDeleteWWISolPhoto() {
  return useDeleteRecord(endpoint);
}
