import { ReactNode } from "react";

interface IndentedDivProps {
  level?: number;
  children?: ReactNode;
}

export function IndentedDiv({ level = 0, children }: IndentedDivProps) {
  return <div style={{ marginLeft: `${level}em` }}>{children}</div>;
}
