import { Banner, Button, DropZone, List, Thumbnail } from "@shopify/polaris";
import sha256 from "crypto-js/sha256";
import { useState } from "react";

import file_placeholder_text from "../../assets/file_placeholder_text.png";
import file_placeholder_video from "../../assets/file_placeholder_video.png";
import Stack from "../../shared/Stack";
import { PText } from "../../shared/TextComponents";
import { getExtension } from "./shared";

export type FileDropResult = {
  serverSHA256: string | null;
  error: string | null;
};

type DropFileType = "any" | "text" | "image" | "video";

///
interface FileDropProps {
  onDrop: (
    files: Record<string, File>
  ) => Promise<
    Record<string, { serverSHA256: string | null; error: string | null }>
  >;
  onRemove: (removedSHA256: string) => void;
  allowMultiple?: boolean;
  fileType?: DropFileType;
  mimeType?: string;
}

export default function FileDrop({
  onDrop,
  onRemove,
  allowMultiple = false,
  fileType = "any",
  mimeType = "*",
}: FileDropProps) {
  type FileData = {
    file: File;
    isUploading: boolean;
    serverSHA256: string | null | undefined;
    sourceImageMD5: string | undefined;
    uploadError: string | null | undefined;
  };
  const [fileData, setFileData] = useState<Record<string, FileData>>({});
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
  const hasError = rejectedFiles.length > 0;

  const dzFileTypeMap: Record<DropFileType, "file" | "image" | "video"> = {
    any: "file",
    text: "file",
    image: "image",
    video: "video",
  };

  const dzFileParams = {
    dzType: dzFileTypeMap[fileType],
    dzAccept:
      mimeType === "*"
        ? dzFileTypeMap[fileType] === "file"
          ? undefined
          : `${dzFileTypeMap[fileType]}/${mimeType}`
        : mimeType,
  };

  const handleDrop = async (
    _dropped: File[],
    accepted: File[],
    rejected: File[]
  ) => {
    if (!allowMultiple) {
      accepted = [accepted[accepted.length - 1]];
    }
    setRejectedFiles(rejected);

    const hashFile = (f?: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result !== "string")
            throw Error("FileReader result was not a string");
          resolve(sha256(reader.result).toString());
        };
        reader.onerror = reject;
        // f will be undefined if user attempts to upload an invalid file type.
        // This check prevents an uglier error from overriding the nicer error
        // messages in development environment.
        if (f) {
          reader.readAsBinaryString(f);
        }
      });
    };

    let acceptedFileData: Record<string, FileData> = {};
    for (const af of accepted) {
      const hash = await hashFile(af);
      acceptedFileData[hash] = {
        file: af,
        isUploading: true,
        serverSHA256: undefined,
        sourceImageMD5: undefined,
        uploadError: undefined,
      };
    }

    setFileData((fileData) => {
      if (allowMultiple) {
        return { ...fileData, ...acceptedFileData };
      }
      return acceptedFileData;
    });

    await onDrop(
      Object.entries(acceptedFileData).reduce(
        (result, [k, { file }]) => ({ ...result, [k]: file }),
        {}
      )
    );
  };

  const handleRemove = (removedSHA256: string) => {
    onRemove(removedSHA256);
    setFileData((fd) => {
      const { [removedSHA256]: _, ...rest } = fd;
      return rest;
    });
  };

  const createThumbnail = (file: File) => {
    const ext = getExtension(file);
    let result = file_placeholder_text;
    if (["jpg", "png", "gif", "svg"].includes(ext)) {
      result = window.URL.createObjectURL(file);
    } else if (["mp4"].includes(ext)) {
      result = file_placeholder_video;
    } else {
      result = file_placeholder_text;
    }
    return <Thumbnail size="small" alt={file.name} source={result} />;
  };

  const uploadedFiles = Object.entries(fileData).length > 0 && (
    <div style={{ padding: "8px" }}>
      <Stack>
        {Object.entries(fileData).map(([sha256, { file }]) => (
          <Stack key={sha256} direction="row" justify="space-between">
            <Stack direction="row">
              {createThumbnail(file)}
              <div>
                {file.name} <PText>{file.size} bytes</PText>{" "}
              </div>
            </Stack>
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleRemove(sha256);
              }}
            >
              <Button size="slim" tone="critical" variant="primary">
                Remove
              </Button>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const fileUpload = !Object.entries(fileData).length && (
    <DropZone.FileUpload />
  );

  //   const extMap: Record<string, string> = {
  //     "image/*": ".gif, .jpg, .png, or .svg",
  //     "video/*": ".mp4",
  //   };

  // TODO: Figure out how to better customize this error message based on the
  //       intersection betweent fileType and mimeType...
  const errorMessage = hasError && (
    <Banner title="The following files couldn't be uploaded:" tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be ${dzFileParams.dzAccept}.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Stack>
      {errorMessage}
      <DropZone
        accept={dzFileParams.dzAccept}
        type={dzFileParams.dzType}
        onDrop={handleDrop}
        dropOnPage
        allowMultiple={allowMultiple}
      >
        {uploadedFiles}
        {fileUpload}
      </DropZone>
    </Stack>
  );
}
