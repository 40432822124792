import { Banner, Button, Layout } from "@shopify/polaris";
import { FallbackProps } from "react-error-boundary";
import {
  NotAuthorized as RootNotAuthorized,
  GeneralError as RootGeneralError,
} from "../errors";
import { PText } from "../shared/TextComponents";
import { CurrentUserData } from "./schemas/core";
import Stack from "../shared/Stack";

interface GeneralErrorProps {
  error: Error;
}

export function GeneralError(props: GeneralErrorProps) {
  return <RootGeneralError error={props.error} />;
}

export function ExpiredSession() {
  return (
    <Stack>
      <Banner title="User session expired" tone="warning">
        <PText>
          Your user session has expired. Please refresh to automatically login
          again or click the button below.
        </PText>
      </Banner>
      <Button
        variant="primary"
        tone="success"
        onClick={() => window.location.reload()}
      >
        Reload
      </Button>
    </Stack>
  );
}

interface NotAuthorizedProps {
  currentUserData?: CurrentUserData | null;
}

export function NotAuthorized(props: NotAuthorizedProps) {
  const waitTime = 48;
  var age = 49;

  if (props.currentUserData) {
    const now = new Date();
    age =
      (now.getTime() - props.currentUserData.user.createDt.getTime()) / 3600000;
  }

  const recentUserMsg = (
    <Stack>
      <PText>
        As a recently registered user, please wait while our administrators
        approve your account.
      </PText>
      <PText>{`You registered ${age.toFixed(2)} hours ago.`}</PText>

      <PText>
        <strong>MDH Staff:</strong> Make sure Krista Bauer, Deputy State
        Registrar and Supervisor of Registration and Fulfillment, is notified of
        your request, so that it can be confirmed with MNHS staff. MNHS staff
        should email peoplesearch@mnhs.org directly.
      </PText>
    </Stack>
  );

  return (
    <RootNotAuthorized customMsg={age < waitTime ? recentUserMsg : undefined} />
  );
}

export default function ErrorFallback({ error }: FallbackProps) {
  return (
    <Layout sectioned>
      <GeneralError error={error} />
    </Layout>
  );
}
