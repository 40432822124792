import { CurrentUserData } from "../schemas/core";

export const removeNulls = <T>(array: (T | null | undefined)[]): T[] => {
  return array.filter((element) => element !== null && element !== undefined);
};

export type PropsOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: P;
};

type DateType = "datetime" | "date" | "polarisDate";

export const isISOFormat = (value: string) => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?($|(Z$)|([-+]\d{2}:\d{2}$))/.test(
    value,
  );
};

/**
 * Renders a Date or an ISOformat date string in the specified format. If a
 * string is an invalid date it will be returned as is.
 * @param value The value to render as a string.
 * @param outputType The date format to use to convert the value to the value,
 * datetime will include the time portion, date will not, polarisDate will be
 * the specific YYYY-MM-DD format required by polaris TextField date-type
 * components.
 */
export const renderDatelikeAsString = (
  value: Date | string,
  outputType: DateType = "datetime",
) => {
  if (
    (typeof value === "string" && isISOFormat(value)) ||
    value instanceof Date
  ) {
    let datevalue = new Date(value);
    if (outputType === "date") {
      return datevalue.toLocaleDateString();
    } else if (outputType === "polarisDate") {
      return datevalue.toISOString().split("T")[0];
    } else {
      return datevalue.toLocaleString();
    }
  } else {
    return value;
  }
};

/**
 * Renders any value into a nicely formatted string for display in DetailFields
 * or FormFields.
 * @param value The value to render as a string.
 * @param dateType The date format to use to display the value, datetime will
 * include the time portion, date will not, polarisDate will be the specific
 * YYYY-MM-DD format required by polaris TextField date-type components.
 * @returns A string, or undefined if value is null or undefined.
 */
export const renderValueAsString = (
  value: any,
  dateType: "datetime" | "date" | "polarisDate" = "datetime",
) => {
  if (!value) {
    return undefined;
  } else if (value instanceof Date || typeof value === "string") {
    return renderDatelikeAsString(value, dateType);
  } else if (value instanceof Array) {
    return value
      .map((v): string | undefined => {
        const element = v.value ? v.value : v;
        return renderValueAsString(element, dateType);
      })
      .join(", ");
  } else {
    return String(value);
  }
};

export interface DefaultPageProps {
  currentUserData: CurrentUserData;
}

export const getExtension = (file: string | File) => {
  const f = file instanceof File ? file.name : file;
  return f.slice(((f.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const removeItem = <T>(list: T[], item: number | T) => {
  if (typeof item !== "number") {
    item = list.indexOf(item);
  }
  return [...list.slice(0, item), ...list.slice(item + 1)];
};

// This is no longer used as it was needed only when we were making the API
// convert media bytes responses to base64 encoded strings and then converting
// them BACK in the client so that we could do all this stuff.
// It is kept here solely for reference in case we need to convert encoded
// strings back to Blobs for some reason.
export const generateNewTabFileUrl = (
  base64EncodedContent: string,
  mimeType: "application/pdf" | "image/jpeg",
) => {
  const substr = base64EncodedContent.substring(
    `data:${mimeType};base64,`.length,
  );
  const binaryString = window.atob(substr);
  const bytesArray = new Uint8Array(binaryString.length);
  const blob = new Blob(
    [bytesArray.map((byte, i) => binaryString.charCodeAt(i))],
    { type: mimeType },
  );
  return URL.createObjectURL(blob);
};

export const formatDurationNeatly = (milliseconds: number) => {
  let t = milliseconds / 1000;
  let result = `${Math.ceil(t)} seconds`;
  if (t > 3600) {
    result = `${Math.ceil(t / 3600)} hours`;
  } else if (t > 60) {
    result = `${Math.ceil(t / 60)} minutes`;
  }
  return result;
};

export type DeeplyNestedRecord<T> = {
  [key: string]: DeeplyNestedRecord<T> | T;
};

export function convertSimpleRecordToNestedRecord<T>(
  input: Record<string, T>,
  separator: string,
): DeeplyNestedRecord<T>;
export function convertSimpleRecordToNestedRecord<T, U>(
  input: Record<string, T>,
  separator: string,
  handler?: (fullKey: string, value: T) => U,
): DeeplyNestedRecord<U>;
export function convertSimpleRecordToNestedRecord<T, U>(
  input: Record<string, T>,
  separator: string,
  handler?: (fullKey: string, value: T) => U,
) {
  let result: DeeplyNestedRecord<T | U> = {};
  let parent = result;

  Object.entries(input).map(([key, value]) => {
    parent = result;
    let parts = key.split(separator);
    parts.map((part, idx) => {
      if (idx + 1 === parts.length) {
        parent[part] = handler ? handler(key, value) : value;
      } else if (parent[part]) {
        // @ts-ignore: Understandably, typescript can't tell that this function
        // will stop running by the time the below would return a value of type T.
        parent = parent[part];
      } else {
        let newParent = {};
        parent[part] = newParent;
        parent = newParent;
      }
      // Return to make eslint happy:
      return parent;
    });
    // Return to make eslint happy:
    return result;
  });

  return result;
}

/**
 * Capitalizes the first letter of the passed string.
 * @param s
 * @returns
 */
export function toTitle(s: string) {
  return s.length > 1
    ? s[0].toLocaleUpperCase() + s.slice(1)
    : s.toLocaleUpperCase();
}

/**
 * @param k The key to delete.
 * @param obj The object to delete from.
 * @returns The element at the passed key, and a clone of the original object with
 * the passed key deleted. The original object will not be modified.
 */
export function pop<T extends string | number | symbol, U>(
  k: T,
  obj: Record<T, U>,
): [U, Record<T, U>] {
  const clone = { ...obj };
  const popped = clone[k];
  delete clone[k];
  return [popped, clone];
}
