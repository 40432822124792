import {
  Button,
  Collapsible,
  Divider,
  Layout,
  Page,
  Spinner,
} from "@shopify/polaris";
import { useState } from "react";

import { API_URL } from "../../env";
import { Heading, PText } from "../../shared/TextComponents";
import { requestAPICredsForUser } from "../api/endpoints";
import { CurrentUserData } from "../schemas/core";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";

interface UserAPICredsProps {
  currentUserData: CurrentUserData;
  generateCreds: (accessToken: string) => Promise<string>;
}

export default function UserAPICreds(props: UserAPICredsProps) {
  const [apiCreds, setAPICreds] = useState<string>();
  const [credsPanelOpen, setCredsPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const genAPICreds = async () => {
    setLoading(true);
    const creds = await props.generateCreds(props.currentUserData.accessToken);
    setAPICreds(creds);
    setLoading(false);
  };

  const credsDisplay = apiCreds && (
    <Stack>
      <div
        style={{
          //   background: props.currentUserData.prefs.useNightMode
          //     ? "DimGrey"
          // : "WhiteSmoke",
          background: "WhiteSmoke",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <PText breakWord>{apiCreds}</PText>
      </div>
      <PText>
        Copy this token immediately, as it will not be generated again.
      </PText>
    </Stack>
  );

  return (
    <Stack align="flex-start">
      <PText>
        {apiCreds
          ? "API sign-in credentials successfully generated:"
          : "Note that this will invalidate any existing API Creds."}
      </PText>
      <Collapsible id="generate-api-creds" open={credsPanelOpen}>
        <Stack>
          {credsDisplay}
          <Stack direction="row">
            <Button
              variant="primary"
              tone="success"
              onClick={() => genAPICreds()}
              disabled={loading}
            >
              Generate
            </Button>
            {loading && <Spinner size="small" />}
          </Stack>
        </Stack>
      </Collapsible>
      <Button onClick={() => setCredsPanelOpen((previous) => !previous)}>
        {credsPanelOpen ? "Cancel" : "Proceed"}
      </Button>
    </Stack>
  );
}

interface UserAPICredsPageProps {
  currentUserData: CurrentUserData;
}

export function UserAPICredsPage(props: UserAPICredsPageProps) {
  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <Stack spacing={4}>
              <Heading>One MNHS API</Heading>
              <Stack align="flex-start">
                <PText>
                  You can generate API sign-in credentials for your user account
                  below. Use the resulting token to sign in and pull data in
                  JSON form directly from the REST API.
                </PText>
                <Button
                  onClick={() => (window.location.href = `${API_URL}/docs`)}
                >
                  API Docs
                </Button>
              </Stack>
              <Divider />
              <Stack align="flex-start">
                <Heading>Generate API Creds</Heading>
                <UserAPICreds
                  currentUserData={props.currentUserData}
                  generateCreds={requestAPICredsForUser}
                />
              </Stack>
            </Stack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
