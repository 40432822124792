import * as yup from "yup";
import { countyNames } from "../reference/counties";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import { Labels, eventSchema, stringElementSchema } from "./core";

export const Gender = ["blank", "female", "male", "other"];

export const Ward = [
  "Unknown",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
];

export const CensusCounties = [
  ...countyNames,
  "La Pointe",
  "Lake and Cook",
  "Mankatah",
  "Wabashaw",
];

export const censusSchema = yup.object({
  id: yup.string().required(),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  familyName: yup.string().nullable(),
  suffix: yup.string().nullable(),
  gender: yup.string().oneOf(Gender),
  age: yup.string().nullable(),
  ethnicityOther: yup.string().nullable(),
  birthPlace: yup.string().nullable(),
  fatherBirthPlace: yup.string().nullable(),
  motherBirthPlace: yup.string().nullable(),
  censusDateStart: yup.date().nullable(),
  censusDateEnd: yup.date().nullable(),
  county: yup.string().oneOf(CensusCounties),
  ward: yup.string().oneOf(Ward),
  locality: yup.string().nullable(),
  ycord: yup.number().nullable(),
  pageId: yup.string().nullable(),
  lineInImage: yup.number().nullable(),
  additionalImageSHA256s: yup.array(yup.string()).nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema),
  certificateId: yup.string().nullable(),
  legacyId: yup.number().nullable(),
});

export type CensusRecord = yup.InferType<typeof censusSchema>;

export const censusInputSchema = censusSchema.shape({
  id: yup.string().nullable(),
  certificateId: yup
    .string()
    .matches(
      /^\d{4}?-[a-zA-Z_\d]*?-\d{4}?-\d*/,
      "Certificate No format must be YYYY-Reel_123-0000-0",
    )
    .nullable(),
});

export type CensusRecordInput = yup.InferType<typeof censusInputSchema>;

export const censusEventSchema = eventSchema.shape({
  state: censusSchema,
});

export const censusEventSchemaList = yup
  .array()
  .of(censusEventSchema)
  .required();

export const censusLabels: Labels<CensusRecord> = {
  id: "UUID",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  gender: "Gender",
  age: "Age",
  ethnicityOther: "Ethnicity",
  birthPlace: "Birth Place",
  fatherBirthPlace: "Father Birth Place",
  motherBirthPlace: "Mother Birth Place",
  censusDateStart: "Exact Census Date or Range Start",
  censusDateEnd: "Census Date Range End",
  county: "County",
  ward: "Ward",
  locality: "Locality",
  ycord: "YCord",
  pageId: "Census Page ID",
  lineInImage: "Line In Image",
  additionalImageSHA256s: "Additional Image SHA256s",
  alternateNames: "Alternate Names",
  certificateId: "Certificate Number",
  legacyId: "Legacy Census ID",
};

export const censusSorts: SearchSortFields<CensusRecord> = {
  prefix: { label: censusLabels["prefix"] },
  firstName: { label: censusLabels["firstName"] },
  middleName: { label: censusLabels["middleName"] },
  familyName: { label: censusLabels["familyName"] },
  suffix: { label: censusLabels["suffix"] },
  gender: { label: censusLabels["gender"] },
  ethnicityOther: { label: censusLabels["ethnicityOther"] },
  birthPlace: { label: censusLabels["birthPlace"] },
  fatherBirthPlace: { label: censusLabels["fatherBirthPlace"] },
  motherBirthPlace: { label: censusLabels["motherBirthPlace"] },
  censusDateStart: { label: "Census Date Start" },
  censusDateEnd: { label: "Census Date End" },
  county: { label: censusLabels["county"] },
  ward: { label: censusLabels["ward"] },
  locality: { label: censusLabels["locality"] },
  certificateId: { label: censusLabels["certificateId"] },
};

export const censusFilters: SearchFilterFields = {
  firstName: {
    label: censusLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: censusLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: censusLabels["familyName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  suffix: {
    label: censusLabels["suffix"],
    type: "string",
  },
  gender: {
    label: censusLabels["gender"],
    type: "string",
    validTerms: [...Gender],
  },
  age: {
    label: censusLabels["age"],
    type: "string",
  },
  ethnicityOther: {
    label: censusLabels["ethnicityOther"],
    type: "string",
    allowNullOps: true,
  },
  birthPlace: {
    label: censusLabels["birthPlace"],
    type: "string",
  },
  fatherBirthPlace: {
    label: censusLabels["fatherBirthPlace"],
    type: "string",
    allowNullOps: true,
  },
  motherBirthPlace: {
    label: censusLabels["motherBirthPlace"],
    type: "string",
    allowNullOps: true,
  },
  county: {
    label: censusLabels["county"],
    type: "string",
    validTerms: [...CensusCounties],
  },
  ward: {
    label: censusLabels["ward"],
    type: "string",
    validTerms: [...Ward],
  },
  alternateNames: {
    label: censusLabels["alternateNames"],
    type: "string",
  },
  certificateId: {
    label: censusLabels["certificateId"],
    type: "string",
  },
  legacyId: {
    label: censusLabels["legacyId"],
    type: "number",
  },
  hasImage: {
    label: "Has Image",
    type: "boolean",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const censusFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Census Date",
    startDayName: "censusStartDay",
    startMonthName: "censusStartMonth",
    startYearName: "censusStartYear",
    endDayName: "censusEndDay",
    endMonthName: "censusEndMonth",
    endYearName: "censusEndYear",
    startDateName: "censusDateStart",
    endDateName: "censusDateEnd",
  },
];
