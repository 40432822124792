import { yupResolver } from "@hookform/resolvers/yup";
import { ChoiceList } from "@shopify/polaris";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { BaseFormCompProps, FormFields, FormSection } from "../Form";
import Skeleton from "../Skeleton";
import { useRoles } from "../hooks/roleHooks";
import { useDeleteUser, useUpdateUser, useUser } from "../hooks/userHooks";
import {
  CurrentUserData,
  Role,
  User,
  UserInput,
  userInputSchema,
  userLabels,
} from "../schemas/core";
import { removeNulls } from "../utils/shared";
import { hasPermissions } from "./authutils";

interface RoleChecklistProps {
  currentUserData: CurrentUserData;
  title: ReactNode;
  roles: Role[];
  selectedRoleIds?: string[];
  onChange: (rolesUUIDs: string[]) => void;
  disabled?: boolean;
  genPerRoleHelpText?: (role: Role) => string;
  applyAuthority?: boolean;
}

/**
 * Role Checklist component.
 * @param currentUserData The CurrentUser who will be viewing the component.
 * @param title Title node to display above the Role checklist.
 * @param roles List of Roles pulled from the API.
 * @param selectedRoleIds Optional list of Role UUIDs that should be marked as
 * selected.
 * @param onChange Function to call when the Checklist is changed, will pass list
 * of selected roleUUIds.
 * @param disabled Whether the component is disabled or not.
 * @param genPerRoleHelpText Function to generate help text underneath each item
 * in the Checklist. Will pass the Role object for that item.
 * @param applyAuthority Pass true to hide Roles the currentUserData doesn't
 * have the authority to interact with.
 * @returns
 */
export function RoleChecklist({
  currentUserData,
  title,
  roles,
  selectedRoleIds,
  onChange,
  disabled,
  genPerRoleHelpText,
  applyAuthority,
}: RoleChecklistProps) {
  const roleChoices = Object.values(roles).map((r) => ({
    label: r.name,
    value: r.id,
    helpText: genPerRoleHelpText ? genPerRoleHelpText(r) : null,
  }));

  return (
    <ChoiceList
      allowMultiple
      title={title}
      choices={
        applyAuthority
          ? removeNulls(
              roleChoices.map((choice) =>
                currentUserData.authority.includes(choice.value)
                  ? choice
                  : null,
              ),
            )
          : roleChoices
      }
      selected={selectedRoleIds || []}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

interface UserFormProps extends BaseFormCompProps<User> {
  roles?: Role[];
}

export default function UserForm({
  record,
  currentUserData,
  onSubmitSuccess,
  onDeleteSuccess,
  roles,
}: UserFormProps) {
  const [userRoles, setUserRoles] = useState<string[]>(
    record ? record.roleIds : [],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<UserInput>({
    resolver: yupResolver(userInputSchema),
  });

  useEffect(() => {
    if (!record) {
      return;
    }
    reset({ ...userInputSchema.cast(record) });
  }, [reset, record]);

  const roleChecklist = roles && (
    <RoleChecklist
      currentUserData={currentUserData}
      title={<Subheading>Roles</Subheading>}
      roles={roles}
      onChange={(roleUUIDs) => setUserRoles(roleUUIDs)}
      selectedRoleIds={userRoles}
      applyAuthority
    />
  );

  const onSubmit = (inputData: UserInput) => {
    inputData.roleIds = userRoles;
  };

  return (
    <FormSection
      currentUserData={currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={userInputSchema}
      initialValues={record}
      mutateHook={useUpdateUser}
      deleteHook={
        hasPermissions(currentUserData, "remove_users")
          ? useDeleteUser
          : undefined
      }
      onSubmit={onSubmit}
      onMutateSuccess={onSubmitSuccess}
      onDeleteSuccess={onDeleteSuccess}
    >
      <FormFields
        control={control}
        spec={{
          name: { type: "text", required: true },
          email: { type: "text", required: true },
        }}
        labels={userLabels}
        errors={errors}
        reset={reset}
        getValues={getValues}
      />
      {roleChecklist}
    </FormSection>
  );
}

interface UserFormPageProps {
  currentUserData: CurrentUserData;
}

export function UserEditPage(props: UserFormPageProps) {
  const navigate = useNavigate();

  const { uuid } = useParams();

  const { data: user } = useUser(props.currentUserData.accessToken, uuid);
  const { data: roles } = useRoles(props.currentUserData.accessToken);

  const skeleton = !user && <Skeleton lines={2} />;

  const form = user && roles && (
    <Stack>
      <Stack spacing={1}>
        <Heading>{user.name}</Heading>
        <Subheading>{user.id}</Subheading>
      </Stack>
      <UserForm
        currentUserData={props.currentUserData}
        record={user}
        roles={roles}
        onSubmitSuccess={() => navigate(`../../${uuid}`)}
        onDeleteSuccess={() => navigate(`../../`)}
      />
    </Stack>
  );

  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>{skeleton || form}</Card>
    </BreadcrumbPage>
  );
}

export function UserAddPage(props: UserFormPageProps) {
  const navigate = useNavigate();

  const { data: roles } = useRoles(props.currentUserData.accessToken);

  const form = roles && (
    <Stack>
      <Stack spacing={1}>
        <Heading>Direct Add User</Heading>
      </Stack>
      <UserForm
        currentUserData={props.currentUserData}
        roles={roles}
        onSubmitSuccess={() => navigate(-1)}
      />
    </Stack>
  );

  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>{form}</Card>
    </BreadcrumbPage>
  );
}
