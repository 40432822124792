import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Button, DescriptionList } from "@shopify/polaris";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { DetailFieldsSpec } from "../Detail";
import Skeleton from "../Skeleton";
import { getUserActivity } from "../api/endpoints";
import { useUser } from "../hooks/userHooks";
import {
  CurrentUserData,
  Event,
  EventRecordType,
  EventStateField,
  Labels,
} from "../schemas/core";
import { censusPageLabels } from "../schemas/censusPage";
import { censusLabels } from "../schemas/census";
import { vetsGraveLabels } from "../schemas/vetsGrave";
import { goldStarLabels } from "../schemas/goldStar";
import { deathRecordLabels } from "../schemas/deathRecord";
import { birthRecordLabels } from "../schemas/birthRecord";
import { renderValueAsString } from "../utils/shared";
import { GoToRecordButton } from "./DomainJournal";

interface JournalEventTypeConfig<T = any> {
  labels: Labels<T>;
  spec: DetailFieldsSpec<T>;
  goToLink: string;
}

interface JournalActivityProps {
  events: Event<EventStateField[]>[];
  configs: Record<EventRecordType, JournalEventTypeConfig<any>>;
}

function JournalActivity(props: JournalActivityProps) {
  const navigate = useNavigate();

  const EventDetails = ({
    event,
    configs,
  }: {
    event: Event<EventStateField[]>;
    configs: Record<EventRecordType, JournalEventTypeConfig<any>>;
  }) => {
    const noState = event.state.length === 0;
    const noStateMsg = noState && (
      <PText>
        You are not authorized to view the record this event is associated with.
      </PText>
    );
    const deleteMsg = event.eventType === "Deleted" && (
      <PText>Record deleted.</PText>
    );
    const config = configs[event.recordType];
    const { labels, spec } = config;

    return (
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack direction="row">
            {event.recordType} {event.eventType}
            <GoToRecordButton event={event} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Subheading>Values after event</Subheading>
          {deleteMsg || noStateMsg || (
            <DescriptionList
              items={event.state.map((field) => ({
                term: labels[field.key] || field.key,
                description: renderValueAsString(
                  field.value,
                  spec[field.key] ? spec[field.key]?.dateType : "date"
                ),
              }))}
            />
          )}
          <Stack align="flex-end">
            <Button
              disabled={noState}
              onClick={() =>
                navigate(`${config.goToLink}${event.originatorID}`)
              }
            >
              Go To Record
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div>
      {props.events.map((event) => (
        <EventDetails
          key={`${event.originatorID}-${event.originatorVersion}`}
          event={event}
          configs={props.configs}
        />
      ))}
    </div>
  );
}

export interface JournalActivityPageProps {
  currentUserData: CurrentUserData;
}

export function JournalActivityPage(props: JournalActivityPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: user } = useUser(props.currentUserData.accessToken, uuid);

  const { data: events } = useQuery(
    ["userActivity"],
    async () =>
      uuid ? getUserActivity(props.currentUserData.accessToken, uuid) : null,
    { keepPreviousData: true }
  );

  const skeleton = (!user || !events) && <Skeleton lines={10} />;

  const configs: Record<EventRecordType, JournalEventTypeConfig<any>> = {
    BirthRecord: {
      goToLink: "/rma/birth-records/",
      labels: {
        ...birthRecordLabels,
        birthDateStart: "Birth Date Start",
        birthDateEnd: "Birth Date End",
        legacyBirthRecordId: "Legacy Id",
        legacyMd5ImageCode: "",
      },
      spec: {},
    },
    CensusRecord: {
      goToLink: "/rma/census/",
      labels: {
        ...censusLabels,
        censusDateStart: "Census Date Start",
        censusDateEnd: "Census Date End",
        legacyCensusId: "Legacy ID",
      },
      spec: {},
    },
    CensusPage: {
      goToLink: "/rma/census/page/",
      labels: censusPageLabels,
      spec: {},
    },
    DeathRecord: {
      goToLink: "/rma/death-records/",
      labels: {
        ...deathRecordLabels,
        deathDateStart: "Death Date Start",
        deathDateEnd: "Death Date End",
        certificateNumber: "Certificate Number",
        legacyDeathRecordId: "Legacy ID",
      },
      spec: {},
    },
    GoldStarRoll: {
      goToLink: "/rma/gold-star/",
      labels: {
        ...goldStarLabels,
        deathDateStart: "Death Date Start",
        deathDateEnd: "Death Date End",
      },
      spec: {},
    },
    VetsGraveRecord: {
      goToLink: "/rma/vets-grave/",
      labels: {
        ...vetsGraveLabels,
        deathDateStart: "Death Date Start",
        deathDateEnd: "Death Date End",
      },
      spec: {},
    },
  };

  const page = user && events && (
    <Card>
      <Stack>
        <Heading>{`Activity for user ${user.name}`}</Heading>
        <JournalActivity events={events} configs={configs} />
      </Stack>
    </Card>
  );

  return (
    <BreadcrumbPage
      breadcrumbAction={() => navigate("../../../")}
      breadcrumbs="both"
    >
      {skeleton || page}
    </BreadcrumbPage>
  );
}
