import { ComplexAction, Modal } from "@shopify/polaris";
import { ReactNode } from "react";

export interface PromptProps {
  title: string;
  active: boolean;
  primaryAction?: ComplexAction;
  onClose: () => void;
  children?: ReactNode;
}

export default function Prompt(props: PromptProps) {
  return (
    <Modal
      open={props.active}
      title={props.title}
      onClose={props.onClose}
      primaryAction={props.primaryAction}
    >
      <Modal.Section>{props.children}</Modal.Section>
    </Modal>
  );
}
