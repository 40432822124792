import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InlineError, Layout, Page, Select } from "@shopify/polaris";
import { XSmallIcon } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Subheading } from "../../shared/TextComponents";
import * as api from "../api/endpoints";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  ControlledSelectFormField,
  FormFields,
  FormSection,
} from "../Form";
import {
  useDeleteVetsGrave,
  useUpdateVetsGrave,
  useVetsGrave,
  useVetsGraveImage,
} from "../hooks/vetsGraveHooks";
import {
  BurialLocations,
  MilitaryServices,
  VetsGraveInput,
  vetsGraveInputSchema,
  vetsGraveLabels,
  VetsGraveRecord,
} from "../schemas/vetsGrave";
import Skeleton from "../Skeleton";
import ImageDropSuite from "../utils/ImageDropSuite";
import { DefaultPageProps } from "../utils/shared";

export default function VetsGraveForm(
  props: BaseFormCompProps<VetsGraveRecord>,
) {
  const [frontLoading, setFrontLoading] = useState(false);
  const [front, setFront] = useState<string | null>();

  const vetsGraveRecord = props.record;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<VetsGraveInput>({
    resolver: yupResolver(vetsGraveInputSchema),
    // defaultValues: vetsGraveInputSchema.cast(vetsGraveRecord),
  });

  const {
    fields: militaryServicesFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "militaryServices",
  });

  useEffect(() => {
    if (!vetsGraveRecord) {
      return;
    }
    setFront(vetsGraveRecord.imageFrontSHA256);
    reset(vetsGraveInputSchema.cast(vetsGraveRecord));
  }, [setFront, vetsGraveRecord, reset]);

  const { data: imageFront } = useVetsGraveImage(
    props.currentUserData.accessToken,
    front,
  );

  const onSubmit = (inputData: VetsGraveInput) => {
    inputData.imageFrontSHA256 = front || undefined;
  };

  const militaryServicesAccumulator = (
    <>
      <Stack align="flex-start">
        {militaryServicesFields.map((field, idx) => {
          const key = `militaryServices.${idx}.value`;
          return (
            <Stack direction="row">
              <Controller
                key={field.id}
                // Can't seem to refer back to the key const here, even when
                // casting as Path<>. Very odd.
                name={`militaryServices.${idx}.value`}
                control={control}
                render={({ field }) => (
                  <Select
                    label={key}
                    requiredIndicator
                    options={MilitaryServices.map((conflict) => ({
                      label: conflict,
                      value: conflict,
                    }))}
                    value={field.value}
                    labelHidden
                    onChange={field.onChange}
                  />
                )}
              />
              <Button icon={XSmallIcon} onClick={() => remove(idx)} />
            </Stack>
          );
        })}
        {errors.militaryServices && (
          <InlineError fieldID="militaryServices" message={"error"} />
        )}
        <Button onClick={() => append({ value: "" } as any)}>Add</Button>
      </Stack>
    </>
  );

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={vetsGraveInputSchema}
      initialValues={vetsGraveRecord}
      mutateHook={useUpdateVetsGrave}
      deleteHook={useDeleteVetsGrave}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <ImageDropSuite
        currentUserData={props.currentUserData}
        addImageEndpoint={api.addVetsGraveImage}
        front={{
          src: imageFront?.content,
          hash: front,
          setHash: setFront,
          loading: frontLoading,
          setLoading: setFrontLoading,
        }}
      />
      <FormFields
        control={control}
        spec={{
          prefix: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text", required: true },
          suffix: { type: "text" },
          deathDateStart: { type: "date" },
          deathDateEnd: { type: "date" },
        }}
        labels={vetsGraveLabels}
        errors={{ familyName: errors.familyName }}
        reset={reset}
        getValues={getValues}
      />
      <ControlledSelectFormField
        options={[...BurialLocations]}
        defaultValue="Unknown"
        fieldID="burialLocation"
        label={vetsGraveLabels.burialLocation}
        control={control}
        required
        error={errors.burialLocation}
      />
      <Subheading>Military Services</Subheading>
      {militaryServicesAccumulator}
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
    </FormSection>
  );
}

export function VetsGraveAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Card>
          <VetsGraveForm
            currentUserData={props.currentUserData}
            onSubmitSuccess={(id) => navigate(`../${id}`)}
          />
        </Card>
      </Layout>
    </Page>
  );
}

export function VetsGraveEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: vetsGraveRecord } = useVetsGrave(
    props.currentUserData.accessToken,
    uuid,
  );

  const skeleton = !vetsGraveRecord && <Skeleton lines={14} />;

  const form = vetsGraveRecord && (
    <VetsGraveForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={vetsGraveRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
