import { DescriptionList, Layout, Page } from "@shopify/polaris";
import { DefaultPageProps } from "./utils/shared";
import { Heading, Subheading } from "../shared/TextComponents";
import Card from "../shared/Card";
import { ENV } from "../env";
import { SYSADMIN_ROLE_ID } from "./schemas/core";

export default function Home(props: DefaultPageProps) {
  return (
    <Page>
      <Layout sectioned>
        <Card>
          <Heading>Welcome to MNHS Vital Records</Heading>
          {ENV === "stage" ? <Subheading>Staging Site</Subheading> : null}
          {props.currentUserData.user.roleIds.includes(SYSADMIN_ROLE_ID) && (
            // Currently have to maintain the version info separately from
            // the one in package.json. It seems like it might be an enormous
            // pain to set this value from pacakage.json, but that would be the
            // preferred way if we can do it client-side without loading the
            // whole package.json into the compiled static site...
            <DescriptionList
              items={[{ term: "Version", description: "1.0.2" }]}
            />
          )}
        </Card>
      </Layout>
    </Page>
  );
}
