import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import { deathRecordFilters } from "../schemas/deathRecord";
import { deathRecordSorts } from "../schemas/deathRecord";
import { deathRecordLabels } from "../schemas/deathRecord";
import { DeathRecord } from "../schemas/deathRecord";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import DeathRecordDetail from "./DeathRecordDetail";
import DeathRecordForm from "./DeathRecordForm";
import { getVitalRecordsDisplayName } from "./shared";

export function renderDeathRecordHit(record: DeathRecord) {
  const displayName = getVitalRecordsDisplayName(record);

  const {
    deathDateStart,
    county: countyName,
    certificateId: certificateNumber,
  } = record;

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        {deathDateStart && new Date(deathDateStart).toLocaleDateString()}{" "}
        {countyName}
      </PText>
      <PText>
        {certificateNumber && `Certificate No: ${certificateNumber}`}
      </PText>
    </>
  );
}

export default function DeathRecordSearchPage(
  props: BaseSearchPageComponentProps<DeathRecord>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={deathRecordLabels}
          renderSearchHit={{
            render: renderDeathRecordHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            DeathRecordDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            DeathRecordForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={deathRecordSorts}
          filterFields={deathRecordFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<DeathRecord>(props, "death-records")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "death-records")
          }
          recordName="Death Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_publish_remove_death_records"
          )}
          bulkUpdateSpec={{
            allowBulkUpdatePerm: "bulk_update_death_records",
            bulkUpdateURL: "/rma/death-records/bulk/edit",
          }}
        />
      </Layout>
    </Page>
  );
}
