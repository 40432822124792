import * as yup from "yup";
import { Dataset, DATASETS, Labels, userSchema } from "./core";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";

export const CMT_ACCESS_LEVELS = ["public", "unpublished"] as const;
export type CmtAccessLevel = (typeof CMT_ACCESS_LEVELS)[number];

export const CMT_CERTIFICATION = ["basic", "staff"] as const;
export type CmtCertification = (typeof CMT_CERTIFICATION)[number];

// Move/remove all this in RMA-89.
export const DOMAIN = [
  "birth-records",
  "census",
  "catalogue-items",
  "death-records",
  "gold-star",
  "vets-graves",
  "wwi-service",
  "wwi-bonuses",
  "wwi-soldiers-photos",
] as const;
export type Domain = (typeof DOMAIN)[number];
export const DomainLabels: Record<
  Domain,
  { singular: string; plural: string }
> = {
  "birth-records": { singular: "Birth Record", plural: "Birth Records" },
  census: { singular: "Census Record", plural: "Census Records" },
  "catalogue-items": { singular: "CMS Item", plural: "CMS Items" },
  "death-records": { singular: "Death Record", plural: "Death Records" },
  "gold-star": {
    singular: "Gold Star Roll Record",
    plural: "Gold Star Roll Records",
  },
  "vets-graves": {
    singular: "Veteran's Graves Record",
    plural: "Veteran's Graves Records",
  },
  "wwi-service": {
    singular: "WWI Service Record (Questionnaire)",
    plural: "WWI Service Records (Questionnaires)",
  },
  "wwi-bonuses": {
    singular: "WWI Bonus Record",
    plural: "WWI Bonus Records",
  },
  "wwi-soldiers-photos": {
    singular: "WWI Soldiers Photo",
    plural: "WWI Soldiers Photos",
  },
};

export const DATASET_DOMAIN_MAP: Record<Dataset, Domain> = {
  birthrecord: "birth-records",
  "catalogue-item": "catalogue-items",
  census: "census",
  deathrecord: "death-records",
  goldstar: "gold-star",
  vetsgrave: "vets-graves",
  "wwi-service": "wwi-service",
  "wwi-bonuses": "wwi-bonuses",
  "wwi-soldiers-photos": "wwi-soldiers-photos",
};

// Doing this for now cause it's expedient:
export const DatasetLabels: Record<
  Dataset,
  { singular: string; plural: string }
> = {
  birthrecord: DomainLabels["birth-records"],
  census: DomainLabels["census"],
  deathrecord: DomainLabels["death-records"],
  "catalogue-item": DomainLabels["catalogue-items"],
  goldstar: DomainLabels["gold-star"],
  vetsgrave: DomainLabels["vets-graves"],
  "wwi-service": DomainLabels["wwi-service"],
  "wwi-bonuses": DomainLabels["wwi-bonuses"],
  "wwi-soldiers-photos": DomainLabels["wwi-soldiers-photos"],
};

export const commentInputSchema = yup.object({
  id: yup.string().nullable(),
  content: yup.string().required(),
  userName: yup.string().required(),
  email: yup.string().required(),
  dataset: yup
    .string()
    .required()
    .oneOf([...DATASETS]),
  assignedTo: yup.string().required(),
  commentDt: yup.date().optional(),
  accessLevel: yup
    .string()
    .required()
    .oneOf([...CMT_ACCESS_LEVELS]),
  replyTo: yup.string().optional().nullable(),
  certification: yup
    .string()
    .required()
    .oneOf([...CMT_CERTIFICATION]),
});

export type CommentInput = yup.InferType<typeof commentInputSchema>;

// Comments have no schema because of the infinitely nested replies, which yup
// doesn't handle well.
export type Comment = {
  id: string;
  content: string;
  userName: string;
  email: string;
  dataset: Dataset;
  assignedTo: string;
  commentDt: Date;
  accessLevel: CmtAccessLevel;
  replyTo: string | null;
  replies: Comment[];
  certification: CmtCertification;
  isStaffComment: boolean;
};

export const commentLabels: Labels<Comment> = {
  id: "UUID",
  content: "Content",
  userName: "User Name",
  email: "Email Address",
  dataset: "Assigned Record Type",
  assignedTo: "Assigned to Record UUID",
  commentDt: "Comment Date",
  accessLevel: "Status",
  replyTo: "Reply to Comment UUID",
  replies: "Replies",
  certification: "Certification",
  isStaffComment: "Is Staff Comment",
};

export const commentSorts: SearchSortFields<Comment> = {
  commentDt: { label: commentLabels["commentDt"] },
  userName: { label: commentLabels["userName"] },
  email: { label: commentLabels["email"] },
  accessLevel: { label: commentLabels["accessLevel"] },
  certification: { label: commentLabels["certification"] },
};

export const commentFilters: SearchFilterFields = {
  email: { label: commentLabels["email"], type: "string" },
  userName: { label: commentLabels["userName"], type: "string" },
  commentDt: { label: commentLabels["commentDt"], type: "date" },
  certification: {
    label: commentLabels["certification"],
    type: "string",
    validTerms: [...CMT_CERTIFICATION],
  },
  dataset: {
    label: commentLabels["dataset"],
    type: "string",
    validTerms: [...DATASETS],
  },
};

export const commentSubInputSchema = yup.object({
  id: yup.string().nullable(),
  email: yup.string().required(),
  recordId: yup.string().required(),
  domain: yup.string().required().oneOf(DOMAIN),
  subscribeDt: yup.date().optional(),
});

export type CommentSubInput = yup.InferType<typeof commentSubInputSchema>;

export const commentSubSchema = commentSubInputSchema.shape({
  id: yup.string().required(),
  subscribeDt: yup.date().required(),
});

export type CommentSub = yup.InferType<typeof commentSubSchema>;

export const commentSubLabels: Labels<CommentSub> = {
  id: "UUID",
  email: "Email",
  recordId: "Record UUID",
  domain: "Dataset",
  subscribeDt: "Subscription Date",
};

export const commentUrlPatsInputSchema = yup.object({
  "birth-records": yup.string().required(),
  "catalogue-items": yup.string().required(),
  census: yup.string().required(),
  "death-records": yup.string().required(),
  "gold-star": yup.string().required(),
  "vets-graves": yup.string().required(),
});

export type CommentUrlPatsInput = yup.InferType<
  typeof commentUrlPatsInputSchema
>;

export type CommentUrlPats = yup.InferType<typeof commentUrlPatsInputSchema>;

export const commentUrlPatsLabels: Labels<CommentUrlPats> = {
  "birth-records": "Birth Records",
  "catalogue-items": "Collections Items",
  census: "Census Records",
  "death-records": "Death Records",
  "gold-star": "Gold Star Roll Records",
  "vets-graves": "Veterans' Grave Records",
};

export const commentBlockedEmailInputSchema = yup.object({
  id: yup.string().nullable(),
  email: yup.string().required(),
  blockDt: yup.date().optional(),
  blockedBy: yup.string().required(),
  reason: yup.string().optional(),
});

export type CommentBlockedEmailInput = yup.InferType<
  typeof commentBlockedEmailInputSchema
>;

export const commentBlockedEmailSchema = commentBlockedEmailInputSchema.shape({
  id: yup.string().required(),
  blockDt: yup.date().required(),
  blockedBy: userSchema,
});

export type CommentBlockedEmail = yup.InferType<
  typeof commentBlockedEmailSchema
>;

export const commentBlockedEmailLabels: Labels<CommentBlockedEmail> = {
  id: "UUID",
  email: "Email",
  blockDt: "Block Date",
  blockedBy: "Blocked By",
  reason: "Reason",
};

export const commentBlockedEmailSorts: SearchSortFields<CommentBlockedEmail> = {
  email: { label: commentBlockedEmailLabels["email"] },
  blockDt: { label: commentBlockedEmailLabels["blockDt"] },
};

export const commentBlockedEmailFilters: SearchFilterFields = {
  email: { label: commentBlockedEmailLabels["email"], type: "string" },
  blockDt: { label: commentBlockedEmailLabels["blockDt"], type: "date" },
  reason: {
    label: commentBlockedEmailLabels["reason"],
    type: "string",
    defaultOperator: "*",
  },
};
