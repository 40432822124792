import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button as MaterialButton,
  CircularProgress,
  Stack as MaterialStack,
  TextField as MaterialField,
  Card as MaterialCard,
  CardContent,
  Box as MaterialBox,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { InlineError } from "@shopify/polaris";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import GoogleIcon from "@mui/icons-material/Google";
import { PText } from "../shared/TextComponents";
import { requestPasswordReset } from "./api/endpoints";

const loginSchema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

type Login = yup.InferType<typeof loginSchema>;

export default function SignIn() {
  const [useFormLogin, setUseFormLogin] = useState(false);
  const [useResetForm, setUseResetForm] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [resetEmail, setResetEmail] = useState<string>();
  const [resetResult, setResetResult] = useState<string>();
  const [loginError, setLoginError] = useState<string>();

  const googleProvider = new GoogleAuthProvider();

  const auth = getAuth();

  const {
    control,
    // formState: { errors },
    reset,
    handleSubmit,
  } = useForm<Login>({
    resolver: yupResolver(loginSchema),
  });

  const googleBtn = (
    <MaterialButton
      variant="outlined"
      startIcon={<GoogleIcon />}
      onClick={async () => {
        await signInWithPopup(auth, googleProvider)
          .then()
          .catch((error) => {
            // TODO: Handle errors here. Not sure what errors to expect atm.
          });
      }}
    >
      Sign in with Google
    </MaterialButton>
  );

  const emailBtn = (
    <MaterialButton
      onClick={() => setUseFormLogin(true)}
      variant="outlined"
      startIcon={<EmailIcon />}
    >
      Sign in with email
    </MaterialButton>
  );

  const loginErrors: Record<string, string> = {
    "auth/wrong-password": "Invalid credentials",
  };

  const handleLoginError = (error: any) => {
    setLoginLoading(false);
    setLoginError(loginErrors[error.code] || error.code);
  };

  const loginForm = !loginLoading && (
    <form
      onSubmit={handleSubmit(async (loginInfo) => {
        setLoginLoading(true);
        setLoginError(undefined);
        await signInWithEmailAndPassword(
          auth,
          loginInfo.email,
          loginInfo.password
        )
          .then()
          .catch(async (error) => {
            if (error.code === "auth/user-not-found") {
              await createUserWithEmailAndPassword(
                auth,
                loginInfo.email,
                loginInfo.password
              )
                .then()
                .catch(handleLoginError);
            } else {
              handleLoginError(error);
            }
          });
      })}
    >
      <MaterialCard>
        <CardContent>
          <MaterialStack spacing={2}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <MaterialField
                  type="text"
                  label="Email"
                  size="small"
                  value={field.value}
                  onChange={field.onChange}
                  autoComplete="off"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <MaterialField
                  type="password"
                  label="Password"
                  size="small"
                  value={field.value}
                  onChange={field.onChange}
                  autoComplete="off"
                />
              )}
            />
            {loginError && <InlineError fieldID="login" message={loginError} />}
            <MaterialStack direction="row" justifyContent="space-between">
              <MaterialButton
                onClick={() => {
                  reset();
                  setUseFormLogin(false);
                  setLoginError(undefined);
                }}
              >
                Cancel
              </MaterialButton>
              <MaterialButton
                variant="contained"
                type="submit"
                disabled={loginLoading}
              >
                Login
              </MaterialButton>
            </MaterialStack>
            <MaterialButton onClick={() => setUseResetForm(true)}>
              Reset Password
            </MaterialButton>
          </MaterialStack>
        </CardContent>
      </MaterialCard>
    </form>
  );

  const passwordResetForm = (
    <MaterialCard>
      <CardContent>
        <MaterialStack spacing={2}>
          <MaterialField
            type="text"
            label="Email"
            size="small"
            value={resetEmail}
            onChange={(event) => setResetEmail(event.target.value)}
            autoComplete="off"
          />
          <MaterialStack direction="row" justifyContent="space-between">
            <MaterialButton
              onClick={() => {
                setUseResetForm(false);
                setResetEmail(undefined);
              }}
            >
              Cancel
            </MaterialButton>
            <MaterialButton
              variant="contained"
              disabled={loginLoading}
              type="submit"
              onClick={async () => {
                if (resetEmail) {
                  setLoginLoading(true);
                  setResetResult(undefined);
                  const result = await requestPasswordReset(resetEmail);
                  setResetResult(
                    result
                      ? "Password reset email sent. Please check your email."
                      : "Password reset email could not be sent!"
                  );
                  setLoginLoading(false);
                }
              }}
            >
              Submit
            </MaterialButton>
          </MaterialStack>
          <MaterialBox
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {loginLoading ? <CircularProgress /> : null}
            <PText>{resetResult}</PText>
          </MaterialBox>
        </MaterialStack>
      </CardContent>
    </MaterialCard>
  );

  const flowButtons = (
    <MaterialCard>
      <CardContent>
        <MaterialStack spacing={3}>
          {googleBtn}
          {emailBtn}
        </MaterialStack>
      </CardContent>
    </MaterialCard>
  );

  return (
    <>
      {useResetForm ? (
        passwordResetForm
      ) : useFormLogin ? (
        loginLoading ? (
          <CircularProgress />
        ) : (
          loginForm
        )
      ) : (
        flowButtons
      )}
    </>
  );
}
