import { BlockStack, InlineStack } from "@shopify/polaris";
import React from "react";

type StackSpacing = 0 | 1 | 2 | 4;

type StackJustify =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

type StackAlignment =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline";

interface StackProps {
  children?: React.ReactNode;
  direction?: "row" | "column";
  spacing?: StackSpacing;
  align?: StackAlignment;
  justify?: StackJustify;
  wrap?: boolean;
}

/**
 * Modeled after Material UI's Stack component, since we might want to switch to
 * Material UI over Shopify Polaris for the RMA.
 * @returns
 */
export default function Stack({
  children,
  direction = "column",
  spacing = 2,
  align = "stretch",
  justify = "flex-start",
  wrap = true,
}: StackProps) {
  const spacingMap: Record<StackSpacing, "0" | "100" | "200" | "500"> = {
    0: "0",
    1: "100",
    2: "200",
    4: "500",
  };

  const alignMap: Record<
    StackAlignment,
    "start" | "center" | "end" | "baseline" | "stretch"
  > = {
    "flex-start": "start",
    center: "center",
    "flex-end": "end",
    stretch: "stretch",
    baseline: "baseline",
  };

  const justifyMap: Record<
    StackJustify,
    | "start"
    | "center"
    | "end"
    | "space-around"
    | "space-between"
    | "space-evenly"
  > = {
    "flex-start": "start",
    center: "center",
    "flex-end": "end",
    "space-between": "space-between",
    "space-around": "space-around",
    "space-evenly": "space-evenly",
  };

  if (direction === "row") {
    return (
      <InlineStack
        align={justifyMap[justify]}
        blockAlign={alignMap[align]}
        gap={spacingMap[spacing]}
        wrap={wrap}
      >
        {children}
      </InlineStack>
    );
  } else {
    return (
      <BlockStack
        align={justifyMap[justify]}
        inlineAlign={alignMap[align]}
        gap={spacingMap[spacing]}
      >
        {children}
      </BlockStack>
    );
  }
}
