import * as yup from "yup";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";
import { Labels } from "./core";

export const wwiSolPhotoSchema = yup.object({
  id: yup.string().required(),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  familyName: yup.string().required(),
  suffix: yup.string().nullable(),
  residence: yup.string().nullable(),
  notes: yup.string().nullable(),
  alternateId: yup.number().required(),
  imageSHA256s: yup.array().required().of(yup.string().required()),
});

export type WWISolPhotoRecord = yup.InferType<typeof wwiSolPhotoSchema>;

export const wwiSolPhotoLabels: Labels<WWISolPhotoRecord> = {
  id: "UUID",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  residence: "Residence",
  notes: "Notes",
  alternateId: "Alternate ID",
  imageSHA256s: "Images",
};

export const wwiSolPhotoSorts: SearchSortFields<WWISolPhotoRecord> = {
  prefix: { label: wwiSolPhotoLabels["prefix"] },
  firstName: { label: wwiSolPhotoLabels["firstName"] },
  middleName: { label: wwiSolPhotoLabels["middleName"] },
  familyName: { label: wwiSolPhotoLabels["familyName"] },
  suffix: { label: wwiSolPhotoLabels["suffix"] },
  residence: {
    label: wwiSolPhotoLabels["residence"],
  },
};

export const wwiSolPhotoFilters: SearchFilterFields = {
  prefix: {
    label: wwiSolPhotoLabels["prefix"],
    type: "string",
  },
  firstName: {
    label: wwiSolPhotoLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: wwiSolPhotoLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: wwiSolPhotoLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: wwiSolPhotoLabels["suffix"],
    type: "string",
  },
  residence: {
    label: wwiSolPhotoLabels["residence"],
    type: "string",
  },
  "notes.normalized": {
    label: wwiSolPhotoLabels["notes"],
    type: "string",
    defaultOperator: "*",
  },
  alternateId: {
    label: wwiSolPhotoLabels["alternateId"],
    type: "string",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};
