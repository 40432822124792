import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  InlineError,
  Form as PolarisForm,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { Controller, Path, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  useCmtSubUrlPats,
  useUpdateCmtSubUrlPats,
} from "../hooks/commentHooks";
import {
  CommentUrlPats,
  CommentUrlPatsInput,
  commentUrlPatsInputSchema,
  commentUrlPatsLabels,
} from "../schemas/comment";
import { CurrentUserData } from "../schemas/core";
import { DefaultPageProps } from "../utils/shared";

interface CommentSubsUrlPatMgmtProps {
  currentUserData: CurrentUserData;
  urlPats: CommentUrlPats;
}

export default function CommentSubsUrlPatMgmt({
  urlPats,
  currentUserData,
}: CommentSubsUrlPatMgmtProps) {
  const mutation = useUpdateCmtSubUrlPats();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentUrlPatsInput>({
    resolver: yupResolver(commentUrlPatsInputSchema),
    defaultValues: commentUrlPatsInputSchema.cast(urlPats),
  });

  const submit = async (inputData: CommentUrlPatsInput) => {
    await mutation.mutateAsync({
      accessToken: currentUserData.accessToken,
      inputData,
    });
  };

  return (
    <PolarisForm onSubmit={handleSubmit(submit)}>
      <Stack>
        {Object.keys(urlPats).map((k) => {
          const key = k as keyof CommentUrlPatsInput;
          return (
            <Stack key={uuid()}>
              <Controller
                name={k as Path<CommentUrlPatsInput>}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label={commentUrlPatsLabels[key]}
                    value={field.value}
                    requiredIndicator
                    autoComplete="off"
                    onChange={field.onChange}
                  />
                )}
              />
              {errors[key] && (
                <InlineError
                  fieldID={k}
                  message={errors[key].message?.toString() || ""}
                />
              )}
            </Stack>
          );
        })}
        <Stack direction="row" align="center">
          <Button
            submit
            variant="primary"
            tone="success"
            disabled={mutation.isLoading}
          >
            Submit
          </Button>
          {mutation.isLoading && <Spinner size="small" />}
        </Stack>
      </Stack>
    </PolarisForm>
  );
}

export function CommentSubsUrlPatMgmtPage(props: DefaultPageProps) {
  const { data: urlPats } = useCmtSubUrlPats(props.currentUserData.accessToken);

  const pats = urlPats && (
    <CommentSubsUrlPatMgmt
      currentUserData={props.currentUserData}
      urlPats={urlPats}
    />
  );

  return (
    <BreadcrumbPage breadcrumbAction="up">
      <Card>
        <Stack>
          <Heading>Comment Subscription Email Settings</Heading>
          <PText>
            Use this page to configure the url patterns used by the automated
            comment subscription-related emails to generate un/subscribe links
            and links to records.
          </PText>
          <Stack spacing={0}>
            <PText>
              {/* In {``} so we can use the literal {} */}
              {`You may use the following keys in curly braces (e.g. {recordId}), `}
              which will be pulled from the comment un/subscription data end
              embedded into the url patterns when the email is generated:
            </PText>
            <ul>
              <li>email</li>
              <li>recordId</li>
              <li>domain</li>
            </ul>
          </Stack>
          {pats}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
