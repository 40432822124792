import { removeNulls } from "../utils/shared";

export function getVitalRecordsDisplayName<
  T extends {
    firstName?: string | null | undefined;
    middleName?: string | null | undefined;
    familyName?: string | null | undefined;
  },
>(record: T, separator: string = " ") {
  return removeNulls([
    record.firstName,
    record.middleName,
    record.familyName,
  ]).join(separator);
}
