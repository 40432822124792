const residences = [
  "Aitkin County, Minnesota, United States",
  "Alaska, United States",
  "Anoka County, Minnesota, United States",
  "Australia",
  "Becker County, Minnesota, United States",
  "Beltrami County, Minnesota, United States",
  "Benton County, Minnesota, United States",
  "Big Stone County, Minnesota, United States",
  "Blue Earth County, Minnesota, United States",
  "Brown County, Minnesota, United States",
  "California, United States",
  "Canada",
  "Carlton County, Minnesota, United States",
  "Carver County, Minnesota, United States",
  "Cass County, Minnesota, United States",
  "Chippewa County, Minnesota, United States",
  "Chisago County, Minnesota, United States",
  "Clay County, Minnesota, United States",
  "Clearwater County, Minnesota, United States",
  "Colorado, United States",
  "Cook County, Minnesota, United States",
  "Cottonwood County, Minnesota, United States",
  "Crow Wing County, Minnesota, United States",
  "Dakota County, Minnesota, United States",
  "Dodge County, Minnesota, United States",
  "Douglas County, Minnesota, United States",
  "Faribault County, Minnesota, United States",
  "Fillmore County, Minnesota, United States",
  "Freeborn County, Minnesota, United States",
  "Goodhue County, Minnesota, United States",
  "Grant County, Minnesota, United States",
  "Hennepin County, Minnesota, United States",
  "Houston County, Minnesota, United States",
  "Hubbard County, Minnesota, United States",
  "Idaho, United States",
  "Illinois, United States",
  "Indiana, United States",
  "Iowa, United States",
  "Isanti County, Minnesota, United States",
  "Itasca County, Minnesota, United States",
  "Jackson County, Minnesota, United States",
  "Kanabec County, Minnesota, United States",
  "Kandiyohi County, Minnesota, United States",
  "Kansas, United States",
  "Kittson County, Minnesota, United States",
  "Koochiching County, Minnesota, United States",
  "Lac qui Parle County, Minnesota, United States",
  "Lake County, Minnesota, United States",
  "Lake of the Woods County, Minnesota, United States",
  "Le Sueur County, Minnesota, United States",
  "Lincoln County, Minnesota, United States",
  "Lyon County, Minnesota, United States",
  "Mahnomen County, Minnesota, United States",
  "Marshall County, Minnesota, United States",
  "Martin County, Minnesota, United States",
  "Massachusetts, United States",
  "McLeod County, Minnesota, United States",
  "Meeker County, Minnesota, United States",
  "Michigan, United States",
  "Mille Lacs County, Minnesota, United States",
  "Minnesota, United States",
  "Missouri, United States",
  "Montana, United States",
  "Morrison County, Minnesota, United States",
  "Mower County, Minnesota, United States",
  "Murray County, Minnesota, United States",
  "Nebraska, United States",
  "Nergenia, Minnesota, United States",
  "New Jersey, United States",
  "New York, United States",
  "Nicollet County, Minnesota, United States",
  "Nobles County, Minnesota, United States",
  "Norman County, Minnesota, United States",
  "North Dakota, United States",
  "Ohio, United States",
  "Oklahoma, United States",
  "Olmsted County, Minnesota, United States",
  "Oregon, United States",
  "Otter Tail County, Minnesota, United States",
  "Pennington County, Minnesota, United States",
  "Pennsylvania, United States",
  "Pine County, Minnesota, United States",
  "Pipestone County, Minnesota, United States",
  "Polk County, Minnesota, United States",
  "Pope County, Minnesota, United States",
  "Ramsey County, Minnesota, United States",
  "Red Lake County, Minnesota, United States",
  "Redwood County, Minnesota, United States",
  "Renville County, Minnesota, United States",
  "Rice County, Minnesota, United States",
  "Rock County, Minnesota, United States",
  "Roseau County, Minnesota, United States",
  "Saint Louis County, Minnesota, United States",
  "Scott County, Minnesota, United States",
  "Sherburne County, Minnesota, United States",
  "Sibley County, Minnesota, United States",
  "South Dakota, United States",
  "Stearns County, Minnesota, United States",
  "Steele County, Minnesota, United States",
  "Stevens County, Minnesota, United States",
  "Sweden",
  "Swift County, Minnesota, United States",
  "Texas, United States",
  "Todd County, Minnesota, United States",
  "Traverse County, Minnesota, United States",
  "Wabasha County, Minnesota, United States",
  "Wadena County, Minnesota, United States",
  "Waseca County, Minnesota, United States",
  "Washington County, Minnesota, United States",
  "Washington, D.C., United States",
  "Washington, United States",
  "Watonwan County, Minnesota, United States",
  "Wilkin County, Minnesota, United States",
  "Winona County, Minnesota, United States",
  "Wisconsin, United States",
  "Wright County, Minnesota, United States",
  "Yellow Medicine County, Minnesota, United States",
];

export default residences;
