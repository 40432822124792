import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { getEmailAutomation, getEmailAutomations } from "../api/endpoints";
import { EmailRecipientInput } from "../schemas/core";
import { axiosInstance, baseAxiosConfig } from "../api/shared";

export function useEmailAutomations(accessToken: string) {
  return useQuery(
    ["emailAutomation", accessToken],
    async () => await getEmailAutomations(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useEmailAutomation(accessToken: string, uuid: string) {
  return useQuery(
    ["emailAutomation", accessToken, uuid],
    async () => await getEmailAutomation(accessToken, uuid),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUpdateEmailAutomation() {
  const queryClient = useQueryClient();

  const updateEmailAutomation = async (
    accessToken: string,
    inputData: EmailRecipientInput,
  ): Promise<string> => {
    const resp = await axiosInstance.post(
      `/email-autos/${inputData.purpose}`,
      inputData,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  };

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: EmailRecipientInput;
    }) => {
      return updateEmailAutomation(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["emailAutomation"]);
      },
    },
  );
}
