import { Tabs } from "@shopify/polaris";
import { useState } from "react";
import { hasPermissions } from "../auth/authutils";
import { DetailFieldsSpec } from "../Detail";
import { JournalDomainEventDisplay } from "./DomainJournal";
import { CurrentUserData, Event, Labels, PermissionId } from "../schemas/core";

export interface EventHistoryuProps<T extends object> {
  currentUserData: CurrentUserData;
  labels: Labels<T>;
  spec: DetailFieldsSpec<T>;
  viewJournalPerm: PermissionId;
  eventHistory?: Event<T>[] | null;
  children: JSX.Element;
}

/**
 * A wrapper that will display the single passed child on a Current tab and the
 * event history related to that child's record on a History tab. If the current
 * user doesn't have the passed permission, no tabs will be displayed. Instead,
 * the child will be displayed without any wrapper.
 * @param currentUserData The current logged in user's data.
 * @param labels A Labels object that matches the record type T.
 * @param spec A DetailFieldsSpec that matches the record type T.
 * @param viewJournalPerm A PermissionId to use to determine if the logged in user
 * can see event history or not.
 * @param eventHistory Optional list of Event<T>. If left null or undefined, then
 * the wrapper component will not be included in the return.
 * @returns
 */
export default function EventHistory<T extends object>(
  props: EventHistoryuProps<T>
) {
  const [selectedTab, setSelectedTab] = useState(0);

  const history = props.eventHistory && (
    <JournalDomainEventDisplay
      events={props.eventHistory}
      labels={props.labels}
      spec={props.spec}
    />
  );

  const tabs = props.eventHistory && (
    <Tabs
      tabs={[
        {
          id: "current",
          content: "Current",
          panelID: "current",
        },
        {
          id: "history",
          content: "History",
          panelID: "history",
        },
      ]}
      selected={selectedTab}
      onSelect={(tabIdx) => setSelectedTab(tabIdx)}
    >
      {[<>{props.children}</>, <>{history}</>][selectedTab]}
    </Tabs>
  );
  const noTabs = <>{props.children}</>;

  return (
    <>
      {hasPermissions(props.currentUserData, props.viewJournalPerm) &&
      props.eventHistory
        ? tabs
        : noTabs}
    </>
  );
}
