import { ReactNode, useEffect, useState } from "react";
import * as searchutils from "./searchutils";
import { CurrentUserData, SearchResult } from "../schemas/core";
import { useQuery } from "react-query";
import { SearchHitRenderSuite, SearchResults } from "./SearchResults";
import { Icon, Popover, TextField } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";

interface MiniSearchProps<T extends object> {
  currentUserData: CurrentUserData;
  searchQuery: (
    props: searchutils.SearchQueryProps
  ) => Promise<SearchResult<T>>;
  hitOnClick: (record: T) => void;
  renderHit: ((record: T) => ReactNode) | SearchHitRenderSuite<T>;
  label?: string;
}

export default function MiniSearch<T extends { id: string }>(
  props: MiniSearchProps<T>
) {
  const [query, setQuery] = useState("");
  const [resultsActive, setResultsActive] = useState(false);

  const { data: searchRecords, isLoading } = useQuery(
    [`miniSearch`, props.currentUserData.accessToken, query],
    async () =>
      props.searchQuery({
        accessToken: props.currentUserData.accessToken,
        query,
        pageNum: 1,
        pageSize: 10,
      }),
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (query !== "") {
      setResultsActive(true);
    }
  }, [query]);

  const input = (
    <TextField
      label="Search"
      labelHidden
      onChange={(value) => setQuery(value)}
      placeholder={props.label || "Search"}
      value={query}
      prefix={<Icon source={SearchIcon} tone="base" />}
      autoComplete="off"
    />
  );

  return (
    <>
      <Popover
        sectioned
        active={resultsActive}
        activator={input}
        onClose={() => setResultsActive(false)}
        fullWidth
        autofocusTarget="none"
      >
        <SearchResults
          result={searchRecords}
          isLoading={isLoading}
          renderHit={props.renderHit}
          hitOnClick={props.hitOnClick}
        />
      </Popover>
    </>
  );
}
