import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { getVitalRecordsDisplayName } from "./shared";
import {
  wwiServiceFilters,
  wwiServiceLabels,
  WWIServiceRecord,
  wwiServiceSorts,
} from "../schemas/wwiService";
import WWIServiceForm from "./WWIServiceForm";
import WWIServiceDetail from "./WWIServiceDetail";

export function renderWWIServiceHit(record: WWIServiceRecord) {
  const displayName = getVitalRecordsDisplayName(record);
  const { birthDateStart } = record;

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        {birthDateStart && new Date(birthDateStart).toLocaleDateString()}
        {record.birthDateEnd ? " (Inexact Date)" : null}
      </PText>
      <PText>{record.residencePriorToService}</PText>
    </>
  );
}

export default function WWIServiceSearchPage(
  props: BaseSearchPageComponentProps<WWIServiceRecord>,
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={wwiServiceLabels}
          renderSearchHit={{
            render: renderWWIServiceHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            WWIServiceDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            WWIServiceForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={wwiServiceSorts}
          filterFields={wwiServiceFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<WWIServiceRecord>(props, "wwi-service")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "wwi-service")
          }
          recordName="WWI Service Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_remove_wwi_service_records",
          )}
        />
      </Layout>
    </Page>
  );
}
