import { useMutation, useQueryClient } from "react-query";
import {
  performBulkRMAUpdate,
  useDeleteRecord,
  useRecord,
  useRMAEvents,
  useRMAMedia,
  useUpdateRecord,
} from "../api/genericEndpoints";
import {
  DeathRecord,
  deathRecordEventSchemaList,
  DeathRecordInput,
  deathRecordSchema,
} from "../schemas/deathRecord";

const endpoint = "death-records";

export function useDeathRecord(accessToken: string, uuid?: string) {
  return useRecord<DeathRecord>(endpoint, deathRecordSchema, accessToken, uuid);
}

export function useUpdateDeathRecord() {
  return useUpdateRecord<DeathRecordInput>(endpoint);
}

export function useDeleteDeathRecord() {
  return useDeleteRecord(endpoint);
}

export function useDeathRecordEvents(accessToken: string, uuid?: string) {
  return useRMAEvents<DeathRecord>(
    endpoint,
    deathRecordEventSchemaList,
    accessToken,
    uuid,
  );
}

export function useBulkUpdateDeathRecords() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      note,
      records,
    }: {
      accessToken: string;
      note?: string;
      records: DeathRecord[];
    }) => {
      return performBulkRMAUpdate(accessToken, endpoint, records, note);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([endpoint]);
      },
    },
  );
}
export function useDeathRecordImage(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
