import {
  Button,
  FormLayout,
  InlineError,
  Form as PolarisForm,
  Select,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { XSmallIcon } from "@shopify/polaris-icons";
import { ReactNode, useState } from "react";
import {
  ArrayPath,
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Path,
  PathValue,
  SubmitHandler,
  useFieldArray,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { UseMutationResult } from "react-query";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import Stack from "../shared/Stack";
import counties from "./reference/counties";
import { CurrentUserData, Labels } from "./schemas/core";
import { DeletePrompt, DeleteSuccessPrompt } from "./utils/DeleteComponents";
import { PropsOfType, renderValueAsString } from "./utils/shared";

interface FormSectionProps<T extends { id: string }, IT extends object> {
  currentUserData: CurrentUserData;
  inputSchema: yup.ObjectSchema<any>;
  initialValues?: T | null;
  onSubmit?: (inputData: IT) => void;
  handleSubmit: UseFormHandleSubmit<IT>;
  mutateHook: () => UseMutationResult<
    string | undefined,
    any,
    { accessToken: string; inputData: IT },
    any
  >;
  deleteHook?: () => UseMutationResult<
    boolean,
    any,
    { accessToken: string; uuid: string },
    any
  >;
  onMutateSuccess?: (result?: string) => void;
  onDeleteSuccess?: () => void;
  onClearAll?: () => void;
  additionalActions?: ReactNode;
  children?: ReactNode;
}

export function FormSection<T extends { id: string }, IT extends object>(
  props: FormSectionProps<T, IT>,
) {
  const mutation = props.mutateHook();

  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);

  const deleteMutation = props.deleteHook ? props.deleteHook() : null;

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const deleteButton = deleteMutation && (
    <Button
      tone="critical"
      disabled={deleteMutation.isLoading}
      onClick={toggleDeletePrompt}
    >
      Delete
    </Button>
  );

  const deletePrompt = deleteMutation && props.initialValues?.id && (
    <DeletePrompt
      id={props.initialValues.id}
      active={deletePromptActive}
      togglePrompt={toggleDeletePrompt}
      onDelete={(id: string) => {
        deleteMutation.mutate(
          { accessToken: props.currentUserData.accessToken, uuid: id },
          {
            onSuccess: () => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
    />
  );

  const deleteSuccessPrompt = deleteMutation && props.initialValues?.id && (
    <DeleteSuccessPrompt
      id={props.initialValues.id}
      active={deleteConfirmActive}
      onAck={() => (props.onDeleteSuccess ? props.onDeleteSuccess() : null)}
    />
  );

  const onMutateSuccess = (result?: string) => {
    if (props.onMutateSuccess) {
      return props.onMutateSuccess(result);
    }
  };

  const submit: SubmitHandler<IT> = async (inputData: IT) => {
    if (props.onSubmit) {
      props.onSubmit(props.inputSchema.cast(inputData));
    }

    mutation.mutate(
      {
        accessToken: props.currentUserData.accessToken,
        inputData: inputData,
      },
      {
        onSuccess: onMutateSuccess,
      },
    );
  };

  const clearBtn = props.onClearAll && (
    <Button onClick={props.onClearAll} disabled={mutation.isLoading}>
      Clear
    </Button>
  );

  return (
    <>
      {deletePrompt}
      {deleteSuccessPrompt}
      <PolarisForm
        onSubmit={props.handleSubmit(submit, (errors) =>
          Object.entries(errors).map(([field, error]) =>
            console.error(
              `Validation error: ${field}, reason = ${error.message}`,
            ),
          ),
        )}
      >
        <FormLayout>
          {props.children}
          <Stack direction="row">
            {clearBtn}
            <Button
              submit
              variant="primary"
              tone="success"
              disabled={mutation.isLoading}
            >
              Submit
            </Button>
            <Stack direction="row">
              {mutation.isLoading && <Spinner size="small" />}
              {deleteMutation ? deleteButton : null}
              {props.additionalActions}
            </Stack>
          </Stack>
        </FormLayout>
      </PolarisForm>
    </>
  );
}

export interface BaseFormCompProps<T extends object> {
  currentUserData: CurrentUserData;
  record?: T | null;
  onSubmitSuccess: (result?: string) => void;
  onDeleteSuccess?: () => void;
  additionalActions?: ReactNode;
}

export type FormFieldType = "text" | "date" | "number";

export type FormFieldSpec<T> = {
  [P in keyof T]?: {
    type: FormFieldType;
    required?: boolean;
    lines?: number;
    sideEffect?: (value: string) => void;
    disabled?: boolean;
  };
};

interface BaseFormFieldProps<T extends object> {
  control: Control<T>;
}

interface FormFieldProps {
  type: FormFieldType;
  label: string;
  value: string | null | undefined;
  onChange: (v: string) => void;
  required?: boolean;
  labelHidden?: boolean;
  onClearButtonClick?: () => void;
  lines?: number;
  disabled?: boolean;
}

export function FormField(props: FormFieldProps) {
  return (
    <TextField
      type={props.type}
      label={props.label}
      multiline={props.lines ? props.lines : 1}
      value={renderValueAsString(props.value, "polarisDate")}
      onChange={props.onChange}
      requiredIndicator={props.required}
      clearButton={props.onClearButtonClick ? true : false}
      labelHidden={props.labelHidden}
      onClearButtonClick={props.onClearButtonClick}
      disabled={props.disabled}
      autoComplete="off"
    />
  );
}

interface FormFieldsProps<T extends object> extends BaseFormFieldProps<T> {
  spec: FormFieldSpec<T>;
  labels: Labels<T>;
  reset: UseFormReset<T>;
  getValues: UseFormGetValues<T>;
  errors: Partial<FieldErrorsImpl<T>>;
}

/**
 * Convenience component that generates text and date-based form fields for data
 * entry based on a FormFieldSpec.
 * @param props formFieldsProps, which consist of:
 * @param props.control A react-hook-form Control object generated by useForm.
 * @param props.spec The FormFieldSpec to use to generate FormFields.
 * @param props.labels A Labels object describing the labels to use for each
 * form field.
 * @param props.errors An object of FieldErrors for some or all of the fields
 * described in props.spec. Pulled from formState: { errors } in useForm.
 * @param props.reset The reset function generated by useForm.
 * @param props.getValues The getValues function generated by useForm.
 * @returns A TextField component wrapped in a Controller for each field
 * described in the FormFieldSpec.
 */
export function FormFields<T extends { id?: string | null }>(
  props: FormFieldsProps<T>,
) {
  return (
    <>
      {Object.entries(props.spec).map(([k, v]) => {
        const key = k as keyof T;
        return (
          <Stack key={uuid()}>
            <Controller
              name={key as Path<T>}
              control={props.control}
              render={({ field }) => (
                <FormField
                  type={v.type}
                  lines={v.lines}
                  label={props.labels[key]}
                  value={field.value}
                  required={v.required}
                  onChange={(value) => {
                    field.onChange(value);
                    if (v.sideEffect) {
                      v.sideEffect(value);
                    }
                  }}
                  disabled={v.disabled}
                  onClearButtonClick={() =>
                    props.reset({
                      ...props.getValues(),
                      [k as keyof T]: null,
                    })
                  }
                />
              )}
            />
            {props.errors[key] && (
              <InlineError
                fieldID={k}
                message={props.errors[key]?.message?.toString() || ""}
              />
            )}
          </Stack>
        );
      })}
    </>
  );
}

interface ControlledSelectFormFieldProps<T extends object>
  extends BaseFormFieldProps<T> {
  label: string;
  fieldID: string;
  defaultValue: any;
  required?: boolean;
  options: string[];
  labelHidden?: boolean;
  error: FieldError | undefined;
}

export function ControlledSelectFormField<T extends object>(
  props: ControlledSelectFormFieldProps<T>,
) {
  return (
    <>
      <Controller
        name={props.fieldID as Path<T>}
        control={props.control}
        defaultValue={props.defaultValue}
        rules={{ required: props.required }}
        render={({ field }) => (
          <Select
            label={props.label}
            requiredIndicator={props.required}
            options={props.options.map((opt) => ({
              label: opt,
              value: opt,
            }))}
            value={field.value}
            onChange={field.onChange}
            labelHidden={props.labelHidden}
          />
        )}
      />
      {props.error && (
        <InlineError fieldID={"county"} message={props.error.message || ""} />
      )}
    </>
  );
}

interface CountyFormFieldProps {
  label: string;
  required?: boolean;
  value: string | null | undefined;
  onChange: (selected: string, id: string) => void;
  labelHidden?: boolean;
}

export function CountyFormField(props: CountyFormFieldProps) {
  return (
    <Select
      label={props.label}
      requiredIndicator={props.required}
      options={counties.map(({ EntityName }) => ({
        label: EntityName,
        value: EntityName,
      }))}
      value={props.value?.toString()}
      onChange={props.onChange}
      labelHidden={props.labelHidden}
    />
  );
}

interface ControlledCountyFormFieldProps<T extends object>
  extends BaseFormFieldProps<T> {
  label: string;
  required?: boolean;
  error: FieldError | undefined;
}

export function ControlledCountyFormField<T extends { county: string }>(
  props: ControlledCountyFormFieldProps<T>,
) {
  return (
    <>
      <Controller
        name={"county" as Path<T>}
        control={props.control}
        defaultValue={"Unknown" as PathValue<T, Path<T>>}
        rules={{ required: props.required }}
        render={({ field }) => (
          <CountyFormField
            label={props.label}
            required={props.required}
            value={field.value?.toString()}
            onChange={field.onChange}
          />
        )}
      />
      {props.error && (
        <InlineError fieldID={"county"} message={props.error.message || ""} />
      )}
    </>
  );
}

interface AccumulativeTextFieldProps<T extends object>
  extends BaseFormFieldProps<T> {
  register: UseFormRegister<T>;
  fieldName: PropsOfType<T, { value: string }[] | null | undefined>;
  errors: FieldError | { value?: FieldError }[] | undefined;
}

export function AccumulativeTextField<T extends object>(
  props: AccumulativeTextFieldProps<T>,
) {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: String(props.fieldName) as ArrayPath<T>,
  });

  return (
    <>
      <Stack align="flex-start">
        {fields.map((field, idx) => {
          const key = `${String(props.fieldName)}.${idx}.value`;
          const kwProps = props.register(key as Path<T>);
          return (
            <Stack key={key}>
              <Stack direction="row">
                <Controller
                  key={field.id}
                  name={kwProps.name}
                  control={props.control}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      label={key}
                      value={field.value ? String(field.value) : undefined}
                      labelHidden
                      autoComplete="off"
                      onChange={field.onChange}
                    />
                  )}
                />
                <Button icon={XSmallIcon} onClick={() => remove(idx)} />
              </Stack>
              {Array.isArray(props.errors) && props.errors[idx] && (
                <InlineError
                  fieldID={field.id}
                  message={props.errors[idx].value?.message || ""}
                />
              )}
            </Stack>
          );
        })}
        {props.errors && !Array.isArray(props.errors) && (
          <InlineError
            fieldID={String(props.fieldName)}
            message={props.errors.message || ""}
          />
        )}
        {/* FIXME: Using "as any" here seems very weird but was the only way I 
        could figure out how to get this to work. There must be some way to 
        further constrain the inputs to this function to only allow fields that
        have the appropriate type signature (i.e. {value: string}[]). Assuming
        that's even the issue. */}
        <Button onClick={() => append({ value: "" } as any)}>Add</Button>
      </Stack>
    </>
  );
}
