import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { initializeApp } from "firebase/app";
import axios from "axios";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import ErrorFallback from "./errors";
import RMA from "./rma/App";
import Shopify from "./shopify/AppWrapper";
import { getAuth } from "firebase/auth";

// Comes from firebase console > project settings > general > your apps
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA1lX08gqmkA4DDmos6EchDmix2Mlmp8co",
  authDomain: "mnhs-rma-prod.firebaseapp.com",
  projectId: "mnhs-rma-prod",
  storageBucket: "mnhs-rma-prod.appspot.com",
  messagingSenderId: "454628587546",
  appId: "1:454628587546:web:ebf3377575a3a4edeab9a3",
  measurementId: "G-DFPV3V5QRN",
};
const app = initializeApp(FIREBASE_CONFIG);
// Have to do this so that getAuth within the application will use initialized
// firebase app:
getAuth(app);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
    },
    mutations: { useErrorBoundary: true },
  },
});

axios.interceptors.request.use(
  (req) => {
    if (req.method === "post") {
      console.log(req);
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppProvider i18n={enTranslations}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/rma/*" element={<RMA />} />
              <Route path="/shopify/*" element={<Shopify />} />
              <Route path="*" element={<Shopify />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
