import * as yup from "yup";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import { Labels, stringElementSchema } from "./core";

export const relatedItemSchema = yup.object({
  id: yup.string().required(),
  summaryData: yup.string().required(),
});

export const collectionsMultimediaSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  forSale: yup.string().nullable(),
  thumbnail: yup.string().nullable(),
  filename: yup.string().required(),
  mimetype: yup.string().required(),
  hash: yup.string().required(),
  thumbnailHash: yup.string().nullable(),
  versions: yup.array().of(
    yup.object({
      filename: yup.string().required(),
      mimetype: yup.string().required(),
      hash: yup.string().required(),
      height: yup.number().nullable(),
      width: yup.number().nullable(),
    }),
  ),
});

export type CollectionsMultimedia = yup.InferType<
  typeof collectionsMultimediaSchema
>;

export const coordinateSchema = yup.object({
  lat: yup.number().required(),
  lon: yup.number().required(),
});

export const catalogueItemSchema = yup.object({
  id: yup.string().required(),
  title: yup.string().required(),
  itemType: yup.string().nullable(),
  holdingType: yup.string().required(),
  alternateTitles: yup.array().of(stringElementSchema).required(),
  descriptions: yup.array().of(stringElementSchema).required(),
  historicContext: yup.string().nullable(),
  materials: yup.array().of(stringElementSchema).required(),
  materialColors: yup.array().of(stringElementSchema).required(),
  formats: yup.array().of(stringElementSchema).required(),
  credit: yup.string().nullable(),
  notes: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required(),
  proveniences: yup.array().of(stringElementSchema).required(),
  classifications: yup.array().of(stringElementSchema).required(),
  associations: yup
    .array()
    .of(
      yup.object({
        assocType: yup.string().required(),
        value: yup.string().required(),
        nature: yup.string().required(),
        label: yup.string().nullable(),
      }),
    )
    .required(),
  parties: yup
    .array()
    .of(
      yup.object({
        assocType: yup.string().required(),
        value: yup.object({
          id: yup.string().required(),
          summaryData: yup.string().required(),
          partyType: yup.string().required(),
        }),
        nature: yup.string().required(),
        label: yup.string().nullable(),
      }),
    )
    .required(),
  locations: yup
    .array()
    .of(
      yup.object({
        assocType: yup.string().required(),
        value: yup.object({
          id: yup.string().required(),
          siteName: yup.string().required(),
          summaryData: yup.string().required(),
          locationType: yup.string().required(),
          coordinates: yup.array().of(coordinateSchema).required(),
        }),
        nature: yup.string().required(),
        label: yup.string().nullable(),
      }),
    )
    .required(),
  creationDateStart: yup.date().nullable(),
  creationDateEnd: yup.date().nullable(),
  contentDateStart: yup.date().nullable(),
  contentDateEnd: yup.date().nullable(),
  dateDisplays: yup
    .array()
    .of(
      yup.object({
        display: yup.string().required(),
        dateType: yup.string().required(),
      }),
    )
    .required(),
  identifiers: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .required(),
  measurements: yup.array().of(stringElementSchema).required(),
  nomenclatures: yup.array().of(stringElementSchema).required(),
  quantities: yup.array().of(stringElementSchema).required(),
  parent: relatedItemSchema.nullable(),
  children: yup.array().of(relatedItemSchema).required(),
  restrictions: yup.array().of(stringElementSchema).required(),
  docs: yup.array().of(collectionsMultimediaSchema).required(),
  images: yup.array().of(collectionsMultimediaSchema).required(),
  audios: yup.array().of(collectionsMultimediaSchema).required(),
  videos: yup.array().of(collectionsMultimediaSchema).required(),
  subjects: yup.array().of(stringElementSchema).required(),
  places: yup.array().of(stringElementSchema).required(),
  alternateId: yup.string().required(),
});

export type CatalogueItem = yup.InferType<typeof catalogueItemSchema>;

export const catalogueItemLabels: Labels<CatalogueItem> = {
  id: "ID",
  title: "Title",
  itemType: "Type",
  holdingType: "Holding Type",
  alternateTitles: "Alternate Titles",
  descriptions: "Descriptions",
  historicContext: "Historic Context",
  materials: "Materials",
  materialColors: "Material Colors",
  formats: "Formats",
  credit: "Credit",
  notes: "Notes",
  proveniences: "Proveniences",
  classifications: "Classifications",
  associations: "Associations",
  parties: "Parties",
  locations: "Locations",
  creationDateStart: "Creation Date Start",
  creationDateEnd: "Creation Date End",
  contentDateStart: "Content Date Start",
  contentDateEnd: "Content Date End",
  dateDisplays: "Dates",
  identifiers: "Identifiers",
  measurements: "Measurements",
  nomenclatures: "Nomenclatures",
  quantities: "Quantities",
  parent: "Parent Record",
  children: "Related Records",
  restrictions: "Restrictions",
  docs: "Documents",
  images: "Images",
  videos: "Videos",
  audios: "Audios",
  subjects: "Subjects",
  places: "Places",
  alternateId: "IRN",
};

export const catalogueItemSorts: SearchSortFields<CatalogueItem> = {
  title: { label: catalogueItemLabels["title"] },
};

export const catalogueItemFilters: SearchFilterFields = {
  title: {
    label: catalogueItemLabels["title"],
    type: "string",
  },
  itemType: {
    label: catalogueItemLabels["itemType"],
    type: "string",
    validTerms: [
      "Artifacts",
      "Art",
      "Moving Images",
      "Maps",
      "Newspapers",
      "Oral History",
      "Books Periodicals",
      "Sound Recordings",
      "Photographs",
      "Historical Records",
    ],
  },
  formats: {
    label: catalogueItemLabels["formats"],
    type: "string",
  },
  subjects: {
    label: "Subjects",
    type: "string",
  },
  hasMedia: {
    label: "Has Media",
    type: "boolean",
  },
  hasImage: {
    label: "Has Image(s)",
    type: "boolean",
  },
  hasAudio: {
    label: "Has Audio(s)",
    type: "boolean",
  },
  hasVideo: {
    label: "Has Video(s)",
    type: "boolean",
  },
  hasDoc: {
    label: "Has Document(s)",
    type: "boolean",
  },
  "identifiers.value": {
    label: "Identifiers",
    type: "string",
  },
  alternateId: {
    label: "IRN",
    type: "number",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const catalogueItemFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Creation Date",
    startDayName: "creationStartDay",
    startMonthName: "creationStartMonth",
    startYearName: "creationStartYear",
    endDayName: "creationEndDay",
    endMonthName: "creationEndMonth",
    endYearName: "creationEndYear",
    startDateName: "creationDateStart",
    endDateName: "creationDateEnd",
  },
  {
    groupName: "Content Date",
    startDayName: "contentStartDay",
    startMonthName: "contentStartMonth",
    startYearName: "contentStartYear",
    endDayName: "contentEndDay",
    endMonthName: "contentEndMonth",
    endYearName: "contentEndYear",
    startDateName: "contentDateStart",
    endDateName: "contentDateEnd",
  },
];

export const allowedCollectionsDateFieldRoots = [
  "content",
  "creation",
] as const;

export type AllowedCollectionsDateFieldRoot =
  (typeof allowedCollectionsDateFieldRoots)[number];

export const collectionsSearchConfig = yup.object({
  generalDateConfig: yup
    .array(yup.string().oneOf(allowedCollectionsDateFieldRoots).required())
    .required(),
  purgeUserInput: yup.boolean().required(),
});

export type CollectionsSearchConfig = yup.InferType<
  typeof collectionsSearchConfig
>;

export const collectionsSearchConfigLabels: Labels<CollectionsSearchConfig> = {
  generalDateConfig: "General Date Definition",
  purgeUserInput: "Purge diacritics input by user in queries.",
};
