import { useLocation, useParams } from "react-router-dom";
import { Heading, PText } from "../../shared/TextComponents";
import Card from "../../shared/Card";
import { useCatalogueItemMedia } from "../hooks/catalogueItemHooks";
import { DefaultPageProps } from "../utils/shared";
import { ProgressBar, Spinner } from "@shopify/polaris";
import { useState } from "react";
import BreadcrumbPage from "../BreadcrumbPage";
import Stack from "../../shared/Stack";

export default function CollectionsMediaPage(props: DefaultPageProps) {
  const [progress, setProgress] = useState<number>(0);
  const [starting, setStarting] = useState(true);
  const [elapsedString, setElapsedString] = useState("");

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const filename = new URLSearchParams(search).get("filename");
  const { hash } = useParams();

  const updateProgress = (
    value: number,
    elapsed: string,
    timeRemaining: string
  ) => {
    setProgress(value);
    setStarting(false);
    setElapsedString(`Downloading: ${timeRemaining} remaining...`);
  };

  const { data: media } = useCatalogueItemMedia(
    props.currentUserData.accessToken,
    hash,
    updateProgress
  );

  const player =
    media && media.mimeType === "audio/mpeg" ? (
      <audio controls src={URL.createObjectURL(media.content)} />
    ) : media && media.mimeType === "video/mp4" ? (
      <video src={URL.createObjectURL(media.content)} controls />
    ) : null;

  const progBar = !media && <ProgressBar progress={progress} />;

  const waiting = starting && (
    <Stack direction="row">
      <Spinner size="small" />
      <PText>Starting download...</PText>
    </Stack>
  );

  const durationDisplay = !media && <PText>{elapsedString}</PText>;

  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>
        <Stack>
          <Heading>
            {id}, {filename}
          </Heading>
          {progBar || player}
          {waiting || durationDisplay}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
