import { Button } from "@shopify/polaris";
import { XSmallIcon } from "@shopify/polaris-icons";
import Stack from "../../shared/Stack";

interface SearchParamChitProps {
  displayText: string;
  onRemove: () => void;
  onClick?: () => void;
  disclosure?: "up" | "down";
  disabled?: boolean;
  removeDisabled?: boolean;
}

export default function SearchParamChit({
  displayText,
  onRemove,
  onClick,
  disclosure,
  disabled,
  removeDisabled,
}: SearchParamChitProps) {
  return (
    <Stack direction="row" spacing={1}>
      <Button
        size="slim"
        disclosure={disclosure}
        onClick={onClick}
        disabled={disabled}
      >
        {displayText}
      </Button>
      <Button
        icon={XSmallIcon}
        size="slim"
        onClick={onRemove}
        disabled={removeDisabled}
        tone="critical"
      />
    </Stack>
  );
}
