import { Button, ChoiceList, Popover, Select } from "@shopify/polaris";
import React, { useState } from "react";

import * as utils from "../utils/shared";
import Stack from "../../shared/Stack";
import { PText } from "../../shared/TextComponents";
import { Labels } from "../schemas/core";
import { AddSearchParamBtn, ContentSensitiveClearBtn } from "./components";
import SearchParamChit from "./SearchParamChit";
import * as searchutils from "./searchutils";

interface SortParamPopoverProps<T> extends searchutils.searchParamPopoverProps {
  sortFields: searchutils.SearchSortFields<T>;
  onSubmit: (field: keyof T, order: searchutils.SearchSortOrder) => void;
}

export function SortParamPopover<T>(props: SortParamPopoverProps<T>) {
  const [selectedField, setSelectedField] = useState<string>("");
  const [sortOrder, setSortOrder] =
    useState<searchutils.SearchSortOrder>("desc");

  const close = () => {
    setSelectedField("");
    setSortOrder("desc");
    props.onClose();
  };

  const submit = () => {
    props.onSubmit(selectedField as keyof T, sortOrder);
    close();
  };

  const selector = (
    <Select
      label="Available sort fields"
      placeholder="select field"
      value={selectedField}
      options={Object.entries(props.sortFields).map(([k, v]) => {
        // Object.entries().map causes strict typing to lose track of the typing
        // on the object passed to it, so we have to cast v here:
        return { key: k, label: (v as { label: string }).label, value: k };
      })}
      onChange={(selected) => setSelectedField(selected)}
    />
  );

  const choices = (
    <ChoiceList
      title="Order"
      choices={[
        { label: "Descending", value: "desc" },
        { label: "Ascending", value: "asc" },
      ]}
      selected={[sortOrder]}
      onChange={(selected) =>
        setSortOrder(selected[0] as searchutils.SearchSortOrder)
      }
    />
  );

  return (
    <>
      <Popover
        active={props.active}
        activator={props.activator}
        onClose={close}
        preferredAlignment="left"
        fullHeight
      >
        <Popover.Section>
          <Stack>
            {selector}
            {choices}
          </Stack>
        </Popover.Section>
        <Popover.Section>
          <Button onClick={submit}>Add</Button>
        </Popover.Section>
      </Popover>
    </>
  );
}

interface SortParamDisplayProps<T> {
  sorts: searchutils.SearchSortParams<T>;
  setSorts: React.Dispatch<
    React.SetStateAction<searchutils.SearchSortParams<T>>
  >;
  sortFields: searchutils.SearchSortFields<T>;
  labels: Labels<T>;
  heading?: string;
}

export default function SortParamDisplay<T>({
  sorts,
  setSorts,
  sortFields,
  labels,
  heading,
}: SortParamDisplayProps<T>) {
  const [popoverActive, setPopoverActive] = useState(false);

  const toggleSortPopover = () => setPopoverActive((prev) => !prev);

  const addSortBtn = <AddSearchParamBtn onClick={toggleSortPopover} />;

  const sortPopover = (
    <SortParamPopover
      activator={addSortBtn}
      active={popoverActive}
      onClose={toggleSortPopover}
      sortFields={sortFields}
      onSubmit={(key, order) =>
        setSorts((prev) => ({
          ...prev,
          [key]: { field: key, direction: order },
        }))
      }
    />
  );

  return (
    <Stack direction="row" align="center">
      <PText weight="bold">{heading || "Sorting"}</PText>
      {Object.entries(sorts).map(([key, sortOrder]) => {
        // Have to remind type checker that this is still a SearchSort, passing
        // it through Object.entries causes it to forget:
        let sOrder = sortOrder as searchutils.SearchSort;
        return (
          <SearchParamChit
            key={key}
            displayText={`${labels[key as keyof T]}`}
            onRemove={() => {
              const result = utils.pop(key, sorts);
              setSorts(result[1]);
            }}
            onClick={() => {
              const newOrder =
                sorts[key as keyof T]?.direction === "asc" ? "desc" : "asc";
              setSorts((previousSorts) => ({
                ...previousSorts,
                [key]: { field: key, direction: newOrder },
              }));
            }}
            disclosure={sOrder.direction === "asc" ? "up" : "down"}
          />
        );
      })}
      {sortPopover}
      <ContentSensitiveClearBtn content={sorts} onClick={() => setSorts({})} />
    </Stack>
  );
}
