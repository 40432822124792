import { Layout, Page, Spinner } from "@shopify/polaris";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Card from "../../shared/Card";
import BulkEditGrid, { BulkEditPageProps } from "../BulkEditGrid";
import { searchRecords } from "../api/genericEndpoints";
import { useBulkUpdateBirthRecords } from "../hooks/birthRecordHooks";
import {
  BirthRecord,
  SERIESCODE,
  birthRecordLabels,
  birthRecordSchema,
} from "../schemas/birthRecord";
import { CONFIDENTIALITY } from "../schemas/core";

export function BirthRecordBulkEditPage(props: BulkEditPageProps<BirthRecord>) {
  const navigate = useNavigate();

  const { data: results } = useQuery(
    ["birthRecord"],
    async () =>
      searchRecords<BirthRecord>(
        {
          accessToken: props.currentUserData.accessToken,
          ...props.searchState.genSearchQuery(),
          pageNum: 1,
          pageSize: 100,
        },
        "birth-records"
      ),
    { keepPreviousData: true }
  );

  const grid = results && (
    <BulkEditGrid
      currentUserData={props.currentUserData}
      records={results.hits}
      validationSchema={birthRecordSchema}
      columns={[
        {
          field: "seriesCode",
          type: "select",
          selectConfig: { options: SERIESCODE },
        },
        { field: "certificateNumber", type: "text", overrideDisabled: true },
        "firstName",
        "middleName",
        { field: "familyName", type: "text", required: true },
        "suffix",
        "mothersMaidenName",
        {
          field: "confidentiality",
          type: "select",
          required: true,
          selectConfig: {
            options: CONFIDENTIALITY,
            defaultValue: "confidential",
          },
        },
        { field: "birthDateStart", type: "date", required: true },
        { field: "birthDateEnd", type: "date" },
        { field: "county", type: "county" },
      ]}
      labels={{
        ...birthRecordLabels,
        birthDateStart: "Birth Date Start",
        birthDateEnd: "Birth Date End",
      }}
      bulkEditHook={useBulkUpdateBirthRecords}
      onBulkEditSuccess={() => navigate("../dash?tab=history")}
    />
  );

  const spinner = !results && <Spinner />;

  return (
    <Page>
      <Layout>
        <Card>{grid || spinner}</Card>
      </Layout>
    </Page>
  );
}
