import * as yup from "yup";
import residences from "../reference/residences";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";
import { Labels, eventSchema, stringElementSchema } from "./core";

export const goldStarSchema = yup.object({
  id: yup.string().required(),
  deathDateStart: yup.date().required(),
  familyName: yup.string().required("Family Name is a required field"),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  suffix: yup.string().nullable(),
  relatedName: yup.string().nullable(),
  deathDateEnd: yup.date().nullable(),
  birthPlace: yup.string().nullable(),
  birthDate: yup.string().nullable(),
  notes: yup.string().nullable(),
  residencePriorToService: yup.string().nullable(),
  pdfSHA256: yup.string().nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema),
  certificateId: yup.number().nullable(),
});

export type GoldStarRecord = yup.InferType<typeof goldStarSchema>;

export const goldStarInputSchema = goldStarSchema.shape({
  id: yup.string().nullable(),
});

export type GoldStarRecordInput = yup.InferType<typeof goldStarInputSchema>;

export const goldStarEventSchema = eventSchema.shape({
  state: goldStarSchema,
});

export const goldStarEventSchemaList = yup
  .array()
  .of(goldStarEventSchema)
  .required();

export const goldStarLabels: Labels<GoldStarRecord> = {
  id: "UUID",
  deathDateStart: "Exact Death Date or Range Start",
  familyName: "Family Name",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  suffix: "Suffix",
  relatedName: "Related Name",
  deathDateEnd: "Death Date Range End",
  birthPlace: "Birth Place",
  birthDate: "Birth Date",
  notes: "Notes",
  residencePriorToService: "Residence Prior to Service",
  pdfSHA256: "PDF SHA256",
  alternateNames: "Alternate Names",
  certificateId: "Certificate Number",
};

export const goldStarSorts: SearchSortFields<GoldStarRecord> = {
  prefix: { label: goldStarLabels["prefix"] },
  firstName: { label: goldStarLabels["firstName"] },
  middleName: { label: goldStarLabels["middleName"] },
  familyName: { label: goldStarLabels["familyName"] },
  suffix: { label: goldStarLabels["suffix"] },
  relatedName: { label: goldStarLabels["relatedName"] },
  deathDateStart: { label: "Death Date Start" },
  deathDateEnd: { label: "Death Date End" },
  residencePriorToService: {
    label: goldStarLabels["residencePriorToService"],
  },
};

export const goldStarFilters: SearchFilterFields = {
  firstName: {
    label: goldStarLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: goldStarLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: goldStarLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: goldStarLabels["suffix"],
    type: "string",
  },
  relatedName: {
    label: goldStarLabels["relatedName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  deathDateIsInexact: {
    label: "Death Date is Inexact",
    type: "boolean",
  },
  residencePriorToService: {
    label: goldStarLabels["residencePriorToService"],
    type: "string",
    validTerms: residences,
  },
  alternateNames: {
    label: goldStarLabels["alternateNames"],
    type: "string",
  },
  certificateId: {
    label: goldStarLabels["certificateId"],
    type: "number",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const goldStarFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Death Date",
    startDayName: "deathStartDay",
    startMonthName: "deathStartMonth",
    startYearName: "deathStartYear",
    endDayName: "deathEndDay",
    endMonthName: "deathEndMonth",
    endYearName: "deathEndYear",
    startDateName: "deathDateStart",
    endDateName: "deathDateEnd",
  },
];
