import {
  Divider,
  SkeletonBodyText,
  SkeletonDisplayText,
} from "@shopify/polaris";
import Stack from "../shared/Stack";

export default function Skeleton({
  lines,
  title,
}: {
  lines: number;
  title?: boolean;
}) {
  return (
    <Stack>
      {title ? <SkeletonDisplayText /> : null}
      <Divider />
      <SkeletonBodyText lines={lines} />
    </Stack>
  );
}
