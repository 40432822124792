import {
  Avatar,
  AvatarProps,
  Icon,
  Pagination,
  ResourceItem,
  ResourceList,
  ThumbnailProps,
} from "@shopify/polaris";

import { SearchResult } from "../schemas/core";
import Stack from "../../shared/Stack";
import { ReactElement, ReactNode } from "react";
import { ImageIcon } from "@shopify/polaris-icons";

export interface SearchHitRenderSuite<T> {
  render: (record: T) => ReactNode;
  hitAltActions?: (record: T) => { label: string; onClick: () => void }[];
  hitRenderMedia?:
    | ((record: T) => ReactElement<ThumbnailProps | AvatarProps>)
    | "person"
    | "image";
  accessibilityLabel?: (record: T) => string;
}

interface SearchResultProps<T extends { id: string }> {
  result: SearchResult<T> | null | undefined;
  isLoading: boolean;
  hitOnClick: (record: T) => void;
  renderHit: ((record: T) => ReactNode) | SearchHitRenderSuite<T>;
  onPrevious?: () => void;
  onNext?: () => void;
  names?: { singular: string; plural: string };
}

export function SearchResults<T extends { id: string }>({
  result,
  isLoading,
  onPrevious,
  onNext,
  hitOnClick,
  renderHit,
  names,
}: SearchResultProps<T>) {
  const pagination = result && result.hits.length > 0 && (
    <Pagination
      hasPrevious={result.hasPrevious}
      hasNext={result.hasNext}
      onPrevious={onPrevious}
      onNext={onNext}
      label={`Page ${result.pageNum} / ${Math.ceil(
        result.totalHitCount / result.pageSize
      )}`}
    />
  );

  const renderItem = (record: T) => {
    const renderingSuite =
      typeof renderHit === "function"
        ? {
            render: renderHit,
            hitAltActions: undefined,
            hitRenderMedia: undefined,
            accessibilityLabel: undefined,
          }
        : renderHit;

    const { render, hitAltActions, hitRenderMedia, accessibilityLabel } =
      renderingSuite;

    const altActions = hitAltActions !== undefined ? hitAltActions(record) : [];

    const renderMedia =
      hitRenderMedia === "person"
        ? (record: T) => (
            <Avatar
              customer
              size="md"
              name={`Placeholder image for ${record.id}`}
            />
          )
        : hitRenderMedia === "image"
        ? (record: T) => <Icon source={ImageIcon} />
        : hitRenderMedia;

    return (
      <ResourceItem
        id={record.id}
        onClick={() => hitOnClick(record)}
        media={renderMedia ? renderMedia(record) : undefined}
        accessibilityLabel={
          accessibilityLabel
            ? accessibilityLabel(record)
            : `View details for ${record.id}`
        }
        shortcutActions={altActions.map((action) => ({
          content: action.label,
          onAction: action.onClick,
        }))}
      >
        {render(record)}
      </ResourceItem>
    );
  };

  const resourceNames = names
    ? names
    : { singular: "record", plural: "records" };

  return (
    <Stack spacing={1}>
      {onPrevious && onNext && pagination}
      <ResourceList
        showHeader
        resourceName={resourceNames}
        items={result?.hits || []}
        totalItemsCount={result?.totalHitCount}
        renderItem={(record) => renderItem(record)}
        loading={isLoading}
        emptyState={
          result ? "No results" : `Search for a ${resourceNames["singular"]}`
        }
      />
      {onPrevious && onNext && pagination}
    </Stack>
  );
}
