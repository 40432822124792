import { Button, DescriptionList, Divider } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import Stack from "../../shared/Stack";
import { Subheading } from "../../shared/TextComponents";
import { DetailFieldsSpec } from "../Detail";
import { Event, Labels } from "../schemas/core";
import { renderValueAsString } from "../utils/shared";

interface GoToRecordButtonProps {
  event: Event<any>;
  onClick?: () => void;
}

export function GoToRecordButton(props: GoToRecordButtonProps) {
  return (
    <Button
      variant="plain"
      onClick={() => (props.onClick ? props.onClick() : {})}
      disabled={props.onClick === undefined}
      textAlign="left"
    >
      {`v${props.event.originatorVersion} - ${
        props.event.originatingUser.name
      } - ${props.event.timestamp.toLocaleString()}`}
    </Button>
  );
}
interface JournalDomainEventProps<T extends Record<string, any>> {
  event: Event<T>;
  previousState: T | null;
  labels: Labels<T>;
  spec?: DetailFieldsSpec<T>;
  goToRecord?: () => void;
}

export function JournalDomainEvent<T extends Record<string, any>>(
  props: JournalDomainEventProps<T>
) {
  const compare = (obj1: any, obj2: any): boolean => {
    if (!obj1 && !obj2) {
      return true;
    } else if (obj1 instanceof Date && obj2 instanceof Date) {
      return obj1.toISOString() === obj2.toISOString();
    } else if (obj1 instanceof Array && obj2 instanceof Array) {
      return obj1.every((value, idx) => compare(obj2[idx], value));
    } else if (
      obj1 &&
      obj2 &&
      typeof obj1 === "object" &&
      typeof obj2 === "object"
    ) {
      return Object.entries(obj1).every(([k, v]) => obj2[k] === v);
    } else {
      return obj1 === obj2;
    }
  };

  const genDiffs = (state1: T | null, state2: T): { [P in keyof T]?: T[P] } => {
    return Object.entries(state2).reduce((result, [k, v]) => {
      const dtype =
        props.spec && props.spec[k] ? props.spec[k]?.dateType : "datetime";

      const diff = state1
        ? !compare(state1[k], state2[k])
          ? `${renderValueAsString(state1[k], dtype) || "blank"} > ${
              renderValueAsString(v, dtype) || "blank"
            }`
          : null
        : renderValueAsString(v, dtype);
      const step = diff ? { ...result, [k]: diff } : { ...result };
      return step;
    }, {});
  };

  return (
    <Stack>
      <GoToRecordButton event={props.event} onClick={props.goToRecord} />
      <Subheading>
        {props.previousState ? "Changes" : "Creation Values"}
      </Subheading>
      <DescriptionList
        items={Object.entries(
          genDiffs(props.previousState, props.event.state)
        ).map(([k, v]) => ({ term: props.labels[k], description: v }))}
      />
    </Stack>
  );
}

interface JournalDomainEventDisplayProps<T extends Record<string, any>> {
  events: Event<T>[];
  labels: Labels<T>;
  spec?: DetailFieldsSpec<T>;
  rootLink?: string;
}

export function JournalDomainEventDisplay<T extends Record<string, any>>(
  props: JournalDomainEventDisplayProps<T>
) {
  const navigate = useNavigate();

  return (
    <Stack>
      {props.events.map((evt, idx) => {
        const prevState = idx > 0 ? props.events[idx - 1].state : null;
        return (
          <>
            <Divider />
            <JournalDomainEvent
              event={evt}
              labels={props.labels}
              previousState={prevState}
              spec={props.spec}
              goToRecord={
                props.rootLink
                  ? () => navigate(`${props.rootLink}${evt.originatorID}`)
                  : undefined
              }
            />
          </>
        );
      })}
    </Stack>
  );
}
