import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import {
  wwiSolPhotoFilters,
  wwiSolPhotoLabels,
  WWISolPhotoRecord,
  wwiSolPhotoSorts,
} from "../schemas/wwiSolPhoto";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { getVitalRecordsDisplayName } from "./shared";
import WWISolPhotoDetail from "./WWISolPhotoDetail";

export function renderWWISolPhotoHit(record: WWISolPhotoRecord) {
  const displayName = getVitalRecordsDisplayName(record);

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>{record.residence}</PText>
    </>
  );
}

export default function WWISolPhotoSearchPage(
  props: BaseSearchPageComponentProps<WWISolPhotoRecord>,
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={wwiSolPhotoLabels}
          renderSearchHit={{
            render: renderWWISolPhotoHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            WWISolPhotoDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          sortFields={wwiSolPhotoSorts}
          filterFields={wwiSolPhotoFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<WWISolPhotoRecord>(props, "wwi-soldiers-photos")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "wwi-soldiers-photos")
          }
          recordName="WWI Soldiers Photo"
          contextualPrefs={props.contextualPrefs}
        />
      </Layout>
    </Page>
  );
}
