import { yupResolver } from "@hookform/resolvers/yup";
import { ChoiceList, Divider, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Heading, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { DetailSection } from "../Detail";
import { FormSection, FormFields } from "../Form";
import { usePermissions } from "../hooks/permissionHooks";
import {
  useRole,
  useRoles,
  useSetRolePermissions,
  useSetSubRoles,
  useUpdateRole,
} from "../hooks/roleHooks";
import {
  CurrentUserData,
  Role,
  RoleInput,
  roleInputSchema,
  roleLabels,
  SYSADMIN_ROLE_ID,
} from "../schemas/core";
import Skeleton from "../Skeleton";
import { removeNulls } from "../utils/shared";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";

interface RoleFormProps {
  currentUserData: CurrentUserData;
  onSubmitSuccess: (result?: string) => void;
  role?: Role;
}

export function RoleForm(props: RoleFormProps) {
  const role = props.role;
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<RoleInput>({
    resolver: yupResolver(roleInputSchema),
    defaultValues: roleInputSchema.cast(role),
  });

  return (
    <FormSection
      currentUserData={props.currentUserData}
      inputSchema={roleInputSchema}
      initialValues={role}
      handleSubmit={handleSubmit}
      mutateHook={useUpdateRole}
      onMutateSuccess={props.onSubmitSuccess}
    >
      <FormFields
        control={control}
        spec={{ name: { type: "text", required: true } }}
        labels={roleLabels}
        errors={errors}
        reset={reset}
        getValues={getValues}
      />
    </FormSection>
  );
}

interface RoleFormPageProps {
  currentUserData: CurrentUserData;
}

export default function RoleFormPage(props: RoleFormPageProps) {
  const { uuid } = useParams();

  const [selectedPerms, setSelectedPerms] = useState<(string | undefined)[]>(
    []
  );
  const [permsLoading, setPermsLoading] = useState(false);
  const [selectedSubRoles, setSelectedSubRoles] = useState<
    (string | undefined)[]
  >([]);
  const [subRolesLoading, setSubRolesLoading] = useState(false);

  const { data: role } = useRole(props.currentUserData.accessToken, uuid);
  const { data: permissions } = usePermissions(
    props.currentUserData.accessToken
  );
  const { data: roles } = useRoles(props.currentUserData.accessToken);

  useEffect(() => {
    if (role) {
      setSelectedPerms(role.permissionIds);
      setSelectedSubRoles(role.subRoleIds);
    }
  }, [role, setSelectedPerms, setSelectedSubRoles]);

  const skeleton = !role && <Skeleton lines={16} />;

  const permsMutation = useSetRolePermissions();
  const subRolesMutation = useSetSubRoles();

  const updatePermissions = async (
    roleUUID: string,
    permissionIDs: string[]
  ) => {
    const prevPerms = [...selectedPerms];
    setSelectedPerms(permissionIDs);
    setPermsLoading(true);

    await permsMutation.mutateAsync({
      accessToken: props.currentUserData.accessToken,
      roleUUID: roleUUID,
      permissionIDs: permissionIDs,
    });

    if (permsMutation.isError) {
      setSelectedPerms(prevPerms);
    }

    setPermsLoading(false);
  };

  const updateSubRoles = async (roleUUID: string, subRoleUUIDs: string[]) => {
    if (role) {
      const prevSubRoles = [...selectedSubRoles];
      setSelectedSubRoles(subRoleUUIDs);
      setSubRolesLoading(true);

      await subRolesMutation.mutateAsync({
        accessToken: props.currentUserData.accessToken,
        roleUUID: roleUUID,
        subRoleUUIDs: subRoleUUIDs,
      });

      if (subRolesMutation.isError) {
        setSelectedSubRoles(prevSubRoles);
      }

      setSubRolesLoading(false);
    }
  };

  const perms = permissions && role && (
    <ChoiceList
      allowMultiple
      title={<Subheading>{roleLabels["permissionIds"]}</Subheading>}
      choices={permissions.map((p) => ({ label: p.name, value: p.id }))}
      selected={removeNulls(
        role
          ? permissions.map((p) => (selectedPerms.includes(p.id) ? p.id : null))
          : []
      )}
      onChange={(permissionIDs) => updatePermissions(role.id, permissionIDs)}
      disabled={permsLoading || role.id === SYSADMIN_ROLE_ID}
    />
  );

  const subroles = roles && role && (
    <ChoiceList
      allowMultiple
      title={<Subheading>{roleLabels["subRoleIds"]}</Subheading>}
      choices={roles.map((r) => ({
        label: r.name,
        value: r.id,
        disabled: r.id === SYSADMIN_ROLE_ID,
      }))}
      selected={removeNulls(
        roles.map((r) => (role.subRoleIds.includes(r.id) ? r.id : null))
      )}
      onChange={(subRoleUUIDs) => updateSubRoles(role.id, subRoleUUIDs)}
      disabled={subRolesLoading || role.id === SYSADMIN_ROLE_ID}
    />
  );

  const form = role && (
    <Stack spacing={1}>
      <Heading>{role.name}</Heading>
      <Subheading>{role.id}</Subheading>
      {role.id === SYSADMIN_ROLE_ID ? null : (
        <RoleForm
          currentUserData={props.currentUserData}
          role={role}
          onSubmitSuccess={() => {}}
        />
      )}
    </Stack>
  );

  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>
        <Stack>
          <DetailSection>{skeleton || form}</DetailSection>
          {perms}
          {permsLoading ? <Spinner size="small" /> : null}
          <Divider />
          {subroles}
          {subRolesLoading ? <Spinner size="small" /> : null}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}

interface NewRoleFormPageProps {
  currentUserData: CurrentUserData;
}

export function NewRoleFormPage(props: NewRoleFormPageProps) {
  const navigate = useNavigate();
  return (
    <BreadcrumbPage breadcrumbAction="back">
      <Card>
        <Stack spacing={4}>
          <Heading>New Role</Heading>
          <RoleForm
            currentUserData={props.currentUserData}
            onSubmitSuccess={(result?: string) =>
              result ? navigate(`../${result}`) : {}
            }
          />
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
