import {
  Banner,
  Button,
  Divider,
  ResourceItem,
  ResourceList,
  Spinner,
  TextField,
} from "@shopify/polaris";
import { ReactNode, useState } from "react";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  useBlockEmail,
  useQueryCmtBlockedEmails,
  useUnblockEmail,
} from "../hooks/commentHooks";
import {
  CommentBlockedEmail,
  commentBlockedEmailFilters,
  commentBlockedEmailLabels,
  commentBlockedEmailSorts,
} from "../schemas/comment";
import { CurrentUserData } from "../schemas/core";
import Prompt from "../utils/Prompt";
import { DefaultPageProps, renderValueAsString } from "../utils/shared";
import { FilterParamLike, SearchSortParams } from "../search/searchutils";
import Pagination from "../search/Pagination";
import SortParamDisplay from "../search/SortParamDisplay";
import FilterParamDisplay from "../search/FilterParamDisplay";

interface CommentBlockedEmailDetailProps {
  blockedEmail: CommentBlockedEmail;
  onUnblock?: (id: string) => {};
  unBlockLoading?: boolean;
}

export default function CommentBlockedEmailDetail({
  blockedEmail,
  onUnblock,
  unBlockLoading,
}: CommentBlockedEmailDetailProps) {
  return (
    <Stack spacing={0}>
      <PText>
        <strong>Blocked by</strong> {blockedEmail.blockedBy.name}{" "}
        <strong>on</strong> {renderValueAsString(blockedEmail.blockDt)}
      </PText>
      <PText>
        <strong>Email:</strong> {blockedEmail.email}
      </PText>
      <PText>
        <strong>Reason:</strong> {blockedEmail.reason}
      </PText>
      <Stack direction="row" justify="flex-end" align="center">
        {unBlockLoading && <Spinner size="small" />}
        {onUnblock && (
          <Button
            onClick={() => onUnblock(blockedEmail.id)}
            disabled={unBlockLoading}
          >
            Unblock
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

interface CommentBlockEmailModalProps {
  currentUserData: CurrentUserData;
  blockPromptActive: boolean;
  setBlockPromptActive: (active: boolean) => void;
  email?: string;
  setEmail: (value?: string) => void;
}

export function CommentBlockEmailModal({
  currentUserData,
  blockPromptActive,
  setBlockPromptActive,
  email,
  setEmail,
}: CommentBlockEmailModalProps) {
  const [blockConfirmActive, setBlockConfirmActive] = useState(false);
  const [alreadyBlocked, setAlreadyBlocked] = useState(false);
  const [reason, setReason] = useState<string | undefined>();

  const mutation = useBlockEmail();

  const resetState = () => {
    setEmail(undefined);
    setAlreadyBlocked(false);
    setReason(undefined);
  };

  const closePromptModal = () => {
    setBlockPromptActive(false);
    resetState();
  };

  const closeConfirmModal = () => {
    setBlockConfirmActive(false);
    resetState();
  };

  const blockPromptModal = email && (
    <Prompt
      active={blockPromptActive}
      title="Confirm Block"
      onClose={closePromptModal}
      primaryAction={{
        content: "Block",
        destructive: true,
        disabled: alreadyBlocked,
        onAction: async () => {
          const result = await mutation.mutateAsync({
            accessToken: currentUserData.accessToken,
            inputData: {
              email: email,
              blockedBy: currentUserData.user.id,
              reason: reason,
            },
          });
          if (result === "This email has already been blocked.") {
            setAlreadyBlocked(true);
          } else {
            setBlockPromptActive(false);
            setBlockConfirmActive(true);
          }
        },
        loading: mutation.isLoading,
      }}
    >
      <Stack>
        {alreadyBlocked && (
          <Banner tone="warning">{email} has already been blocked.</Banner>
        )}
        <PText>
          Really block email {email}? They will no longer be able to add
          comments to <strong>any</strong> records.
        </PText>
        <TextField
          autoComplete="off"
          label="Reason"
          value={reason}
          onChange={(input) => setReason(input)}
          clearButton
          onClearButtonClick={() => setReason(undefined)}
        />
      </Stack>
    </Prompt>
  );

  const blockConfirmModal = email && (
    <Prompt
      active={blockConfirmActive}
      title="Block Complete"
      onClose={closeConfirmModal}
      primaryAction={{
        content: "OK",
        onAction: closeConfirmModal,
      }}
    >
      <PText>{email} has been blocked from future comments.</PText>
    </Prompt>
  );

  return (
    <>
      {blockConfirmModal}
      {blockPromptModal}
    </>
  );
}

export function CommentBlockedEmailsPage(props: DefaultPageProps) {
  const [page, setPage] = useState(1);
  const [sorts, setSorts] = useState<SearchSortParams<CommentBlockedEmail>>({
    blockDt: { field: "blockDt", direction: "desc" },
  });
  const [filters, setFilters] = useState<FilterParamLike[]>([]);

  const { data: blockedEmails, isRefetching } = useQueryCmtBlockedEmails(
    props.currentUserData.accessToken,
    page,
    50,
    Object.values(sorts),
    filters,
  );

  const unblockMutation = useUnblockEmail();

  const pagination = (
    <Pagination
      results={blockedEmails}
      setPage={setPage}
      queryRefetching={isRefetching}
      alwaysVisible
    />
  );

  const sortDisplay = (
    <SortParamDisplay
      sorts={sorts}
      setSorts={setSorts}
      sortFields={commentBlockedEmailSorts}
      labels={commentBlockedEmailLabels}
    />
  );

  const filterDisplay = (
    <FilterParamDisplay
      filters={filters}
      setFilters={setFilters}
      filterFields={commentBlockedEmailFilters}
      disallowEdit
      disallowConjuncts
    />
  );

  const renderItem = (item: CommentBlockedEmail): ReactNode => (
    <ResourceItem
      id={item.id}
      accessibilityLabel={`Detail for blocked email ${item.email}`}
      // ResourceItem onClick doesn't play nice with the detail buttons:
      onClick={() => {}}
    >
      <CommentBlockedEmailDetail
        blockedEmail={item}
        unBlockLoading={unblockMutation.isLoading}
        onUnblock={async (uuid: string) => {
          await unblockMutation.mutateAsync({
            accessToken: props.currentUserData.accessToken,
            uuid,
          });
        }}
      />
    </ResourceItem>
  );

  const feed = blockedEmails && (
    <ResourceList
      resourceName={{ singular: "blocked email", plural: "blocked emails" }}
      items={blockedEmails.results}
      renderItem={renderItem}
      emptyState={
        blockedEmails.totalCount === 0 && "No emails currently blocked"
      }
    />
  );

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Card>
        <Stack>
          <Heading>Blocked Emails</Heading>
          <PText>
            The emails listed below are blocked from commenting. Any attempts to
            make a comment on any record using these emails will be refused.
          </PText>
          <Divider />
          {sortDisplay}
          <Divider />
          {filterDisplay}
          <Divider />
          {pagination}
          {feed}
          {pagination}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
