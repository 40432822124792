import { Provider } from "@shopify/app-bridge-react";
import {
  API_URL,
  SHOPIFY_API_KEY,
  SHOPIFY_API_OAUTH_SCOPE_PATH,
  SHOPIFY_STORE_LOGIN_URL,
} from "../env";
import App from "./App";
import AppSkeleton from "./Skeleton";

export default function AppWrapper() {
  const searchParams = new URLSearchParams(window.location.search);

  // At present, the `host` URL param needed to initialize App Bridge is the
  // base64-encoded shop URL, but Shopify docs don't actually say that. It's
  // probably a bad idea to count on it staying a base64-encoded shop URL.
  const host = searchParams.get("host");
  const shop = searchParams.get("shop");
  if (host) {
    return (
      <Provider
        config={{
          apiKey: SHOPIFY_API_KEY,
          host: host,
          forceRedirect: true,
        }}
      >
        <App
          apiUrl={API_URL}
          shopifyApiOAuthScopePath={SHOPIFY_API_OAUTH_SCOPE_PATH}
          shop={shop}
        />
      </Provider>
    );
  }

  // If we're missing a host parameter but we have a `shop` parameter, we can
  // redirect them to the API, which will send them through the oauth flow for
  // the given shop.
  if (shop) {
    // FIXME
    // Relies on the browsers ability to get url params:
    window.location.replace(API_URL + "/shopify" + window.location.search);
  }

  // If all else fails, bounce them to the Shopify login.
  window.location.replace(SHOPIFY_STORE_LOGIN_URL);

  return <AppSkeleton />;
}
