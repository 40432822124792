import * as yup from "yup";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";
import { CensusCounties } from "./census";
import { Labels, stringElementSchema } from "./core";

export const censusPageSchema = yup.object({
  id: yup.string().required(),
  SHA256Hash: yup.string().required(),
  reel: yup.string().required("Reel is a required field."),
  page: yup.number().required("Page number is a required field."),
  year: yup.number().required("Census Year is a required field."),
  counties: yup.array().of(stringElementSchema).required(),
  legacyId: yup.string().nullable(),
});

export type CensusPage = yup.InferType<typeof censusPageSchema>;

export const censusPageInputSchema = censusPageSchema.shape({
  id: yup.string().nullable(),
  SHA256Hash: yup.string().nullable(),
});

export type CensusPageInput = yup.InferType<typeof censusPageInputSchema>;

export const nearbyPagesSchema = yup.object({
  prev: censusPageSchema.nullable(),
  next: censusPageSchema.nullable(),
});

export type NearbyPages = yup.InferType<typeof nearbyPagesSchema>;

export const censusPageLabels: Labels<CensusPage> = {
  id: "UUID",
  SHA256Hash: "Image Hash",
  reel: "Reel",
  page: "Page Number",
  year: "Census Year",
  counties: "Counties",
  legacyId: "Legacy Census Page ID",
};

export const censusPageSorts: SearchSortFields<CensusPage> = {
  reel: { label: censusPageLabels["reel"] },
  page: { label: censusPageLabels["page"] },
};

export const censusPageFilters: SearchFilterFields = {
  reel: {
    label: censusPageLabels["reel"],
    type: "string",
  },
  page: {
    label: censusPageLabels["page"],
    type: "number",
  },
  imageId: {
    label: "Full Image ID",
    type: "string",
  },
  counties: {
    label: censusPageLabels["counties"],
    type: "string",
    validTerms: [...CensusCounties],
  },
  year: {
    label: censusPageLabels["year"],
    type: "number",
  },
  countyCt: {
    label: "Number of Counties",
    type: "number",
  },
};
