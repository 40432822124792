import { Button, DescriptionList, Divider } from "@shopify/polaris";

import { CurrentUserData, Event, Labels, StringElement } from "./schemas/core";
import { renderValueAsString } from "./utils/shared";
import { PText, Subheading } from "../shared/TextComponents";
import { v4 as uuid } from "uuid";
import React from "react";
import Stack from "../shared/Stack";

interface DetailSectionProps {
  onEditBtnClick?: () => void;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export function DetailSection(props: DetailSectionProps) {
  const editBtnClick = props.onEditBtnClick;

  const editBtn = editBtnClick && (
    <Button onClick={() => editBtnClick()}>Edit</Button>
  );

  const actionSection = (editBtnClick || props.actions) && (
    <Stack direction="row" align="flex-start">
      {editBtn}
      {props.actions}
    </Stack>
  );

  return (
    <Stack spacing={4}>
      {props.children}
      <Divider />
      {actionSection}
    </Stack>
  );
}

export type DetailFieldsSpec<T> = {
  [P in keyof T]?: { dateType: "date" | "datetime" } | null;
};

interface DetailFieldsProps<T> {
  record: T;
  spec: DetailFieldsSpec<T>;
  labels: Labels<T>;
  children?: any[] | any;
}

export function DetailFields<T extends Record<string, any>>(
  props: DetailFieldsProps<T>
) {
  return (
    <Stack>
      <DescriptionList
        items={Object.entries(props.spec).map(([k, v]) => ({
          term: props.labels[k as keyof T],
          description: renderValueAsString(
            props.record[k as keyof T],
            v ? v.dateType : "datetime"
          ),
        }))}
      />
      {props.children}
    </Stack>
  );
}

interface ListFieldStackProps<T extends object> {
  label: string;
  elements: T[];
  noDivider?: boolean;
}

interface ListObjectFieldStackProps<T extends object>
  extends ListFieldStackProps<T> {
  renderMethod: (obj: T, idx: number) => JSX.Element;
}

export function ListObjectFieldStack<T extends object>(
  props: ListObjectFieldStackProps<T>
) {
  return (
    <Stack>
      <Subheading>{props.label}</Subheading>
      <Stack>
        {props.elements.map((v, idx) => (
          <Stack key={uuid()}>
            {props.renderMethod(v, idx)}
            {!props.noDivider ? <Divider /> : null}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export function ListFieldStack(props: ListFieldStackProps<StringElement>) {
  return (
    <ListObjectFieldStack
      label={props.label}
      elements={props.elements}
      renderMethod={(v) => <PText>{v.value}</PText>}
      noDivider={props.noDivider}
    />
  );
}

export interface BaseDetailCompProps<T extends object> {
  currentUserData: CurrentUserData;
  record?: T | null;
  onEditBtnClick: () => void;
  eventHistory?: Event<T>[] | null;
}
