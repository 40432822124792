import * as yup from "yup";
import { eventSchema, Labels, stringElementSchema } from "./core";
import { coordinateSchema } from "./catalogueItem";
import {
  FreeformDateGroupSpec,
  SearchFilterFields,
  SearchSortFields,
} from "../search/searchutils";

export const wwiServiceSchema = yup.object({
  id: yup.string().required(),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  familyName: yup.string().required(),
  suffix: yup.string().nullable(),
  residencePriorToService: yup.string().nullable(),
  residenceCoordinates: coordinateSchema.nullable().default(undefined),
  locatorNumber: yup.string().required(),
  certificateNumber: yup.number().required(),
  certificateId: yup.string().required(),
  birthDateStart: yup.date().nullable(),
  birthDateEnd: yup.date().nullable(),
  notes: yup.string().nullable(),
  pdfSHA256: yup.string().nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema).default([]),
});

export type WWIServiceRecord = yup.InferType<typeof wwiServiceSchema>;

export const wwiServiceInputSchema = wwiServiceSchema.shape({
  id: yup.string().nullable(),
  certificateNumber: yup.number().nullable(),
  certificateId: yup.string().nullable(),
});

export type WWIServiceRecordInput = yup.InferType<typeof wwiServiceInputSchema>;

export const wwiServiceEventSchema = eventSchema.shape({
  state: wwiServiceSchema,
});

export const wwiServiceEventSchemaList = yup
  .array()
  .of(wwiServiceEventSchema)
  .required();

export const wwiServiceLabels: Labels<WWIServiceRecord> = {
  id: "UUID",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  residencePriorToService: "Residence Prior to Service",
  residenceCoordinates: "Residence Coordinates",
  locatorNumber: "Locator Number",
  certificateNumber: "Certificate Number",
  certificateId: "Certificate Id",
  birthDateStart: "Exact Birth Date or Range Start",
  birthDateEnd: "Birth Date Range End",
  notes: "Notes",
  pdfSHA256: "PDF SHA256",
  alternateNames: "Alternate Names",
};

export const wwiServiceSorts: SearchSortFields<WWIServiceRecord> = {
  prefix: { label: wwiServiceLabels["prefix"] },
  firstName: { label: wwiServiceLabels["firstName"] },
  middleName: { label: wwiServiceLabels["middleName"] },
  familyName: { label: wwiServiceLabels["familyName"] },
  suffix: { label: wwiServiceLabels["suffix"] },
  residencePriorToService: {
    label: wwiServiceLabels["residencePriorToService"],
  },
  locatorNumber: { label: wwiServiceLabels["locatorNumber"] },
  certificateId: { label: wwiServiceLabels["certificateId"] },
  birthDateStart: { label: "Birth Date Start" },
  birthDateEnd: { label: "Birth Date End" },
};

export const wwiServiceFilters: SearchFilterFields = {
  prefix: {
    label: wwiServiceLabels["prefix"],
    type: "string",
  },
  firstName: {
    label: wwiServiceLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: wwiServiceLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: wwiServiceLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: wwiServiceLabels["suffix"],
    type: "string",
  },
  "notes.normalized": {
    label: wwiServiceLabels["notes"],
    type: "string",
    defaultOperator: "*",
  },
  birthDateIsInexact: {
    label: "Birth Date is Inexact",
    type: "boolean",
  },
  residencePriorToService: {
    label: wwiServiceLabels["residencePriorToService"],
    type: "string",
    allowNullOps: true,
  },
  locatorNumber: {
    label: wwiServiceLabels["locatorNumber"],
    type: "string",
  },
  alternateNames: {
    label: wwiServiceLabels["alternateNames"],
    type: "string",
  },
  certificateId: {
    label: wwiServiceLabels["certificateId"],
    type: "string",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};

export const wwiServiceFreeformDateSpecs: FreeformDateGroupSpec[] = [
  {
    groupName: "Birth Date",
    startDayName: "birthStartDay",
    startMonthName: "birthStartMonth",
    startYearName: "birthStartYear",
    endDayName: "birthEndDay",
    endMonthName: "birthEndMonth",
    endYearName: "birthEndYear",
    startDateName: "birthDateStart",
    endDateName: "birthDateEnd",
  },
];
