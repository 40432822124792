import * as api from "../api/genericEndpoints";
import { useRMAMedia } from "../api/genericEndpoints";
import {
  vetsGraveEventSchemaList,
  VetsGraveInput,
  VetsGraveRecord,
  vetsGraveSchema,
} from "../schemas/vetsGrave";

const endpoint = "vets-graves";

export function useVetsGrave(accessToken: string, uuid?: string) {
  return api.useRecord<VetsGraveRecord>(
    endpoint,
    vetsGraveSchema,
    accessToken,
    uuid,
  );
}

export function useUpdateVetsGrave() {
  return api.useUpdateRecord<VetsGraveInput>(endpoint);
}

export function useDeleteVetsGrave() {
  return api.useDeleteRecord(endpoint);
}

export function useVetsGraveEvents(accessToken: string, uuid?: string) {
  return api.useRMAEvents<VetsGraveRecord>(
    endpoint,
    vetsGraveEventSchemaList,
    accessToken,
    uuid,
  );
}
export function useVetsGraveImage(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
