import Card, { NavCard } from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading } from "../../shared/TextComponents";
import { hasPermissions } from "../auth/authutils";
import BreadcrumbPage from "../BreadcrumbPage";
import { DefaultPageProps } from "../utils/shared";

export default function CommentAdminPage({
  currentUserData,
}: DefaultPageProps) {
  const canSeeUrlPats = hasPermissions(
    currentUserData,
    "view_cmt_sub_url_pats",
  );

  const canSeeCmtBlockedEmails = hasPermissions(
    currentUserData,
    "view_cmt_blocked_emails",
  );

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Comment System Admin</Heading>
          <NavCard heading="Comment Activity Feed" url="../">
            View and manage the latest new comments.
          </NavCard>
          {canSeeCmtBlockedEmails && (
            <NavCard heading="Blocked Emails" url="./blocked-emails">
              View and manage the emails that are currently blocked from
              commenting.
            </NavCard>
          )}
          {canSeeUrlPats && (
            <NavCard heading="Subscription Email URL Patterns" url="./sub-urls">
              Configure the url patterns used by the automated comment
              subscription-related emails.
            </NavCard>
          )}
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
