import axios from "axios";
import { API_URL } from "../../env";

export const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const baseAxiosConfig = (accessToken: string) => ({
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export class Error {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class ServiceError extends Error {}

export class ValidationError extends Error {
  invalidItem: any;

  constructor(message: string, invalidItem: any) {
    super(message);
    this.invalidItem = invalidItem;
  }
}

export class AuthenticationError extends Error {}

export class AuthorizationError extends Error {}

export type APIError =
  | ValidationError
  | AuthorizationError
  | AuthenticationError
  | ServiceError;

export const handleAPIErrors = (e: any): APIError => {
  if (axios.isAxiosError(e)) {
    if (e.response?.status === 401) return new AuthenticationError(e.message);
    if (e.response?.status === 403) return new AuthorizationError(e.message);
    console.error(e.message, e.response);
    return new ServiceError(e.message);
  }
  throw e;
};
