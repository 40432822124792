import { Button } from "@shopify/polaris";
import { PlusIcon } from "@shopify/polaris-icons";

export function AddSearchParamBtn({ onClick }: { onClick: () => void }) {
  return <Button onClick={onClick} size="slim" icon={PlusIcon} />;
}

export function ContentSensitiveClearBtn({
  content,
  onClick,
}: {
  content: object;
  onClick: () => void;
}) {
  return (
    Object.keys(content).length > 0 && (
      <Button size="slim" onClick={onClick}>
        Clear All
      </Button>
    )
  );
}
