import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import {
  deleteUser,
  getUser,
  getUsers,
  setRoles,
  updateUser,
  updateUserPrefs,
} from "../api/endpoints";
import { UserInput, UserPrefs } from "../schemas/core";

export function useUser(accessToken: string, uuid?: string) {
  return useQuery(
    ["user", accessToken, uuid],
    async () => (accessToken && uuid ? await getUser(accessToken, uuid) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useUsers(accessToken: string) {
  return useQuery(
    ["users", accessToken],
    async () => (accessToken ? await getUsers(accessToken) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useSetUserRoles() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      userUUID,
      roleUUIDs,
    }: {
      accessToken: string;
      userUUID: string;
      roleUUIDs: string[];
    }) => {
      return setRoles(accessToken, userUUID, roleUUIDs);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", "roles"]);
      },
    },
  );
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: UserInput;
    }) => {
      return updateUser(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["user"]);
      },
    },
  );
}

export function useUpdateUserPrefs() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ accessToken, prefs }: { accessToken: string; prefs: UserPrefs }) => {
      return updateUserPrefs(accessToken, prefs);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["user"]);
      },
    },
  );
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteUser(accessToken, uuid);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["users"]);
      },
    },
  );
}
