import { useEffect, useState } from "react";
import Card from "../../shared/Card";
import { collectionsSearchConfigLabels } from "../schemas/catalogueItem";
import { CollectionsSearchConfig } from "../schemas/catalogueItem";
import { AllowedCollectionsDateFieldRoot } from "../schemas/catalogueItem";
import { allowedCollectionsDateFieldRoots } from "../schemas/catalogueItem";
import { DefaultPageProps, PropsOfType, toTitle } from "../utils/shared";
import Stack from "../../shared/Stack";
import { Heading, PText, Subheading } from "../../shared/TextComponents";
import { Button, Checkbox, Spinner } from "@shopify/polaris";
import {
  useCollectionsSearchConfig,
  useUpdateCollectionsSearchConfig,
} from "../hooks/catalogueItemHooks";
import BreadcrumbPage from "../BreadcrumbPage";
import { v4 as uuid } from "uuid";
import { CircleLeftIcon, CircleRightIcon } from "@shopify/polaris-icons";

interface GeneralDateConfigProps {
  config: AllowedCollectionsDateFieldRoot[];
  onChange: (config: AllowedCollectionsDateFieldRoot[]) => void;
  isLoading?: boolean;
}

export function GeneralDateConfig(props: GeneralDateConfigProps) {
  const addOptToConfig = (opt: AllowedCollectionsDateFieldRoot) => {
    props.onChange([...props.config, opt]);
  };

  const removeOptFromConfig = (opt: AllowedCollectionsDateFieldRoot) => {
    props.onChange(props.config.filter((value) => value !== opt));
  };

  const reorder = (
    opt: AllowedCollectionsDateFieldRoot,
    direction: "R" | "L"
  ) => {
    let idx = props.config.indexOf(opt);
    let newConfig = [
      ...props.config.slice(0, idx),
      ...props.config.slice(idx + 1),
    ];
    if (direction === "L") {
      newConfig.splice(idx - 1, 0, opt);
    } else {
      newConfig.splice(idx + 1, 0, opt);
    }
    props.onChange(newConfig);
  };

  return (
    <Card innerCard>
      <Stack spacing={4}>
        <Stack>
          <Subheading>General Date Definition</Subheading>
          <PText>
            Check the boxes below to include that date type in date filtering
            and sorting:
          </PText>
          <Stack direction="row">
            {allowedCollectionsDateFieldRoots.map((option) => (
              <Checkbox
                key={uuid()}
                label={toTitle(option)}
                disabled={props.isLoading}
                checked={props.config.includes(option)}
                onChange={(newChecked) =>
                  newChecked
                    ? addOptToConfig(option)
                    : removeOptFromConfig(option)
                }
              />
            ))}
          </Stack>
        </Stack>
        <Stack>
          <Subheading>General Date Sorting</Subheading>
          <PText>
            Arrange the date types in the order you want them to be sorted in:
          </PText>
          <Stack direction="row">
            {props.config.map((option, idx) => (
              <Stack key={uuid()} direction="row" align="center">
                <Button
                  icon={CircleLeftIcon}
                  variant="plain"
                  onClick={() => reorder(option, "L")}
                  disabled={idx === 0 || props.isLoading}
                />
                <PText>{toTitle(option)}</PText>
                <Button
                  icon={CircleRightIcon}
                  variant="plain"
                  onClick={() => reorder(option, "R")}
                  disabled={idx === props.config.length - 1 || props.isLoading}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

type OtherSettings = {
  [P in PropsOfType<CollectionsSearchConfig, boolean>]: boolean;
};

interface OtherSettingsConfigProps {
  config: OtherSettings;
  onChange: (setting: keyof OtherSettings, value: boolean) => void;
  isLoading?: boolean;
}

export function OtherSettingsConfig(props: OtherSettingsConfigProps) {
  return (
    <Card innerCard>
      <Stack>
        <Subheading>Other Settings</Subheading>
        {Object.entries(props.config).map(([setting, value]) => (
          <Checkbox
            onChange={(v) => {
              props.onChange(setting as keyof OtherSettings, v);
            }}
            disabled={props.isLoading}
            checked={value}
            label={
              collectionsSearchConfigLabels[
                setting as keyof CollectionsSearchConfig
              ]
            }
          />
        ))}
      </Stack>
    </Card>
  );
}

export function CollectionsSearchConfigPage(props: DefaultPageProps) {
  const { data: config } = useCollectionsSearchConfig(
    props.currentUserData.accessToken
  );
  const [searchConfig, setSearchConfig] = useState(config);
  const [changed, setChanged] = useState(false);

  const defaultConfig: CollectionsSearchConfig = {
    purgeUserInput: false,
    generalDateConfig: ["content", "creation"],
  };

  useEffect(() => {
    if (config) {
      setSearchConfig(config);
    }
  }, [config, setSearchConfig]);

  const mutation = useUpdateCollectionsSearchConfig();

  const updateGeneralDateConfig = (
    dateConfig: AllowedCollectionsDateFieldRoot[]
  ) => {
    setSearchConfig((prev) => {
      let conf = {
        ...defaultConfig,
        generalDateConfig: dateConfig,
      };
      if (prev) {
        return { ...prev, generalDateConfig: dateConfig };
      }
      return conf;
    });
    setChanged(true);
  };

  const updateBooleanSetting = (
    setting: keyof OtherSettings,
    value: boolean
  ) => {
    setSearchConfig((prev) => {
      let conf = {
        ...defaultConfig,
        [setting]: value,
      };
      if (prev) {
        return { ...prev, [setting]: value };
      }
      return conf;
    });
    setChanged(true);
  };

  const submit = async () => {
    if (searchConfig) {
      mutation.mutate({
        accessToken: props.currentUserData.accessToken,
        inputData: searchConfig,
      });
      setChanged(false);
    }
  };

  return (
    <BreadcrumbPage>
      <Card>
        <Stack>
          <Heading>Collections Search Settings</Heading>
          {searchConfig && (
            <>
              <GeneralDateConfig
                config={searchConfig.generalDateConfig}
                onChange={updateGeneralDateConfig}
                isLoading={mutation.isLoading}
              />
              <OtherSettingsConfig
                config={{ purgeUserInput: searchConfig.purgeUserInput }}
                onChange={updateBooleanSetting}
                isLoading={mutation.isLoading}
              />
            </>
          )}
          <Stack direction="row" justify="flex-end">
            {mutation.isLoading && <Spinner size="small" />}
            {changed && (
              <Button tone="success" variant="primary" onClick={submit}>
                Apply
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>
    </BreadcrumbPage>
  );
}
