import { Navigation as PolarisNav } from "@shopify/polaris";
import {
  AdjustIcon,
  ArchiveIcon,
  BookIcon,
  CalendarIcon,
  ChatIcon,
  ClipboardIcon,
  EmailIcon,
  ImagesIcon,
  LockIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  SettingsIcon,
  WandIcon,
} from "@shopify/polaris-icons";
import { ItemProps } from "@shopify/polaris/build/ts/src/components/Navigation/types";
import { removeNulls } from "./utils/shared";

interface ShowRecordMgmt {
  showSearch?: boolean;
  showAdd?: boolean;
  showBulkDash?: boolean;
  showBoostMgr?: boolean;
}

interface StdNavSectionProps {
  title: string;
  singularRecLabel: string;
  baseUrl: string;
  showRecMgmt?: ShowRecordMgmt;
  additionalProps?: (ItemProps | null)[];
}

function StdNavSection({
  title,
  singularRecLabel,
  baseUrl,
  showRecMgmt,
  additionalProps = [],
}: StdNavSectionProps) {
  const stdNav = showRecMgmt
    ? removeNulls([
        showRecMgmt.showSearch
          ? {
              url: `/rma/${baseUrl}`,
              label: `Search ${singularRecLabel}s`,
              icon: ArchiveIcon,
            }
          : null,
        showRecMgmt.showAdd
          ? {
              url: `/rma/${baseUrl}/add`,
              label: `Add ${singularRecLabel}`,
              icon: PlusCircleIcon,
            }
          : null,
        showRecMgmt.showBoostMgr
          ? {
              url: `/rma/${baseUrl}/boosts`,
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        showRecMgmt.showBulkDash
          ? {
              url: `/rma/${baseUrl}/bulk/dash`,
              label: "Bulk Editing Area",
              icon: WandIcon,
            }
          : null,
        ...additionalProps,
      ])
    : [];

  return (
    stdNav.length > 0 && (
      <PolarisNav.Section separator title={title} items={stdNav} />
    )
  );
}

interface BirthRecordMgmt extends ShowRecordMgmt {
  showAnnualRelease?: boolean;
}

interface NavigationProps {
  showAuth?: boolean;
  showEmailAutos?: boolean;
  showAdminRoleMgmt?: boolean;
  birthRecords?: BirthRecordMgmt;
  deathRecords?: ShowRecordMgmt;
  goldStarRecords?: ShowRecordMgmt;
  vetsGraves?: ShowRecordMgmt;
  census?: ShowRecordMgmt;
  wwiService?: ShowRecordMgmt;
  wwiBonuses?: ShowRecordMgmt;
  wwiSolPhotos?: {
    showSearch?: boolean;
    showBoostMgr?: boolean;
  };
  collections?: {
    showSearch?: boolean;
    showBoostMgr?: boolean;
    showSearchConfig?: boolean;
  };
  findAids?: ShowRecordMgmt;
  comments?: boolean;
}

export default function Navigation(props: NavigationProps) {
  const birthRecords = (
    <StdNavSection
      title="Birth Records"
      singularRecLabel="birth record"
      baseUrl="birth-records"
      showRecMgmt={props.birthRecords}
      additionalProps={[
        props.birthRecords?.showAnnualRelease
          ? {
              url: "/rma/birth-records/annual-release",
              label: "Annual Record Release",
              icon: CalendarIcon,
            }
          : null,
      ]}
    />
  );

  const deathRecords = (
    <StdNavSection
      title="Death Records"
      singularRecLabel="death record"
      baseUrl="death-records"
      showRecMgmt={props.deathRecords}
    />
  );

  const goldStarRecords = (
    <StdNavSection
      title="Gold Star Roll"
      singularRecLabel="gold star roll record"
      baseUrl="gold-star"
      showRecMgmt={props.goldStarRecords}
    />
  );

  const vetsGraveRecords = (
    <StdNavSection
      title="Veterans Graves"
      singularRecLabel="vets grave"
      baseUrl="vets-graves"
      showRecMgmt={props.vetsGraves}
      additionalProps={[
        props.vetsGraves?.showSearch
          ? {
              label: "Vets graves finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open("http://www2.mnhs.org/library/findaids/va001.xml"),
            }
          : null,
      ]}
    />
  );

  const censusRecords = (
    <StdNavSection
      title="Census"
      singularRecLabel="census record"
      baseUrl="census"
      showRecMgmt={props.census}
      additionalProps={[
        props.census?.showSearch
          ? {
              url: "/rma/census/page",
              label: "Search census pages",
              icon: BookIcon,
            }
          : null,
        props.census?.showAdd
          ? {
              url: "/rma/census/page/add",
              label: "Add census page",
              icon: PlusCircleIcon,
            }
          : null,
        props.census?.showBoostMgr
          ? {
              url: "/rma/census/page/boosts",
              label: "Manage page weights",
              icon: AdjustIcon,
            }
          : null,
      ]}
    />
  );

  const wwiService = (
    <StdNavSection
      title="WWI Service Records"
      baseUrl="wwi-service"
      singularRecLabel="WWI service record"
      showRecMgmt={props.wwiService}
      additionalProps={[
        props.wwiService?.showSearch
          ? {
              label: "WWI Service records finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open(
                  "http://www2.mnhs.org/library/findaids/gr00980.xml",
                ),
            }
          : null,
      ]}
    />
  );

  const wwiBonuses = (
    <StdNavSection
      title="WWI Bonus Records"
      baseUrl="wwi-bonuses"
      singularRecLabel="WWI Bonus record"
      showRecMgmt={props.wwiBonuses}
      additionalProps={[
        props.wwiBonuses?.showSearch
          ? {
              label: "Bonus records finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open(
                  "http://www2.mnhs.org/library/findaids/gr00401.xml",
                ),
            }
          : null,
        props.wwiBonuses?.showSearch
          ? {
              label: "Disallowed record finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open(
                  "http://www2.mnhs.org/library/findaids/gr00056.xml",
                ),
            }
          : null,
      ]}
    />
  );

  const wwiSolPhotosNav = props.wwiSolPhotos
    ? removeNulls([
        props.wwiSolPhotos.showSearch
          ? {
              url: "/rma/wwi-soldiers-photos",
              label: "Search photos",
              icon: ArchiveIcon,
            }
          : null,
        props.wwiSolPhotos.showBoostMgr
          ? {
              url: "/rma/wwi-soldiers-photos/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.wwiSolPhotos.showSearch
          ? {
              label: "Soldiers photos finding aid",
              icon: ClipboardIcon,
              onClick: () =>
                window.open(
                  "http://www2.mnhs.org/library/findaids/gr00131.xml",
                ),
            }
          : null,
      ])
    : [];

  const wwiSolPhotos = wwiSolPhotosNav.length > 0 && (
    <PolarisNav.Section
      separator
      title="WWI Soldiers Photos"
      items={wwiSolPhotosNav}
    />
  );

  const collectionsNav = props.collections
    ? removeNulls([
        props.collections.showSearch
          ? {
              url: "/rma/collections/catalogue-items",
              label: "Search cms",
              icon: ArchiveIcon,
            }
          : null,
        props.collections.showBoostMgr
          ? {
              url: "/rma/collections/catalogue-items/boosts",
              label: "Manage weights",
              icon: AdjustIcon,
            }
          : null,
        props.collections.showSearchConfig
          ? {
              url: "/rma/collections/search-config",
              label: "Search settings",
              icon: SettingsIcon,
            }
          : null,
        props.collections.showSearchConfig
          ? {
              url: "/rma/collections/catalogue-items/image-config",
              label: "Image resolution settings",
              icon: ImagesIcon,
            }
          : null,
      ])
    : [];

  const collections = collectionsNav.length > 0 && (
    <PolarisNav.Section separator title="Collections" items={collectionsNav} />
  );

  const findAidsNav = props.findAids
    ? removeNulls([
        props.findAids.showSearch
          ? {
              url: "/rma/finding-aids",
              label: "Finding Aids",
              icon: ArchiveIcon,
            }
          : null,
        props.findAids.showAdd
          ? {
              url: "/rma/finding-aids/add",
              label: "Add finding aid",
              icon: PlusCircleIcon,
            }
          : null,
      ])
    : [];

  const findAids = findAidsNav.length > 0 && (
    <PolarisNav.Section separator title="Finding Aids" items={findAidsNav} />
  );

  const adminNav = removeNulls([
    props.showAuth
      ? {
          url: "/rma/auth/",
          label: "Manage authorization",
          icon: LockIcon,
        }
      : null,
    props.showEmailAutos
      ? {
          url: "/rma/admin/email-autos",
          label: "Manage email automations",
          icon: EmailIcon,
        }
      : null,
    props.showAdminRoleMgmt
      ? {
          url: "/rma/auth/roles",
          label: "Role Management",
          icon: LockIcon,
        }
      : null,
    props.comments
      ? {
          url: "/rma/comments",
          label: "Comments Feed",
          icon: ChatIcon,
        }
      : null,
    props.comments
      ? {
          url: "/rma/comments/admin",
          label: "Comments Admin",
          icon: SettingsIcon,
        }
      : null,
  ]);

  const admin = adminNav.length > 0 && (
    <PolarisNav.Section separator title="Admin" items={adminNav} />
  );

  const helpNav = removeNulls([
    {
      label: "Documentation",
      icon: QuestionCircleIcon,
      onClick: () =>
        window.open("https://vital-records-docs.mnhs.io/index.html"),
    },
    props.birthRecords?.showSearch
      ? {
          label: "New Frostbite Docs",
          icon: ClipboardIcon,
          onClick: () =>
            window.open(
              "https://docs.google.com/document/d/1hyZ7DZhNBYLgmI8HEaUXy0ojtQe09hjwJY520LsZvBE/edit#heading=h.1ydujcykuabd",
            ),
        }
      : null,
  ]);

  const help = <PolarisNav.Section separator title="Support" items={helpNav} />;

  return (
    <PolarisNav location="/">
      {birthRecords}
      {deathRecords}
      {goldStarRecords}
      {vetsGraveRecords}
      {censusRecords}
      {wwiService}
      {wwiBonuses}
      {wwiSolPhotos}
      {collections}
      {findAids}
      {admin}
      {help}
    </PolarisNav>
  );
}
