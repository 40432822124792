import { yupResolver } from "@hookform/resolvers/yup";
import { Button, InlineError, Layout, Page, Spinner } from "@shopify/polaris";
import { XSmallIcon } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Subheading } from "../../shared/TextComponents";
import { addCensusImage } from "../api/endpoints";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  BaseFormCompProps,
  CountyFormField,
  FormFields,
  FormSection,
} from "../Form";
import {
  useCensusPage,
  useCensusPageImage,
  useDeleteCensusPage,
  useUpdateCensusPage,
} from "../hooks/censusHooks";
import {
  CensusPage,
  CensusPageInput,
  censusPageInputSchema,
  censusPageLabels,
} from "../schemas/censusPage";
import Skeleton from "../Skeleton";
import ImageDropSuite from "../utils/ImageDropSuite";
import { DefaultPageProps } from "../utils/shared";

export default function CensusPageForm(props: BaseFormCompProps<CensusPage>) {
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState<string | null>();

  const page = props.record;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<CensusPageInput>({
    resolver: yupResolver(censusPageInputSchema),
    // defaultValues: censusPageInputSchema.cast(page),
  });

  const {
    fields: countiesFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "counties",
  });

  useEffect(() => {
    if (!page) {
      return;
    }
    setImage(page.SHA256Hash);
    reset(censusPageInputSchema.cast(page));
  }, [setImage, page, reset]);

  const { data: imageData } = useCensusPageImage(
    props.currentUserData.accessToken,
    image,
  );

  const onSubmit = (inputData: CensusPageInput) => {
    if (image) {
      inputData.SHA256Hash = image;
    }
  };

  const countiesAccumulator = (
    <Stack align="flex-start">
      {countiesFields.map((field, idx) => {
        const key = `counties.${idx}.value`;
        return (
          <>
            <Stack direction="row">
              <Controller
                key={field.id}
                // Can't seem to refer back to the key const here, even when
                // casting as Path<>. Very odd.
                name={`counties.${idx}.value`}
                control={control}
                render={({ field }) => (
                  <CountyFormField
                    label={key}
                    onChange={field.onChange}
                    value={field.value}
                    labelHidden
                  />
                )}
              />
              <Button icon={XSmallIcon} onClick={() => remove(idx)} />
            </Stack>
          </>
        );
      })}
      {errors.counties && <InlineError fieldID="counties" message={"error"} />}
      <Button onClick={() => append({ value: "" } as any)}>Add</Button>
    </Stack>
  );

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={censusPageInputSchema}
      initialValues={page}
      onSubmit={onSubmit}
      mutateHook={useUpdateCensusPage}
      deleteHook={useDeleteCensusPage}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <ImageDropSuite
        currentUserData={props.currentUserData}
        addImageEndpoint={addCensusImage}
        front={{
          src: imageData?.content,
          hash: image,
          setHash: setImage,
          loading: imageLoading,
          setLoading: setImageLoading,
        }}
      />
      <FormFields
        control={control}
        labels={censusPageLabels}
        errors={errors}
        reset={reset}
        getValues={getValues}
        spec={{
          reel: { type: "text", required: true },
          page: { type: "number", required: true },
          year: { type: "number", required: true },
        }}
      />
      <Subheading>Counties</Subheading>
      {countiesAccumulator}
    </FormSection>
  );
}

export function CensusPageAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Card>
          <CensusPageForm
            currentUserData={props.currentUserData}
            onSubmitSuccess={(id) => navigate(`../${id}`)}
          />
        </Card>
      </Layout>
    </Page>
  );
}

export function CensusPageEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: page } = useCensusPage(props.currentUserData.accessToken, uuid);

  const skeleton = !page && (
    <Stack>
      <Spinner size="large" />
      <Skeleton lines={2} />
    </Stack>
  );

  const form = page && (
    <CensusPageForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={page}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
