import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import { getFindingAid, updateFindingAid } from "../api/endpoints";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import { FindingAidInput } from "../schemas/findAid";

export function useFindingAid(accessToken: string, uuid?: string) {
  return useQuery(
    ["findingAid", accessToken, uuid],
    async () =>
      accessToken && uuid ? await getFindingAid(accessToken, uuid) : null,
    { staleTime: RQUERY_STALE_TIME }
  );
}

export const getFindaidCount = async (accessToken: string): Promise<number> => {
  const resp = await axiosInstance.get(
    "/finding-aids/count",
    baseAxiosConfig(accessToken)
  );
  return resp.data;
};

export function useUpdateFindingAid() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      inputData,
    }: {
      accessToken: string;
      inputData: FindingAidInput;
    }) => {
      return updateFindingAid(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["findingAid"]);
      },
    }
  );
}
