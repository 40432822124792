import { Dispatch, SetStateAction } from "react";
import { CurrentUserData } from "../schemas/core";
import { Button, Spinner } from "@shopify/polaris";
import { Subheading } from "../../shared/TextComponents";
import * as api from "../api/shared";
import FileDrop, { FileDropResult } from "./FileDrop";
import ZoomableImage from "../ZoomableImage";
import Stack from "../../shared/Stack";

interface ImageDropSlice {
  src: Blob | null | undefined;
  hash: string | null | undefined;
  setHash: Dispatch<SetStateAction<string | null | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface ImageDropSuiteProps {
  currentUserData: CurrentUserData;
  addImageEndpoint: (
    accessToken: string,
    imageFile: File,
  ) => Promise<string | api.APIError>;
  front: ImageDropSlice;
}

export default function ImageDropSuite({
  currentUserData,
  addImageEndpoint,
  front: {
    src: frontSrc,
    hash: frontHash,
    setHash: setFrontHash,
    loading: frontLoading,
    setLoading: setFrontLoading,
  },
}: ImageDropSuiteProps) {
  const imageAdder = async (
    files: Record<string, File>,
    setter: Dispatch<SetStateAction<string | null | undefined>>,
  ) => {
    const result: Record<string, FileDropResult> = {};
    for (const sha256 in files) {
      const serverSHA256 = await addImageEndpoint(
        currentUserData.accessToken,
        files[sha256],
      );
      if (serverSHA256 instanceof api.Error) {
        result[sha256] = {
          serverSHA256: null,
          error: serverSHA256.message,
        };
      } else {
        result[sha256] = {
          serverSHA256,
          error: null,
        };
        setter(serverSHA256);
      }
    }
    return result;
  };

  const display = ({
    imgSrc,
    imgAlt,
    removeText,
    setter,
  }: {
    imgSrc: Blob;
    imgAlt: string;
    removeText: string;
    setter: Dispatch<SetStateAction<string | null | undefined>>;
  }) => (
    <Stack>
      <ZoomableImage src={imgSrc} alt={imgAlt} />
      <Stack direction="row" justify="flex-end">
        <Button tone="critical" variant="primary" onClick={() => setter(null)}>
          {removeText}
        </Button>
      </Stack>
    </Stack>
  );

  const frontDrop = !frontHash && (
    <FileDrop
      fileType="image"
      onDrop={async (files) => {
        setFrontLoading(true);
        const result = await imageAdder(files, setFrontHash);
        setFrontLoading(false);
        return result;
      }}
      onRemove={() => {}}
    />
  );

  const frontDisplay =
    frontSrc &&
    display({
      imgSrc: frontSrc,
      imgAlt: "Front of paper record",
      removeText: "Remove Front Image",
      setter: setFrontHash,
    });

  return (
    <>
      <Subheading>Front</Subheading>
      {frontLoading || (frontHash && !frontSrc) ? (
        <Spinner />
      ) : (
        frontDrop || frontDisplay
      )}
    </>
  );
}
