import { Layout, Page } from "@shopify/polaris";
import { catalogueItemFilters } from "../schemas/catalogueItem";
import { catalogueItemSorts } from "../schemas/catalogueItem";
import { catalogueItemLabels } from "../schemas/catalogueItem";
import { CatalogueItem } from "../schemas/catalogueItem";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { CatalogueItemDetail } from "./CatalogueItemDetail";
import { SearchQueryProps } from "../search/searchutils";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { PText } from "../../shared/TextComponents";

function renderCatalogueItemHit(record: CatalogueItem) {
  const displayDate = record.contentDateStart || record.creationDateStart;

  //   const media = [
  //     ...record.images,
  //     ...record.videos,
  //     ...record.docs,
  //     ...record.audios,
  //   ];
  //   const { data: thumbnail } = useCatalogueItemMedia(
  //     currentUserData.accessToken,
  //     media[0]?.id,
  //     media[0]?.thumbnail
  //   );
  //   const thumbnailURL = thumbnail && URL.createObjectURL(thumbnail.content);

  return (
    <>
      <PText weight="bold">{record.title}</PText>
      <PText>{record.alternateId}</PText>
      <PText>{record.itemType}</PText>
      <PText>{displayDate && new Date(displayDate).toLocaleDateString()}</PText>
    </>
  );
}

export default function CatalogueItemSearchPage(
  props: BaseSearchPageComponentProps<CatalogueItem>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={catalogueItemLabels}
          renderSearchHit={{
            render: renderCatalogueItemHit,
            accessibilityLabel: (record) => `View details for ${record.title}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            CatalogueItemDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          sortFields={catalogueItemSorts}
          filterFields={catalogueItemFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<CatalogueItem>(props, "collections")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "collections")
          }
          recordName="CMS Item"
          contextualPrefs={props.contextualPrefs}
        />
      </Layout>
    </Page>
  );
}
