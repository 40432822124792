import React, { useState } from "react";
import * as searchutils from "./searchutils";
import { ContextualPrefs } from "../schemas/core";
import * as utils from "../utils/shared";

export interface PersistentSearchState<T extends object> {
  advOpen: boolean;
  toggleAdvOpen: () => void;
  editMode: boolean;
  toggleEditMode: () => void;
  navMode: boolean;
  toggleNavMode: () => void;
  query: string;
  setQuery: (value: string) => void;
  sorts: searchutils.SearchSortParams<T>;
  setSorts: React.Dispatch<
    React.SetStateAction<searchutils.SearchSortParams<T>>
  >;
  addSort: (k: keyof T, sortOrder: searchutils.SearchSortOrder) => void;
  removeSort: (k: keyof T) => void;
  toggleSortOrder: (k: keyof T) => void;
  clearSorts: () => void;
  filters: (
    | searchutils.SearchFilterParam
    | searchutils.SearchFilterConjunctParam
  )[];
  addFilter: (
    f: searchutils.SearchFilterParam | searchutils.SearchFilterConjunctParam,
  ) => void;
  updateFilter: (
    f: searchutils.SearchFilterParam | searchutils.SearchFilterConjunctParam,
    idx: number,
  ) => void;
  removeFilter: (idx: number) => void;
  clearFilters: () => void;
  freeformDates: searchutils.FreeformDatesByGroup;
  addFreeformDate: (group: string, ranges: searchutils.FreeformDates) => void;
  clearFreeformDate: (group: string) => void;
  clearAllFreeformDates: () => void;
  updateFromContextualPrefs: (prefs: ContextualPrefs) => void;
  freeformDateSpecs?: searchutils.FreeformDateGroupSpec[];
  genSearchQuery: () => searchutils.SearchQuery;
}

export function GenPersistentSearchState<T extends object>(
  freeformDateSpecs?: searchutils.FreeformDateGroupSpec[],
): PersistentSearchState<T> {
  const [advOpen, setAdvOpen] = useState<boolean>(true);
  const [editModeActive, setEditMode] = useState(false);
  const [navModeActive, setNavMode] = useState(false);
  const [query, setQuery] = useState("");
  const [sorts, setSorts] = useState<searchutils.SearchSortParams<T>>({});
  const [filters, setFilters] = useState<
    (searchutils.SearchFilterParam | searchutils.SearchFilterConjunctParam)[]
  >([]);
  const [freeformDates, setFreeformDates] =
    useState<searchutils.FreeformDatesByGroup>({});

  const updateQuery = (q: string) => {
    setQuery(q);
  };

  const addSort = (k: keyof T, sortOrder: searchutils.SearchSortOrder) => {
    setSorts((previousSorts) => ({
      ...previousSorts,
      [k]: { field: k, direction: sortOrder },
    }));
  };

  const removeSort = (k: keyof T) => {
    const result = utils.pop(k, sorts as Record<keyof T, any>);
    setSorts(result[1]);
  };

  const toggleSortOrder = (k: keyof T) => {
    const newOrder = sorts[k]?.direction === "asc" ? "desc" : "asc";
    setSorts((previousSorts) => ({
      ...previousSorts,
      [k]: { field: k, direction: newOrder },
    }));
  };

  const clearSorts = () => {
    setSorts({});
  };

  const addFilter = (
    f: searchutils.SearchFilterParam | searchutils.SearchFilterConjunctParam,
  ) => {
    setFilters((previousFilters) => {
      previousFilters.push(f);
      return previousFilters;
    });
  };

  const updateFilter = (
    f: searchutils.SearchFilterParam | searchutils.SearchFilterConjunctParam,
    idx: number,
  ) => {
    let newFilters = [...filters];
    newFilters[idx] = f;
    setFilters(newFilters);
  };

  const removeFilter = (idx: number) => {
    setFilters(utils.removeItem(filters, idx));
  };

  const clearFilters = () => {
    setFilters([]);
  };

  const addFreeformDate = (
    group: string,
    ranges: searchutils.FreeformDates,
  ) => {
    setFreeformDates((previous) => ({
      ...previous,
      [group]: ranges,
    }));
  };

  const clearFreeformDates = (group: string) => {
    setFreeformDates((previous) => ({
      ...previous,
      [group]: searchutils.emptyFreeformDates,
    }));
  };

  const clearAllFreeformDates = () => {
    setFreeformDates({});
  };

  const updateFromPrefs = (prefs: ContextualPrefs) => {
    setAdvOpen(!prefs.useAdvSearchToggle);
    setEditMode(prefs.useEditMode);
    setNavMode(prefs.useNavMode);
  };

  const freeformSpecsByGroup: Record<
    string,
    searchutils.FreeformDateGroupSpec
  > = freeformDateSpecs
    ? freeformDateSpecs.reduce(
        (previous, spec) => ({
          ...previous,
          [spec.groupName]: spec,
        }),
        {},
      )
    : {};

  const genSearchQuery = (): searchutils.SearchQuery => {
    return {
      query: query,
      sorts: Object.values(sorts),
      filters: [
        ...searchutils.parseSearchParams(filters),
        ...utils.removeNulls(
          Object.entries(freeformDates).map(([group, dates]) =>
            searchutils.assembleFreeformDateFilters(
              dates,
              freeformSpecsByGroup[group],
            ),
          ),
        ),
      ],
    };
  };

  return {
    advOpen,
    toggleAdvOpen: () => setAdvOpen((open) => !open),
    editMode: editModeActive,
    toggleEditMode: () => setEditMode((active) => !active),
    navMode: navModeActive,
    toggleNavMode: () => setNavMode((active) => !active),
    query,
    setQuery: updateQuery,
    sorts,
    addSort,
    removeSort,
    toggleSortOrder,
    setSorts,
    clearSorts,
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    clearFilters,
    freeformDates,
    addFreeformDate,
    clearFreeformDate: clearFreeformDates,
    clearAllFreeformDates,
    updateFromContextualPrefs: updateFromPrefs,
    freeformDateSpecs,
    genSearchQuery,
  };
}
