import * as yup from "yup";
import { eventSchema, Labels, stringElementSchema } from "./core";
import { coordinateSchema } from "./catalogueItem";
import { SearchFilterFields, SearchSortFields } from "../search/searchutils";

export const OUTCOME = ["allowed", "disallowed"] as const;

export const wwiBonusSchema = yup.object({
  id: yup.string().required(),
  prefix: yup.string().nullable(),
  firstName: yup.string().nullable(),
  middleName: yup.string().nullable(),
  familyName: yup.string().required(),
  suffix: yup.string().nullable(),
  certificateId: yup.string().required(),
  outcome: yup
    .string()
    .oneOf([...OUTCOME])
    .required(),
  locatorNumber: yup.string().nullable(),
  residence: yup.string().nullable(),
  residenceCoordinates: coordinateSchema.nullable().default(undefined),
  payeeName: yup.string().nullable(),
  notes: yup.string().nullable(),
  alternateId: yup.number().nullable(),
  pdfSHA256: yup.string().nullable(),
  alternateNames: yup.array().nullable().of(stringElementSchema).default([]),
});

export type WWIBonusRecord = yup.InferType<typeof wwiBonusSchema>;

export const wwiBonusInputSchema = wwiBonusSchema.shape({
  id: yup.string().nullable(),
});

export type WWIBonusRecordInput = yup.InferType<typeof wwiBonusInputSchema>;

export const wwiBonusEventSchema = eventSchema.shape({
  state: wwiBonusSchema,
});

export const wwiBonusEventSchemaList = yup
  .array()
  .of(wwiBonusEventSchema)
  .required();

export const wwiBonusLabels: Labels<WWIBonusRecord> = {
  id: "UUID",
  prefix: "Prefix",
  firstName: "First Name",
  middleName: "Middle Name",
  familyName: "Family Name",
  suffix: "Suffix",
  certificateId: "Certificate Id",
  outcome: "Outcome",
  locatorNumber: "Locator Number",
  residence: "Residence",
  residenceCoordinates: "Residence Coordinates",
  payeeName: "Payee Name",
  notes: "Notes",
  alternateId: "Legacy ID",
  pdfSHA256: "PDF SHA256",
  alternateNames: "Alternate Names",
};

export const wwiBonusSorts: SearchSortFields<WWIBonusRecord> = {
  prefix: { label: wwiBonusLabels["prefix"] },
  firstName: { label: wwiBonusLabels["firstName"] },
  middleName: { label: wwiBonusLabels["middleName"] },
  familyName: { label: wwiBonusLabels["familyName"] },
  suffix: { label: wwiBonusLabels["suffix"] },
  certificateId: { label: wwiBonusLabels["certificateId"] },
  outcome: { label: wwiBonusLabels["outcome"] },
  locatorNumber: { label: wwiBonusLabels["locatorNumber"] },
  residence: {
    label: wwiBonusLabels["residence"],
  },
  payeeName: { label: wwiBonusLabels["payeeName"] },
};

export const wwiBonusFilters: SearchFilterFields = {
  prefix: {
    label: wwiBonusLabels["prefix"],
    type: "string",
  },
  firstName: {
    label: wwiBonusLabels["firstName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  middleName: {
    label: wwiBonusLabels["middleName"],
    type: "string",
    defaultOperator: "^",
    allowNullOps: true,
  },
  familyName: {
    label: wwiBonusLabels["familyName"],
    type: "string",
    defaultOperator: "^",
  },
  suffix: {
    label: wwiBonusLabels["suffix"],
    type: "string",
  },
  certificateId: {
    label: wwiBonusLabels["certificateId"],
    type: "string",
  },
  outcome: {
    label: wwiBonusLabels["outcome"],
    type: "string",
    validTerms: [...OUTCOME],
  },
  locatorNumber: {
    label: wwiBonusLabels["locatorNumber"],
    type: "string",
    allowNullOps: true,
  },
  residence: {
    label: wwiBonusLabels["residence"],
    type: "string",
  },
  payeeName: {
    label: wwiBonusLabels["payeeName"],
    type: "string",
    allowNullOps: true,
  },
  "notes.normalized": {
    label: wwiBonusLabels["notes"],
    type: "string",
    defaultOperator: "*",
  },
  alternateId: {
    label: wwiBonusLabels["alternateId"],
    type: "string",
  },
  alternateNames: {
    label: wwiBonusLabels["alternateNames"],
    type: "string",
  },
  "comments.content.normalized": {
    label: "Comment Content",
    type: "string",
  },
  hasComments: {
    label: "Has Comment(s)",
    type: "boolean",
  },
};
