import { Button, Divider, Spinner } from "@shopify/polaris";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { Heading, PText } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { BaseDetailCompProps } from "../Detail";
import Skeleton from "../Skeleton";
import ZoomableImage from "../ZoomableImage";
import {
  useCensusPage,
  useCensusPageAssocRecords,
  useCensusPageImage,
  useCensusPageNearbyPages,
  useUpdateCensusRecord,
} from "../hooks/censusHooks";
import { CensusRecord } from "../schemas/census";
import { CensusPage } from "../schemas/censusPage";
import { SearchResults } from "../search/SearchResults";
import Prompt from "../utils/Prompt";
import { DefaultPageProps } from "../utils/shared";
import CensusForm from "./CensusForm";
import { renderCensusHit } from "./CensusSearch";

export const padZeroes = (n: number, padLength: number) => {
  let s = n.toString();
  let zeroes = "0".repeat(padLength - s.length);
  return `${zeroes}${s}`;
};

export function CensusPageDetail(props: BaseDetailCompProps<CensusPage>) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useUpdateCensusRecord();

  const [modalActive, setModalActive] = useState(false);

  const page = props.record;

  const { data: image, isLoading: imageLoading } = useCensusPageImage(
    props.currentUserData.accessToken,
    page?.SHA256Hash,
  );
  const { data: assocRecords, isLoading: assocRecordsLoading } =
    useCensusPageAssocRecords(props.currentUserData.accessToken, page?.id);
  const { data: nearbyPages } = useCensusPageNearbyPages(
    props.currentUserData.accessToken,
    page?.id,
  );

  const invalidateAssocRecords = () =>
    queryClient.invalidateQueries(["censusPage", "assocRecords"]);

  const imageDisplay = !imageLoading && image && (
    <ZoomableImage
      src={image.content}
      alt="Page display"
      fileName={`${page?.reel}-${page?.page}`}
    />
  );

  const imageSpinner = imageLoading && !image && (
    <Stack>
      <Spinner size="large" />
    </Stack>
  );

  const heading = page && (
    <Heading>{`${page.reel}-${padZeroes(page.page, 4)}`}</Heading>
  );

  const detail = page && (
    <PText>
      <strong>Legacy ID:</strong> {page.legacyId} - <strong>Year:</strong>{" "}
      {page.year} - <strong>Counties:</strong>{" "}
      {page.counties.map((cty) => cty.value).join(", ")}
    </PText>
  );

  const unlink = async (inputData: CensusRecord) => {
    inputData.pageId = undefined;
    mutation.mutate(
      {
        accessToken: props.currentUserData.accessToken,
        inputData: inputData,
      },
      {
        onSuccess: () => {
          invalidateAssocRecords();
        },
      },
    );
  };

  // Definitely need to paginate these.
  const assocRecordDisplay = (
    <SearchResults
      result={assocRecords}
      isLoading={assocRecordsLoading}
      hitOnClick={(record) => navigate(`../../${record.id}`)}
      renderHit={{
        render: renderCensusHit,
        hitAltActions: (record) => [
          { label: "Unlink", onClick: () => unlink(record) },
        ],
      }}
    />
  );

  const addRecordBtn = page && assocRecords && (
    <Button fullWidth onClick={() => setModalActive(true)}>
      Add Record
    </Button>
  );

  const addRecordModal = page && (
    <Prompt
      active={modalActive}
      title="Add census record to page"
      onClose={() => setModalActive(false)}
    >
      <div style={{ padding: 10 }}>
        <CensusForm
          currentUserData={props.currentUserData}
          onSubmitSuccess={() => {
            setModalActive(false);
            invalidateAssocRecords();
          }}
          pageId={page.id}
          certificateId={`${page.year}-${page.reel}-${padZeroes(
            page.page,
            4,
          )}-`}
        />
      </div>
    </Prompt>
  );

  const toPage = (page: CensusPage | null) => {
    if (page) {
      navigate(`../${page.id}`);
    }
  };

  const pageNavBtns = nearbyPages && (
    <Stack direction="row" justify="space-between">
      <Button
        onClick={() => toPage(nearbyPages.prev)}
        disabled={nearbyPages.prev === null}
      >
        Previous Page
      </Button>
      <Button
        onClick={() => toPage(nearbyPages.next)}
        disabled={nearbyPages.next === null}
      >
        Next Page
      </Button>
    </Stack>
  );

  return (
    <Stack>
      {addRecordModal}
      <Stack>
        <Stack align="center">
          {imageSpinner || imageDisplay}
          {heading}
          {detail}
        </Stack>
        <Stack direction="row" align="flex-end" justify="flex-end">
          <Button
            onClick={() =>
              props.onEditBtnClick ? props.onEditBtnClick() : undefined
            }
          >
            Edit Page
          </Button>
        </Stack>
      </Stack>
      <Divider />
      {assocRecordDisplay || <Skeleton lines={20} />}
      {addRecordBtn}
      {pageNavBtns}
    </Stack>
  );
}

export function CensusPageDetailPage(props: DefaultPageProps) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: censusPage } = useCensusPage(
    props.currentUserData.accessToken,
    uuid,
  );

  return (
    <BreadcrumbPage breadcrumbAction="up" breadcrumbs="both">
      <Card>
        <CensusPageDetail
          currentUserData={props.currentUserData}
          record={censusPage}
          onEditBtnClick={() => navigate("./edit")}
        />
      </Card>
    </BreadcrumbPage>
  );
}
