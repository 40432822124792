import { Icon, Layout, Page } from "@shopify/polaris";
import { PageIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { Heading, PText } from "../../shared/TextComponents";
import { searchFindingAids } from "../api/endpoints";
import { getFindaidCount } from "../hooks/findAidHooks";
import { findaidFilters } from "../schemas/findAid";
import { findaidSorts } from "../schemas/findAid";
import { findaidLabels } from "../schemas/findAid";
import { FindingAid } from "../schemas/findAid";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";

function renderFindaidHit(record: FindingAid) {
  return (
    <>
      <Heading>{record.filename}</Heading>
      <PText>{record.title}</PText>
      <PText>
        <strong>Created: </strong>
        {new Date(record.createDt).toLocaleString()}
        {` \u25CF `}
        <strong>Last Update: </strong>
        {new Date(record.lastUpdateDt).toLocaleString()}
      </PText>
    </>
  );
}

export default function FindAidSearchPage(
  props: BaseSearchPageComponentProps<FindingAid>
) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={findaidLabels}
          sortFields={findaidSorts}
          filterFields={findaidFilters}
          recordName="Finding Aid"
          contextualPrefs={props.contextualPrefs}
          renderSearchHit={{
            render: renderFindaidHit,
            accessibilityLabel: (record) =>
              `View details for ${record.filename}`,
            hitRenderMedia: (record) => <Icon source={PageIcon} />,
            hitAltActions: (record) => [
              {
                label: "Preview",
                onClick: () =>
                  navigate(`/rma/finding-aids/${record.id}/preview`),
              },
            ],
          }}
          countQuery={async (accessToken: string) =>
            getFindaidCount(accessToken)
          }
          searchQuery={async (props: SearchQueryProps) =>
            searchFindingAids({
              accessToken: props.accessToken,
              query: props.query,
              sorts: props.sorts,
              filters: props.filters,
              pageNum: props.pageNum,
              pageSize: props.pageSize,
            })
          }
        />
      </Layout>
    </Page>
  );
}
