import {
  Button,
  Layout,
  Page,
  ResourceItem,
  ResourceList,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { Heading, PText } from "../../shared/TextComponents";
import { useRoles } from "../hooks/roleHooks";
import { CurrentUserData, Role } from "../schemas/core";
import Card from "../../shared/Card";

interface RolesPageProps {
  currentUserData: CurrentUserData;
}

export default function RolesPage(props: RolesPageProps) {
  const navigate = useNavigate();

  const { data: roles } = useRoles(props.currentUserData.accessToken);

  const genRoleItem = (r: Role) => {
    return (
      <ResourceItem
        id={r.id}
        accessibilityLabel={`Edit data for ${r.name}`}
        onClick={() => navigate(`./${r.id}`)}
      >
        <PText weight="bold">{r.name}</PText>
      </ResourceItem>
    );
  };

  const roleList = roles && (
    <ResourceList
      resourceName={{ singular: "role", plural: "roles" }}
      items={roles}
      renderItem={genRoleItem}
    />
  );

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            <Heading>Role Management</Heading>
            {roleList}
            <Button
              variant="primary"
              tone="success"
              onClick={() => navigate("./add")}
            >
              New Role
            </Button>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
