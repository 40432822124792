import { Button, Layout, Page } from "@shopify/polaris";
import { ArrowLeftIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

interface BreadcrumbPageProps {
  breadcrumbs?: "top" | "bottom" | "both";
  breadcrumbAction?: (() => void) | "back" | "up";
  children?: any[] | any;
}

/**
 * Generalized framework for displaying details of a record in the RMA.
 * @param breadcrumbs Pass a value to specify where you want a breadcrumb arrow
 * to appear on the page (top | bottom | both). Default is no breadcrumb, unless
 * you also pass breadcrumbAction, in which case the breadcrumb will appear on
 * the bottom by default.
 * @param breadcrumbAction Pass a function to call when the breadcrumb is
 * clicked. Alternately, pass "back" to go back 1 screen, and "up", to go to the
 * parent path of the current path (e.g. /rma/birth-records/uuid goes to
 * /rma/birth-records/).
 * @returns
 */
export default function BreadcrumbPage(props: BreadcrumbPageProps) {
  const navigate = useNavigate();
  const action =
    props.breadcrumbAction === "up" || props.breadcrumbAction === "back"
      ? () =>
          props.breadcrumbAction === "back" ? navigate(-1) : navigate("../")
      : props.breadcrumbAction;

  const crumb = (
    <Layout.Section>
      <Button icon={ArrowLeftIcon} onClick={() => (action ? action() : null)} />
    </Layout.Section>
  );

  const crumbDefault = "bottom";

  return (
    <Page>
      <Layout sectioned>
        {["top", "both"].includes(props.breadcrumbs || crumbDefault) && action
          ? crumb
          : null}
        <Layout.Section>{props.children}</Layout.Section>
        {["bottom", "both"].includes(props.breadcrumbs || crumbDefault) &&
        action
          ? crumb
          : null}
      </Layout>
    </Page>
  );
}
