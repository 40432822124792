import { useMutation, useQueryClient } from "react-query";
import {
  performBulkRMAUpdate,
  useDeleteRecord,
  useRecord,
  useRMAEvents,
  useRMAMedia,
  useUpdateRecord,
} from "../api/genericEndpoints";
import { axiosInstance, baseAxiosConfig } from "../api/shared";
import {
  BirthRecord,
  birthRecordEventSchemaList,
  BirthRecordInput,
  birthRecordSchema,
} from "../schemas/birthRecord";

const endpoint = "birth-records";

export function useBirthRecord(accessToken: string, uuid?: string) {
  return useRecord<BirthRecord>(endpoint, birthRecordSchema, accessToken, uuid);
}

export function useUpdateBirthRecord() {
  return useUpdateRecord<BirthRecordInput>(endpoint);
}

export function useDeleteBirthRecord() {
  return useDeleteRecord(endpoint);
}

export function useBirthRecordEvents(accessToken: string, uuid?: string) {
  return useRMAEvents<BirthRecord>(
    endpoint,
    birthRecordEventSchemaList,
    accessToken,
    uuid,
  );
}

export function useBulkUpdateBirthRecords() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      accessToken,
      note,
      records,
    }: {
      accessToken: string;
      note?: string;
      records: BirthRecord[];
    }) => {
      return performBulkRMAUpdate(accessToken, "birth-records", records, note);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(["birthRecord"]);
      },
    },
  );
}

export function useExecAnnualRelease() {
  const queryClient = useQueryClient();

  const exec = async (accessToken: string, year: number) => {
    await axiosInstance.put(
      "/birth-records/bulk/annual-release",
      {},
      { ...baseAxiosConfig(accessToken), params: { year } },
    );
  };

  return useMutation({
    mutationFn: ({
      accessToken,
      year,
    }: {
      accessToken: string;
      year: number;
    }) => {
      return exec(accessToken, year);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["birthRecord"] });
    },
  });
}
export function useBirthRecordImage(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
