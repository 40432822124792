import { Button, ButtonGroup, Popover } from "@shopify/polaris";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import Stack from "../../shared/Stack";
import { PText, Subheading } from "../../shared/TextComponents";
import * as utils from "../utils/shared";
import { AddSearchParamBtn, ContentSensitiveClearBtn } from "./components";
import {
  FilterBooleanTermInput,
  FilterDatePartTermInput,
  FilterFieldSelect,
  FilterOperatorSelect,
  FilterSelectTermInput,
  FilterTermInput,
  processDynamicFilterTermInputs,
} from "./FilterComponents";
import SearchParamChit from "./SearchParamChit";
import * as searchutils from "./searchutils";

interface FilterParamPopoverProps extends searchutils.searchParamPopoverProps {
  filterFields: searchutils.SearchFilterFields;
  onSubmit: (f: searchutils.SearchFilterParam) => void;
}

export function FilterParamPopover(props: FilterParamPopoverProps) {
  const [selectedField, setSelectedField] = useState<string>("");
  const [operator, setOperator] =
    useState<searchutils.FilterParamOperator>("=");
  const [term, setTerm] = useState<string>();
  const [startDay, setStartDay] = useState<number>();
  const [startMonth, setStartMonth] = useState<number>();
  const [startYear, setStartYear] = useState<number>();
  const [fieldType, setFieldType] =
    useState<searchutils.FilterFieldType | null>(null);

  const close = () => {
    setSelectedField("");
    setOperator("=");
    setTerm(undefined);
    setStartDay(undefined);
    setStartMonth(undefined);
    setStartYear(undefined);
    setFieldType(null);
    props.onClose();
  };

  const submit = () => {
    const result = processDynamicFilterTermInputs(
      operator,
      selectedField,
      props.filterFields,
      term,
      startDay,
      startMonth,
      startYear,
    );
    if (result) {
      props.onSubmit(result);
    }
    close();
  };

  const onFieldSelect = (selected: string) => {
    setSelectedField(selected);
    setFieldType(props.filterFields[selected].type);
    setTerm(undefined);
    let defaultOp = props.filterFields[selected].defaultOperator;
    setOperator(defaultOp || "=");
  };

  const selector = (
    <FilterFieldSelect
      selectedField={selectedField}
      onFieldSelect={onFieldSelect}
      filterFields={props.filterFields}
    />
  );

  const operatorInput = selectedField && (
    <FilterOperatorSelect
      operator={operator}
      setOperator={setOperator}
      selectedField={selectedField}
      filterFields={props.filterFields}
      setTerm={setTerm}
    />
  );

  // General term input used for numbers and strings:
  const termInput = <FilterTermInput term={term} setTerm={setTerm} />;

  // Specialized term inputs:
  const selectInput = selectedField &&
    props.filterFields[selectedField].validTerms && (
      <FilterSelectTermInput
        label="select: "
        labelInline
        term={term}
        validTerms={props.filterFields[selectedField].validTerms}
        setTerm={setTerm}
      />
    );

  const booleanInput = selectedField && (
    <FilterBooleanTermInput term={term} setTerm={setTerm} />
  );

  const opts = fieldType && (
    <Stack>
      {["number", "date", "string"].includes(fieldType) &&
        !props.filterFields[selectedField].validTerms &&
        operatorInput}
      {["number", "string"].includes(fieldType) &&
        !props.filterFields[selectedField].validTerms &&
        !["isnull", "notnull"].includes(operator) &&
        termInput}
      {selectInput}
      {fieldType === "date" && (
        <FilterDatePartTermInput
          setDay={setStartDay}
          setMonth={setStartMonth}
          setYear={setStartYear}
          day={startDay}
          month={startMonth}
          year={startYear}
        />
      )}
      {fieldType === "boolean" && booleanInput}
    </Stack>
  );

  return (
    <>
      <Popover
        active={props.active}
        activator={props.activator}
        onClose={close}
        preferredAlignment="left"
        fullHeight
      >
        <Popover.Section>
          <Stack>
            {selector}
            {opts}
          </Stack>
        </Popover.Section>
        <Popover.Section>
          <Button onClick={submit} disabled={term || startYear ? false : true}>
            Add
          </Button>
        </Popover.Section>
      </Popover>
    </>
  );
}

interface FilterParamChitProps {
  filterFields: searchutils.SearchFilterFields;
  onRemove: () => void;
  filterParam: searchutils.FilterParamLike;
  disabled?: boolean;
  onEdit?: (f: searchutils.FilterParamLike) => void;
}

export function FilterParamChit({
  filterFields,
  onRemove,
  filterParam,
  disabled,
  onEdit,
}: FilterParamChitProps) {
  const [filter, setFilter] = useState(filterParam);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const buildFilterText = (f: searchutils.FilterParamLike): string => {
    if ("filters" in f) {
      let filterText = buildFilterText(f.filters[0]);
      return `${f.conjunction.toUpperCase()} (${filterText} ...)`;
    } else {
      return f.label;
    }
  };

  const subfilterChits =
    "filters" in filter &&
    filter.filters.map((f, idx) => (
      <FilterParamChit
        key={f.uuid}
        filterFields={filterFields}
        onRemove={() => removeSubfilter(idx)}
        filterParam={f}
        disabled={true}
      />
    ));

  const onClick = () => {
    if (onEdit) {
      setEditOpen(true);
    }
  };

  const submitEdit = (f: searchutils.FilterParamLike) => {
    if (onEdit) {
      onEdit(f);
    }
  };

  const chit = (
    <SearchParamChit
      displayText={buildFilterText(filter)}
      onRemove={onRemove}
      onClick={onClick}
      disabled={disabled}
    />
  );

  const removeSubfilter = (idx: number) => {
    if ("filters" in filter) {
      let newfilters = utils.removeItem(filter.filters, idx);
      let updatedFilter = { ...filter, filters: newfilters };
      setFilter(updatedFilter);
      submitEdit(updatedFilter);
    }
  };

  const updateConjunction = (conj: searchutils.FilterConjunct) => {
    if ("conjunction" in filter) {
      let updatedFilter = { ...filter, conjunction: conj };
      setFilter(updatedFilter);
      submitEdit(updatedFilter);
    }
  };

  const conjunctPopoverSection = "conjunction" in filter && (
    <Popover.Section>
      <Stack>
        <ButtonGroup>
          <Button
            pressed={filter.conjunction === "and"}
            size="slim"
            onClick={() => updateConjunction("and")}
          >
            AND
          </Button>
          <Button
            size="slim"
            pressed={filter.conjunction === "or"}
            onClick={() => updateConjunction("or")}
          >
            OR
          </Button>
          <Button
            size="slim"
            pressed={filter.conjunction === "not"}
            onClick={() => updateConjunction("not")}
          >
            NOT
          </Button>
        </ButtonGroup>
        <Subheading>Filters</Subheading>
        {subfilterChits}
      </Stack>
    </Popover.Section>
  );

  const addSubfilter = (f?: searchutils.FilterParamLike) => {
    if (f) {
      let updatedFilter = { ...filter };
      if ("filters" in filter) {
        let newfilters = [...filter.filters, f];
        updatedFilter = { ...filter, filters: newfilters };
      } else {
        updatedFilter = {
          uuid: uuid(),
          conjunction: "or",
          filters: [filter, f],
        };
      }
      setFilter(updatedFilter);
      submitEdit(updatedFilter);
    }
    setEditOpen(true);
  };

  const editPopover = (
    <Popover
      sectioned
      active={editOpen}
      activator={chit}
      preferredAlignment="left"
      onClose={() => setEditOpen(false)}
      fullHeight
    >
      {conjunctPopoverSection}
      <Popover.Section>
        <Button
          onClick={() => {
            setEditOpen(false);
            setAddOpen(true);
          }}
        >
          Add Subfilter
        </Button>
      </Popover.Section>
    </Popover>
  );

  const addPopover = (
    <FilterParamPopover
      activator={chit}
      active={addOpen}
      filterFields={filterFields}
      onSubmit={addSubfilter}
      onClose={() => {
        setAddOpen(false);
        setEditOpen(true);
      }}
    />
  );

  return editOpen ? editPopover : addPopover;
}

interface FilterParamDisplayProps {
  filters: searchutils.FilterParamLike[];
  setFilters: React.Dispatch<
    React.SetStateAction<searchutils.FilterParamLike[]>
  >;
  filterFields: searchutils.SearchFilterFields;
  heading?: string;
  disallowConjuncts?: boolean;
  disallowEdit?: boolean;
}

export default function FilterParamDisplay({
  filters,
  setFilters,
  filterFields,
  heading,
  disallowConjuncts,
  disallowEdit,
}: FilterParamDisplayProps) {
  const [popoverActive, setPopoverActive] = useState(false);

  const toggleFilterPopover = () => setPopoverActive((prev) => !prev);

  const addFilterBtn = <AddSearchParamBtn onClick={toggleFilterPopover} />;

  const filterPopover = (
    <FilterParamPopover
      activator={addFilterBtn}
      active={popoverActive}
      onClose={toggleFilterPopover}
      onSubmit={(f) => {
        setFilters((prev) => [...prev, f]);
      }}
      filterFields={filterFields}
    />
  );

  return (
    <Stack direction="row" align="center">
      <PText weight="bold">{heading || "Filtering"}</PText>
      {filters.map((f, idx) => (
        <FilterParamChit
          key={f.uuid}
          filterParam={f}
          filterFields={filterFields}
          onEdit={
            disallowEdit
              ? undefined
              : (f) => {
                  let newFilters = [...filters];
                  newFilters[idx] = f;
                  setFilters(newFilters);
                }
          }
          onRemove={() => setFilters(utils.removeItem(filters, idx))}
        />
      ))}
      {filterPopover}
      <ContentSensitiveClearBtn
        content={filters}
        onClick={() => setFilters([])}
      />
    </Stack>
  );
}
