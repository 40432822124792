import { Banner } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import Card from "../../shared/Card";

interface VerificationBannerProps {
  loading: boolean;
  onAction: () => Promise<void>;
}

export default function VerificationBanner(props: VerificationBannerProps) {
  return (
    <Card>
      <Banner
        title="Please Verify Email"
        tone="warning"
        action={{
          loading: props.loading,
          content: "Verify Email",
          onAction: props.onAction,
        }}
      >
        <PText>
          Your email address is not verified. Please follow the instructions in
          the welcome email or request a new verification email by clicking the
          button below. Once you have verified your email, refresh this page and
          a {`\u2713`} will appear beside your name on the right.
        </PText>
      </Banner>
    </Card>
  );
}
