import { Banner, Layout, Page } from "@shopify/polaris";
import { FallbackProps } from "react-error-boundary";
import { PText } from "./shared/TextComponents";
import Card from "./shared/Card";
import Stack from "./shared/Stack";

interface GeneralErrorProps {
  error: Error;
}

export function GeneralError(props: GeneralErrorProps) {
  return (
    <Stack>
      <Banner title="Something went wrong!" tone="critical" />
      <Card>
        <Stack>
          <PText>
            Try refreshing the page. If that doesn't work, submit a ticket to
            the Business Applications Team for troubleshooting.
          </PText>
          <PText>
            If you submit a ticket, describe what you were doing when the error
            occurred and send the following error message:
          </PText>
          <PText>{props.error.message}</PText>
        </Stack>
      </Card>
    </Stack>
  );
}

interface NotAuthorizedProps {
  customMsg?: JSX.Element;
}

export const NotAuthorized = (props: NotAuthorizedProps) => (
  <Stack>
    <Banner title="Not Authorized" tone="warning" />
    <Card>
      {props.customMsg || (
        <PText>Sorry! You're not authorized to view this.</PText>
      )}
    </Card>
  </Stack>
);

const FallbackError = ({ error }: FallbackProps) => {
  const generalError = <GeneralError error={error} />;

  const forbiddenError = error.message ===
    "Request failed with status code 403" && <NotAuthorized />;

  return (
    <div role="alert">
      <Page fullWidth>
        <Layout sectioned>{forbiddenError || generalError}</Layout>
      </Page>
    </div>
  );
};

export default FallbackError;
