import { TopBar as PolarisTopBar } from "@shopify/polaris";
import { useState } from "react";

import { CurrentUserData } from "./schemas/core";
import { removeNulls } from "./utils/shared";

interface TopBarProps {
  user?: CurrentUserData | null;
  handleSignout: () => void;
  userProfileAction?: () => void;
}

export default function TopBar(props: TopBarProps) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const userMenuActions = removeNulls([
    props.userProfileAction
      ? {
          items: [
            {
              content: "User Profile",
              onAction: props.userProfileAction,
            },
          ],
        }
      : null,
    { items: [{ content: "Sign Out", onAction: props.handleSignout }] },
  ]);

  const userMenu = props.user && (
    <PolarisTopBar.UserMenu
      name={`${props.user.user.name} ${
        props.user.emailVerified ? `\u2713` : ``
      }`}
      initials={props.user.user.name[0].toUpperCase()}
      open={userMenuOpen}
      onToggle={() => setUserMenuOpen((current) => !current)}
      actions={userMenuActions}
    />
  );

  return <PolarisTopBar userMenu={userMenu} />;
}
