import { useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { useAppBridge } from "@shopify/app-bridge-react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  Icon,
  TextField,
  ResourceList,
  ResourceItem,
  Collapsible,
  Avatar,
  Badge,
  Banner,
  Spinner,
  DataTable,
  Link,
  Pagination,
  Divider,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { API_URL } from "../env";
import { PText } from "../shared/TextComponents";
import Card from "../shared/Card";
import Stack from "../shared/Stack";

interface Membership {
  id: number;
  status: { description: string };
  inception_date_time: string;
  expiration_date_time: string;
  membership_level: { description: string };
}

type Constituent = {
  id: string;
  display_name: string;
  city: string;
  state: string;
  postal_code: number;
  constituent_type: string;
  memberships: Membership[];
  membership_status: string;
  household?: Constituent;
  affiliates?:
    | Record<number, Constituent>
    | {
        individual_constituent_id: number;
        individual_constituent_name: string;
      }[];
};

function MembershipDataTable({ memberships }: { memberships: Membership[] }) {
  return (
    <DataTable
      columnContentTypes={["text", "text", "text", "text"]}
      headings={[
        "Membership Level",
        "Status",
        "Inception Date",
        "Expiration Date",
      ]}
      rows={memberships.map((m) => [
        m.membership_level.description,
        m.status.description === "Active" ? (
          <Badge tone="success">Active</Badge>
        ) : (
          m.status.description
        ),
        new Date(m.inception_date_time).toLocaleDateString(),
        new Date(m.expiration_date_time).toLocaleDateString(),
      ])}
    />
  );
}

interface ConstituentResourceItemProps extends Constituent {
  isSelected: boolean;
  onSelect: () => void;
  onClickHousehold: (constituent_id: string) => void;
}

function ConstituentResourceItem({
  id,
  display_name,
  city,
  state,
  postal_code,
  household,
  affiliates,
  memberships,
  membership_status,
  isSelected,
  onSelect,
  onClickHousehold,
}: ConstituentResourceItemProps) {
  return (
    <ResourceItem
      id={id}
      media={<Avatar customer size="md" name={display_name} />}
      accessibilityLabel={`Constituent: ${display_name}`}
      onClick={onSelect}
    >
      <Stack>
        <PText weight="bold">{display_name}</PText>
        <Stack direction="row" justify="space-between">
          <PText>
            <strong>Constituent: </strong>
            {id}
          </PText>
          <Stack direction="row">
            <PText>
              {city}, {state} {postal_code}
            </PText>
            <Badge
              tone={
                ["Active", "Active household"].includes(membership_status)
                  ? "success"
                  : "critical"
              }
            >
              {membership_status}
            </Badge>
          </Stack>
        </Stack>
        <Collapsible id={id} open={isSelected}>
          <Stack>
            {affiliates && (
              <div>
                <strong>Affiliates: </strong>
                {Object.values(affiliates)
                  .map((a) => a.display_name || a.individual_constituent_name)
                  .join(", ")}
              </div>
            )}
            {household && (
              <div>
                <strong>Household: </strong>
                <Link onClick={() => onClickHousehold(household.id)}>
                  {household.display_name}{" "}
                  {membership_status === "Active household" && "(Active)"}
                </Link>
              </div>
            )}
            {memberships.length > 0 ? (
              <MembershipDataTable memberships={memberships} />
            ) : (
              <Banner>
                No membership history on this account.
                {household && (
                  <>
                    {" "}
                    <Link onClick={() => onClickHousehold(household.id)}>
                      Check household
                    </Link>
                  </>
                )}
              </Banner>
            )}
          </Stack>
        </Collapsible>
      </Stack>
    </ResourceItem>
  );
}

export function MemberSearch({ shop }: { shop: string | null }) {
  // TODO: Turn these into params when the search is factored out
  const searchRequestTimeout = 20e3; // ms
  const searchFrequencyMax = 300; // ms
  const tokenExpiredRedirectTime = 3000; // ms

  const app = useAppBridge();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [searchInputTimeout, setSearchInputTimeout] =
    useState<null | ReturnType<typeof setTimeout>>(null);
  const [requestCancelTokenSource, setRequestCancelTokenSource] =
    useState<CancelTokenSource | null>(null);
  const [searchResults, setSearchResults] = useState<Constituent[]>([]);
  const [hitCount, setHitCount] = useState(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(false);

  const [constituentIsSelected, setConstituentIsSelected] = useState<
    Record<string, boolean>
  >({});

  if (tokenExpired) {
    setTimeout(
      () => window.location.replace(API_URL + `?shop=${shop}`),
      tokenExpiredRedirectTime,
    );
  }

  const updateSearch = (searchInput: string, page: number) => {
    const prevSearchQuery = searchQuery?.trim();
    setSearchQuery(searchInput);

    searchInput = searchInput.trim();

    if (searchInput === prevSearchQuery && page === 1) {
      return;
    }

    setIsLoading(true);
    setError(false);

    // If there's an existing search scheduled, unschedule it since the user
    // wasn't finished typing when it was scheduled
    if (searchInputTimeout) {
      clearTimeout(searchInputTimeout);
      requestCancelTokenSource?.cancel();
    }

    // Otherwise schedule the new search to fire after `searchFrequencyMax` ms.
    // If it's not cleared by more typing, it will send the query to the server.
    setSearchInputTimeout(
      setTimeout(() => {
        if (searchInput.length < 3) {
          setIsLoading(false);
          setSearchResults([]);
          return;
        }

        // Get a session token from Shopify
        getSessionToken(app)
          .then((token) => {
            const source = axios.CancelToken.source();
            setRequestCancelTokenSource(source);
            // Send the query
            axios
              // FIXME
              .get(API_URL + "/shopify/members", {
                headers: { Authorization: `Bearer ${token}` },
                params: { query: searchInput, page_num: page },
                timeout: searchRequestTimeout,
                cancelToken: source.token,
              })
              .then((response) => {
                const {
                  constituents,
                  hit_count,
                  page_num,
                  has_prev,
                  has_next,
                } = response.data;

                setPageNum(page_num);
                setHasPrev(has_prev);
                setHasNext(has_next);

                constituents.forEach((c: any) => {
                  c.id = c.id.toString();
                  if (c.household) {
                    c.household.id = c.household.id.toString();
                  }
                  c.affiliates &&
                    Object.values(c.affiliates).forEach((a: any) => {
                      if (a.id) {
                        a.id = a.id.toString();
                      }
                    });
                });

                // Set and cache the search results
                setSearchResults(constituents);
                setHitCount(hit_count);
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
                if (axios.isCancel(error)) {
                } else if (error.response?.status === 401) {
                  setTokenExpired(true);
                  setIsLoading(false);
                } else {
                  setError(true);
                  setIsLoading(false);
                }
              });
          })
          .catch((e) => {
            setTokenExpired(true);
            setIsLoading(false);
          });
      }, searchFrequencyMax),
    );
  };

  // NOTE: This empty result handling is done manually since there's a spinner
  // bug in ResourceList
  // TODO: Clean this up
  let resultsList = <></>;
  if (!searchQuery || searchQuery.trim() === "") {
    resultsList = (
      <div style={{ marginTop: "2em" }}>
        <p>Enter a search query.</p>
      </div>
    );
  } else if (searchQuery.trim().length < 3) {
    resultsList = (
      <div style={{ marginTop: "2em" }}>
        <p>Your search query must have at least 3 letters.</p>
      </div>
    );
  } else if (searchResults.length > 0 || pageNum > 1) {
    resultsList = (
      <>
        <Pagination
          hasPrevious={hasPrev}
          hasNext={hasNext}
          onPrevious={() => updateSearch(searchQuery, pageNum - 1)}
          onNext={() => updateSearch(searchQuery, pageNum + 1)}
          label={"Page " + pageNum}
        />
        <ResourceList
          showHeader
          resourceName={{
            singular: "constituent",
            plural: "constituents (rough estimate)",
          }}
          items={searchResults}
          renderItem={(item) =>
            ConstituentResourceItem({
              ...item,
              onSelect: () =>
                setConstituentIsSelected({
                  ...constituentIsSelected,
                  [item.id]: !constituentIsSelected[item.id],
                }),
              isSelected: constituentIsSelected[item.id],
              onClickHousehold: (constituent_id: string) =>
                updateSearch(constituent_id, 1),
            })
          }
          totalItemsCount={hitCount}
          loading={isLoading}
        />
      </>
    );
  } else if (isLoading) {
    resultsList = (
      <div style={{ marginTop: "2em" }}>
        <Stack>
          <Spinner />
        </Stack>
      </div>
    );
  } else if (pageNum === 1) {
    resultsList = (
      <div style={{ marginTop: "2em" }}>
        <p>Your search did not match any constituents.</p>
      </div>
    );
  }

  return (
    <Card>
      <Stack>
        <p>
          Search MNHS members by name or by constituent ID. Click on the search
          results to see affiliations and membership history.
        </p>
        <div style={{ marginTop: "1em" }}>
          <Banner tone="info">
            If you're using Shopify POS, then to get back to the Sales screen,
            select <strong>Close</strong> in the upper left.
          </Banner>
        </div>
        {error && (
          <Stack>
            <Banner title="Something went wrong!" tone="critical">
              <p>
                {/* TODO: Make this message one of the configurable messages a la 
                the emails. */}
                Please try again in a few minutes. If the problem persists,
                submit a help desk ticket and direct it to the Business
                Applications Team.
              </p>
            </Banner>
            <Divider />
          </Stack>
        )}
        {tokenExpired ? (
          <Banner title="Sorry! We need to renew our Shopify credentials. One moment...">
            <Stack>
              <Spinner />
            </Stack>
          </Banner>
        ) : (
          <Stack>
            <TextField
              label="Search by name or by Constituent ID:"
              prefix={<Icon source={SearchIcon} tone="base" />}
              value={searchQuery || ""}
              onChange={(searchInput) => updateSearch(searchInput, 1)}
              autoComplete="off"
            />
            {resultsList}
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
