import { Button, TextField } from "@shopify/polaris";
import { useState } from "react";
import { FreeformDates } from "./searchutils";
import { PText } from "../../shared/TextComponents";
import Stack from "../../shared/Stack";

interface FreeformDateRangeProps {
  group: string;
  initialValues?: FreeformDates;
  onSubmit: (group: string, ranges: FreeformDates) => void;
  onClear: (group: string) => void;
}

export default function FreeformDateRange(props: FreeformDateRangeProps) {
  const [startDay, setStartDay] = useState<number | undefined>(
    props.initialValues?.startDay
  );
  const [startMonth, setStartMonth] = useState<number | undefined>(
    props.initialValues?.startMonth
  );
  const [startYear, setStartYear] = useState<number | undefined>(
    props.initialValues?.startYear
  );
  const [endDay, setEndDay] = useState<number | undefined>(
    props.initialValues?.endDay
  );
  const [endMonth, setEndMonth] = useState<number | undefined>(
    props.initialValues?.endMonth
  );
  const [endYear, setEndYear] = useState<number | undefined>(
    props.initialValues?.endYear
  );

  const submit = () => {
    const result = {
      startDay: startDay,
      endDay: endDay,
      startMonth: startMonth,
      endMonth: endMonth,
      startYear: startYear,
      endYear: endYear,
    };
    props.onSubmit(props.group, result);
  };

  const clear = () => {
    setStartDay(undefined);
    setStartMonth(undefined);
    setStartYear(undefined);
    setEndDay(undefined);
    setEndMonth(undefined);
    setEndYear(undefined);
    props.onClear(props.group);
  };

  const parseInput = (value: string) =>
    value === "" ? undefined : parseInt(value);

  const dateInput = (
    setDay: (value: number | undefined) => void,
    setMonth: (value: number | undefined) => void,
    setYear: (value: number | undefined) => void,
    day?: number,
    month?: number,
    year?: number,
    rangeLabel: "Start" | "End" = "Start"
  ) => (
    <Stack direction="row">
      <TextField
        type="number"
        min={1}
        max={31}
        value={day?.toString()}
        label={`${rangeLabel} Day`}
        onChange={(value) => setDay(parseInput(value))}
        autoComplete="off"
      />
      <TextField
        type="number"
        min={1}
        max={12}
        value={month?.toString()}
        label={`${rangeLabel} Month`}
        onChange={(value) => setMonth(parseInput(value))}
        autoComplete="off"
      />
      <TextField
        type="number"
        min={1}
        max={9999}
        value={year?.toString()}
        label={`${rangeLabel} Year`}
        onChange={(value) => setYear(parseInput(value))}
        autoComplete="off"
      />
    </Stack>
  );

  return (
    <Stack>
      <PText weight="bold">{props.group}</PText>
      <Stack direction="row">
        {dateInput(
          setStartDay,
          setStartMonth,
          setStartYear,
          startDay,
          startMonth,
          startYear
        )}
        {dateInput(
          setEndDay,
          setEndMonth,
          setEndYear,
          endDay,
          endMonth,
          endYear,
          "End"
        )}
      </Stack>
      <Stack direction="row">
        <Button onClick={submit} variant="primary" tone="success">
          Apply
        </Button>
        <Button onClick={clear}>Clear</Button>
      </Stack>
    </Stack>
  );
}
