import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import { censusFilters } from "../schemas/census";
import { censusSorts } from "../schemas/census";
import { censusLabels } from "../schemas/census";
import { CensusRecord } from "../schemas/census";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { CensusDetail } from "./CensusDetail";
import CensusForm from "./CensusForm";
import { getVitalRecordsDisplayName } from "./shared";

export function renderCensusHit(record: CensusRecord) {
  const displayName = getVitalRecordsDisplayName(record);

  const { censusDateStart, county } = record;

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        {censusDateStart && new Date(censusDateStart).toLocaleDateString()}{" "}
        {county}
      </PText>
    </>
  );
}

export default function CensusSearchPage(
  props: BaseSearchPageComponentProps<CensusRecord>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={censusLabels}
          renderSearchHit={{
            render: renderCensusHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            CensusDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            CensusForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={censusSorts}
          filterFields={censusFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<CensusRecord>(props, "census")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "census")
          }
          recordName="Census Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_remove_census_records"
          )}
        />
      </Layout>
    </Page>
  );
}
