import { useParams } from "react-router-dom";
import { useFindingAid } from "../hooks/findAidHooks";
import { CurrentUserData } from "../schemas/core";
import { FindingAid } from "../schemas/findAid";
import Skeleton from "../Skeleton";

interface FindAidPreviewProps {
  record?: FindingAid | null;
}
export default function FindAidPreview(props: FindAidPreviewProps) {
  const skeleton = !props.record && <Skeleton lines={20} />;

  const preview = props.record && (
    <div dangerouslySetInnerHTML={{ __html: props.record.content }} />
  );

  return <>{skeleton || preview}</>;
}

interface FindAidPreviewPageProps {
  currentUserData: CurrentUserData;
}

export function FindAidPreviewPage(props: FindAidPreviewPageProps) {
  const { uuid } = useParams();

  const { data: findingAid } = useFindingAid(
    props.currentUserData.accessToken,
    uuid
  );

  return <FindAidPreview record={findingAid} />;
}
