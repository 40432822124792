import {
  addRMAMedia,
  useDeleteRecord,
  useRecord,
  useRMAEvents,
  useRMAMedia,
  useUpdateRecord,
} from "../api/genericEndpoints";
import { APIError } from "../api/shared";
import {
  wwiBonusEventSchemaList,
  WWIBonusRecord,
  WWIBonusRecordInput,
  wwiBonusSchema,
} from "../schemas/wwiBonus";

const endpoint = "wwi-bonuses";

export function useWWIBonusRecord(accessToken: string, uuid?: string) {
  return useRecord<WWIBonusRecord>(endpoint, wwiBonusSchema, accessToken, uuid);
}

export function useUpdateWWIBonusRecord() {
  return useUpdateRecord<WWIBonusRecordInput>(endpoint);
}

export function useDeleteWWIBonusRecord() {
  return useDeleteRecord(endpoint);
}

export function useWWIBonusEvents(accessToken: string, uuid?: string) {
  return useRMAEvents<WWIBonusRecord>(
    endpoint,
    wwiBonusEventSchemaList,
    accessToken,
    uuid,
  );
}

export async function addWWIBonusPDF(
  accessToken: string,
  pdfFile: File,
): Promise<string | APIError> {
  const result = await addRMAMedia(accessToken, pdfFile, endpoint);
  return result;
}

export function useWWIBonusPDF(accessToken: string, hash?: string | null) {
  return useRMAMedia(accessToken, endpoint, hash);
}
