import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, FormLayout, Spinner } from "@shopify/polaris";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Heading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import { FormFields } from "../Form";
import { useUser, useUpdateUser, useUpdateUserPrefs } from "../hooks/userHooks";
import {
  CurrentUserData,
  UserInput,
  userInputSchema,
  userLabels,
  UserPrefs,
} from "../schemas/core";
import UserPrefsEditor from "./UserPrefsEditor";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import Skeleton from "../Skeleton";

interface userProfilePageProps {
  currentUserData: CurrentUserData;
}

// This is separated from the UserFormPage so that we can incorporate user profile
// settings into it eventually.
export default function UserProfilePage(props: userProfilePageProps) {
  const { data: user } = useUser(
    props.currentUserData.accessToken,
    props.currentUserData.user.id
  );

  const mutation = useUpdateUser();

  const prefsMutation = useUpdateUserPrefs();

  const skeleton = !user && <Skeleton title lines={2} />;

  const prefsSkeleton = !user && <Skeleton lines={3} />;

  const onSubmit: SubmitHandler<UserInput> = async (inputData: UserInput) => {
    mutation.mutate(
      { accessToken: props.currentUserData.accessToken, inputData: inputData },
      { onSuccess: () => {} }
    );
  };

  const onPrefsSave = async (prefs: UserPrefs) => {
    prefsMutation.mutate({
      accessToken: props.currentUserData.accessToken,
      prefs: prefs,
    });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<UserInput>({
    resolver: yupResolver(userInputSchema),
  });

  // Ensures user data is always loaded into the defaults even if the form renders
  // first.
  useEffect(() => {
    if (!user) {
      return;
    }
    reset(userInputSchema.cast(user));
  }, [user, reset]);

  const detail = user && (
    <Stack>
      <Heading>{user.name}</Heading>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout>
          <FormFields
            control={control}
            spec={{
              name: { type: "text", required: true },
              email: { type: "text", required: true },
            }}
            labels={userLabels}
            errors={{ name: errors.name, email: errors.email }}
            reset={reset}
            getValues={getValues}
          />
          <Stack direction="row" justify="flex-end">
            <Button
              submit
              tone="success"
              variant="primary"
              disabled={mutation.isLoading}
            >
              Update
            </Button>
            {mutation.isLoading && <Spinner size="small" />}
          </Stack>
        </FormLayout>
      </Form>
    </Stack>
  );

  const generalPrefs = user && <Stack></Stack>;

  const userPrefsTabs = user && (
    <UserPrefsEditor
      currentUserData={props.currentUserData}
      onSave={onPrefsSave}
    />
  );

  const userPrefs = user && [generalPrefs, userPrefsTabs];

  return (
    <BreadcrumbPage breadcrumbAction="back" breadcrumbs="both">
      <Stack>
        <Card>{skeleton || detail}</Card>
        <Card>
          <Stack>
            <Heading>User Preferences</Heading>
            {prefsSkeleton || userPrefs}
          </Stack>
        </Card>
      </Stack>
    </BreadcrumbPage>
  );
}
