import { Button, Divider, Icon, Layout, Page, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import * as api from "../api/endpoints";
import { APIError, Error } from "../api/shared";
import FileDrop, { FileDropResult } from "../utils/FileDrop";
import { CurrentUserData } from "../schemas/core";
import { FindingAidInput } from "../schemas/findAid";
import { FindingAid } from "../schemas/findAid";
import { DefaultPageProps } from "../utils/shared";
import { AlertCircleIcon, StatusActiveIcon } from "@shopify/polaris-icons";
import { useFindingAid, useUpdateFindingAid } from "../hooks/findAidHooks";
import { useNavigate, useParams } from "react-router-dom";
import { Heading, PText } from "../../shared/TextComponents";
import Skeleton from "../Skeleton";
import BreadcrumbPage from "../BreadcrumbPage";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";

interface FindAidFormProps {
  currentUserData: CurrentUserData;
  findaid?: FindingAid;
}

export default function FindAidForm(props: FindAidFormProps) {
  const [findaid] = useState<FindingAid | undefined>(props.findaid);
  const [newFindaid, setNewFindaid] = useState<FindingAidInput | undefined>(
    props.findaid
  );
  const [mediaMap, setMediaMap] = useState<Record<string, string>>({});
  const [sha256Map, setSHA256Map] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const mutation = useUpdateFindingAid();

  const addMediaMap = (filename: string, hash: string) => {
    setMediaMap((previous) => ({ ...previous, [filename]: hash }));
  };

  useEffect(() => {
    if (!newFindaid || Object.entries(mediaMap).length === 0) {
      return;
    }
    setNewFindaid((previous) => {
      if (previous) {
        return {
          ...previous,
          associatedMedia: previous.associatedMedia.map((assoc) => ({
            ...assoc,
            hash: mediaMap[assoc.filename],
          })),
        };
      }
    });
  }, [newFindaid, setNewFindaid, mediaMap]);

  const getOldMedia = (): Record<
    string,
    { filepath: string; filename: string; hash: string }
  > => {
    if (findaid) {
      return findaid.associatedMedia.reduce(
        (prev, am) => ({ ...prev, [am.filepath]: am }),
        {}
      );
    }
    return {};
  };

  const updateFindaid = (inputData: FindingAidInput) => {
    const uuid = findaid ? findaid.id : inputData.id;
    let newMedia = inputData.associatedMedia;
    if (findaid) {
      const oldMedia = getOldMedia();
      newMedia = inputData.associatedMedia.map((am) => {
        if (Object.keys(oldMedia).includes(am.filepath)) {
          am.hash = oldMedia[am.filepath].hash;
        }
        return am;
      });
    }
    setNewFindaid({ ...inputData, id: uuid, associatedMedia: newMedia });
  };

  // <T,> is required in react for arrow funcs to indicate that its a generic
  // type not a tag:
  const handleResp = <T,>(
    resp: T | APIError
  ): { result: T | null; error: string | null } => {
    if (resp instanceof Error) {
      return {
        result: null,
        error: resp.message,
      };
    } else {
      return {
        result: resp,
        error: null,
      };
    }
  };

  const handleUpload = async (file: File) => {
    var result: FileDropResult = { serverSHA256: null, error: null };
    if (file.type === "text/xml") {
      const resp = await api.uploadFindingAid(
        props.currentUserData.accessToken,
        file
      );
      const handledResp = handleResp(resp);
      if (handledResp.result !== null) {
        result.serverSHA256 = handledResp.result.findaidHash;
        updateFindaid(handledResp.result);
      }
      result.error = handledResp.error;
    } else {
      const resp = await api.uploadFindingAidMedia(
        props.currentUserData.accessToken,
        file.type,
        file
      );
      const handledResp = handleResp(resp);
      if (handledResp.result !== null) {
        addMediaMap(file.name, handledResp.result);
        result.serverSHA256 = handledResp.result;
      }
      result.error = handledResp.error;
    }
    return result;
  };

  const submit = async (inputData: FindingAidInput) => {
    setLoading(true);
    mutation.mutate(
      {
        accessToken: props.currentUserData.accessToken,
        inputData: inputData,
      },
      {
        onSuccess: () => {
          // Arguably not necessary since we immediately navigate away from the page...
          setLoading(false);
          navigate("./preview");
        },
        onError: () => setLoading(false),
      }
    );
  };

  const uploadedDetails = newFindaid && (
    <Stack>
      <Heading>{newFindaid.title}</Heading>
      <PText>
        <strong>Created: </strong>
        {new Date(newFindaid.createDt).toLocaleString()}
        {` \u25CF `}
        <strong>Last Update: </strong>
        {new Date(newFindaid.lastUpdateDt).toLocaleString()}
      </PText>
      {findaid && findaid.findaidHash !== newFindaid.findaidHash ? (
        <Stack direction="row">
          <PText tone="subdued">Existing Finding Aid will be overwritten</PText>
          <Icon source={AlertCircleIcon} tone="warning" />
        </Stack>
      ) : null}
      {newFindaid.associatedMedia.map((assoc) => {
        const hash = assoc.hash;
        const oldMedia = getOldMedia();
        const hashmatch =
          Object.entries(mediaMap).length === 0 ||
          (oldMedia[assoc.filepath] &&
            (hash === oldMedia[assoc.filepath].hash ||
              !oldMedia[assoc.filepath].hash));
        return (
          <Stack key={assoc.filepath} direction="row">
            <PText>{assoc.filepath}</PText>
            <Icon
              source={hash ? StatusActiveIcon : AlertCircleIcon}
              tone={hash ? (hashmatch ? "primary" : "warning") : "critical"}
              accessibilityLabel={
                hash
                  ? hashmatch
                    ? `Found uploaded file ${assoc.filepath}.`
                    : `Uploaded file ${assoc.filepath} will overwrite existing file.`
                  : `Expected file ${assoc.filepath} to be uploaded!`
              }
            />
          </Stack>
        );
      })}
    </Stack>
  );

  return (
    <Stack>
      <FileDrop
        allowMultiple
        onDrop={async (files) => {
          setLoading(true);
          const results: Record<string, FileDropResult> = {};
          for (const sha256 in files) {
            const result = await handleUpload(files[sha256]);
            const serverSHA256 = result.serverSHA256;
            if (serverSHA256) {
              // There are slight differences between how JS handles
              // sha256 hashing of some files and how python (the api)
              // handles sha256, so unfortunately we can't expect local
              // hashes to match the server hashes.
              setSHA256Map((previous) => ({
                ...previous,
                [sha256]: serverSHA256,
              }));
            }
          }
          setLoading(false);
          return results;
        }}
        onRemove={(sha256) => {
          if (newFindaid && sha256 === newFindaid.findaidHash) {
            setNewFindaid(findaid);
          } else {
            const inverse: Record<string, string> = Object.entries(
              mediaMap
            ).reduce(
              (prev, [k, v]) => ({
                ...prev,
                [v]: k,
              }),
              {}
            );
            let clone = { ...mediaMap };
            const serverSHA256 = sha256Map[sha256];
            delete clone[inverse[serverSHA256]];
            setMediaMap(clone);
          }
        }}
      />
      <Divider />
      {uploadedDetails}
      <Stack direction="row" justify="flex-end">
        {loading && <Spinner size="small" />}
        <Button
          variant="primary"
          tone="success"
          disabled={!newFindaid || loading}
          onClick={async () => (newFindaid ? await submit(newFindaid) : null)}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
}

export function FindaidAddPage(props: DefaultPageProps) {
  return (
    <Page>
      <Layout sectioned>
        <Card>
          <FindAidForm currentUserData={props.currentUserData} />
        </Card>
      </Layout>
    </Page>
  );
}

export function FindaidEditPage(props: DefaultPageProps) {
  const { uuid } = useParams();

  const { data: findaid } = useFindingAid(
    props.currentUserData.accessToken,
    uuid
  );

  const skeleton = !findaid && <Skeleton lines={3} />;

  const form = findaid && (
    <FindAidForm currentUserData={props.currentUserData} findaid={findaid} />
  );

  return (
    <BreadcrumbPage breadcrumbs="both" breadcrumbAction="back">
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
