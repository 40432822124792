import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { UseMutationResult } from "react-query";

import * as yup from "yup";

import { TableRows } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridColType,
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridRenderEditCellParams,
  GridRowId,
  GridValidRowModel,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { v4 as uuid4 } from "uuid";
import Stack from "../shared/Stack";
import { Heading } from "../shared/TextComponents";
import { CountyFormField, FormFieldType } from "./Form";
import { CurrentUserData, Labels } from "./schemas/core";
import { DefaultPageProps } from "./utils/shared";
import { PersistentSearchState } from "./search/SearchState";

export interface BulkEditPageProps<T extends object> extends DefaultPageProps {
  searchState: PersistentSearchState<T>;
}

type BulkEditGridColType = FormFieldType | "select" | "county";

interface UnsavedChanges {
  unsavedRows: Record<GridRowId, GridValidRowModel>;
  rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
}

interface SelectConfig {
  options: string[] | ReadonlyArray<string>;
  defaultValue?: string;
}

interface SelectInputProps extends SelectConfig {
  value: string;
  onChange: (value?: string) => void;
  required?: boolean;
}

function SelectInput({ options, value, onChange, required }: SelectInputProps) {
  // Will have to figure out some alternative if SelectInput can ever take
  // an arbitrarily-typed value instead of just strings. Might have to just
  // use an Autocomplete.
  const selectOpts = required ? options : ["Clear", ...options];
  const getOpt = (value: string) => {
    const idx = Number(value);
    const result = selectOpts[idx];
    return result === "Clear" ? undefined : result;
  };
  return (
    <Select
      value={selectOpts
        .findIndex((element) =>
          value ? element === value : "Clear" === element
        )
        .toString()}
      onChange={(event) => onChange(getOpt(event.target.value))}
      sx={{ minWidth: 100 }}
    >
      {selectOpts.map((opt, idx) => (
        <MenuItem key={uuid4()} value={idx}>
          {opt}
        </MenuItem>
      ))}
    </Select>
  );
}

export interface BulkEditGridColDef<T> {
  field: keyof T;
  type?: BulkEditGridColType;
  selectConfig?: SelectConfig;
  overrideDisabled?: boolean;
  required?: boolean;
}

// Using this addProperty paradigm for GridColDef creation because DataGrid treats
// passing `undefined` to certain properties in the GridColDef as overriding
// the default behavior without providing any functionality (e.g. if you pass
// `undefined` to `renderEditCell` the editing breaks entirely). `valueFormatter`
// seems to work fine if you pass `undefined` but better not to risk it if they
// change that behavior.
function addValueFormatter(def: GridColDef, type?: BulkEditGridColType) {
  if (type === "date") {
    // TODO: GridValueFormatterParams is becoming unavailable in v7. Tried to 
    //       figure out an alternative to this and it was enough of a pain that
    //       I put it off. Figure it out!
    def["valueFormatter"] = (params: GridValueFormatterParams<any>) =>
      params.value && new Date(params.value).toLocaleDateString();
  }
  return def;
}

function addRenderEditCell(
  def: GridColDef,
  apiRef: MutableRefObject<GridApiCommunity>,
  type?: BulkEditGridColType,
  selectConfig?: SelectConfig,
  required?: boolean
) {
  if (type === "select" && selectConfig) {
    def["renderEditCell"] = (params: GridRenderEditCellParams) => {
      const { id, value, field } = params;
      const handleChange = (value?: string) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value,
        });
      };
      return (
        <SelectInput
          value={value}
          onChange={handleChange}
          options={selectConfig.options}
          required={required}
        />
      );
    };
  } else if (type === "county") {
    def["renderEditCell"] = (params: GridRenderEditCellParams) => {
      const { id, value, field } = params;
      const handleChange = (value: string) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value,
        });
      };
      return (
        <CountyFormField
          label={`county_${id}`}
          labelHidden
          value={value}
          onChange={handleChange}
        />
      );
    };
  }
  return def;
}

interface BulkEditGridColumnMenuProps<T> {
  gridColMenuProps: GridColumnMenuProps;
  columnDefsByField: Record<keyof T, BulkEditGridColDef<T>>;
  unsavedChangesRef: MutableRefObject<UnsavedChanges>;
  setRecords: (records: T[]) => void;
  setHasChanges: (value: boolean) => void;
}

function BulkEditGridColumnMenu<T>(props: BulkEditGridColumnMenuProps<T>) {
  const [showOverride, setShowOverride] = useState(false);
  const [overrideValue, setOverrideValue] = useState<string>();
  const [overrideError, setOverrideError] = useState<string>();

  const {
    gridColMenuProps: menuProps,
    columnDefsByField,
    unsavedChangesRef,
    setRecords,
    setHasChanges,
  } = props;

  const def = columnDefsByField[menuProps.colDef.field as keyof T];
  const selectConfig = def.selectConfig;
  const overrideType = def.type || "text";

  const OverrideMenuItem = (itemProps: GridColumnMenuItemProps) => {
    const { overrideHandler } = itemProps;
    return (
      <MenuItem onClick={overrideHandler}>
        <ListItemIcon>
          <TableRows />
        </ListItemIcon>
        <ListItemText>Override</ListItemText>
      </MenuItem>
    );
  };

  const handleOverride = (value: string | undefined) => {
    let newRecords = Object.entries(
      unsavedChangesRef.current.rowsBeforeChange
    ).map(([id, record]) => {
      if (unsavedChangesRef.current.unsavedRows[id]) {
        record = unsavedChangesRef.current.unsavedRows[id];
      }
      const parsedValue =
        typeof value === "string" && def.type === "date"
          ? new Date(`${value}T06:00:00.000Z`)
          : value;
      let newRecord = {
        ...record,
        [menuProps.colDef.field as keyof T]: parsedValue,
      };
      unsavedChangesRef.current.unsavedRows[id] = newRecord;
      return newRecord as T;
    });
    setRecords(newRecords);
    setHasChanges(true);
  };

  const basicOverrideField = overrideType !== "county" && (
    <Stack>
      <TextField
        type={overrideType}
        label={overrideType === "text" && "Enter override value..."}
        value={overrideValue || ""}
        onChange={(event) => {
          setOverrideError(undefined);
          setOverrideValue(event.target.value);
        }}
        error={overrideError !== undefined}
        helperText={overrideError}
      />
      {!def.required && (
        <Button
          size="small"
          variant="outlined"
          onClick={(event) => {
            setOverrideValue(undefined);
            handleOverride(undefined);
            menuProps.hideMenu(event);
          }}
        >
          Clear
        </Button>
      )}
    </Stack>
  );

  const countyOverrideField = overrideType === "county" && (
    <CountyFormField
      label={`override_${menuProps.colDef.field}`}
      labelHidden
      value={overrideValue}
      onChange={(value) => {
        setOverrideError(undefined);
        setOverrideValue(value);
      }}
    />
  );

  const selectOverrrideField = overrideType === "select" && selectConfig && (
    <SelectInput
      options={selectConfig.options}
      value={overrideValue || selectConfig.defaultValue || ""}
      onChange={(value) => {
        setOverrideError(undefined);
        setOverrideValue(value);
      }}
      required={def.required}
    />
  );

  const override = (
    <Box padding={2}>
      <Stack>
        {countyOverrideField || selectOverrrideField || basicOverrideField}
        <Button
          size="small"
          variant="contained"
          onClick={(event) => {
            if (def.required && !overrideValue) {
              setOverrideError("This field is required.");
              return;
            }
            handleOverride(overrideValue);
            menuProps.hideMenu(event);
          }}
        >
          Apply
        </Button>
      </Stack>
    </Box>
  );

  const slotProps = def.overrideDisabled
    ? {}
    : {
        slots: {
          override: OverrideMenuItem,
        },
        slotProps: {
          override: {
            overrideHandler: () => {
              setShowOverride(true);
            },
          },
        },
      };

  const menu = <GridColumnMenu {...menuProps} {...slotProps} />;

  return showOverride ? override : menu;
}

interface BulkEditGridProps<T> {
  currentUserData: CurrentUserData;
  records: T[];
  columns: (keyof T | BulkEditGridColDef<T>)[];
  labels: Labels<T>;
  validationSchema: yup.Schema;
  bulkEditHook: () => UseMutationResult<
    void,
    any,
    { accessToken: string; records: T[]; note?: string },
    any
  >;
  onBulkEditSuccess: () => void;
}

export default function BulkEditGrid<T extends { id: string }>(
  props: BulkEditGridProps<T>
) {
  const [records, setRecords] = useState<T[]>(props.records);
  const [note, setNote] = useState<string>();
  const [hasChanges, setHasChanges] = useState(false);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const apiRef = useGridApiRef();

  const mutation = props.bulkEditHook();

  const bulkEditGridColDefTypeMap: Record<BulkEditGridColType, GridColType> = {
    text: "string",
    date: "date",
    number: "number",
    county: "singleSelect",
    select: "singleSelect",
  };

  const columnDefs = props.columns.map((value) => {
    if (value instanceof Object) {
      return value;
    } else {
      return { field: value } as BulkEditGridColDef<T>;
    }
  });

  const columnDefsByField = columnDefs.reduce(
    (prev, current) => ({ ...prev, [current.field]: current }),
    {} as Record<keyof T, BulkEditGridColDef<T>>
  );

  const gridColDef: GridColDef[] = columnDefs.map((def) => {
    const result: GridColDef = {
      field: def.field.toString(),
      headerName: props.labels[def.field],
      editable: true,
      filterable: false,
      type: def.type && bulkEditGridColDefTypeMap[def.type],
    };
    addRenderEditCell(result, apiRef, def.type, def.selectConfig, def.required);
    addValueFormatter(result, def.type);
    return result;
  });

  const genRecordsByID = (records: T[]) => {
    return records.reduce(
      (prev, current) => ({ ...prev, [current.id]: current }),
      {} as Record<string, T>
    );
  };

  const unsavedChangesRef = useRef<UnsavedChanges>({
    unsavedRows: {},
    rowsBeforeChange: genRecordsByID(props.records),
  });

  const BulkEditGridColMenu = (props: GridColumnMenuProps) => (
    <BulkEditGridColumnMenu
      gridColMenuProps={props}
      columnDefsByField={columnDefsByField}
      unsavedChangesRef={unsavedChangesRef}
      setRecords={setRecords}
      setHasChanges={setHasChanges}
    />
  );

  const processRowUpdate: NonNullable<DataGridProps["processRowUpdate"]> = (
    newRow,
    oldRow
  ) => {
    const rowId = newRow.id;
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    // This should be impossible, but just in case:
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasChanges(true);
    return newRow;
  };

  const resetChanges = () => {
    // This doesn't currently work because we're using the free version of DataGrid.
    // apiRef.current.updateRows(
    //   Object.values(unsavedChangesRef.current.rowsBeforeChange)
    // );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: unsavedChangesRef.current.rowsBeforeChange,
    };
    setHasChanges(false);
  };

  const commit = async () => {
    const inputList = Object.values(unsavedChangesRef.current.unsavedRows).map(
      (value) => {
        return props.validationSchema.validateSync(value);
      }
    );
    await mutation.mutate({
      accessToken: props.currentUserData.accessToken,
      note: note,
      records: inputList,
    });
    resetChanges();
    props.onBulkEditSuccess();
  };

  // This makes the grid single-click to edit, it was copy/pasted directly
  // from the MUI DataGrid recipe:
  const handleCellClick = useCallback(
    (params: GridCellParams, event: MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  return (
    <Stack align="flex-start">
      <Heading>Editing {props.records.length.toString()} records...</Heading>
      <Container disableGutters>
        <DataGrid
          apiRef={apiRef}
          columns={gridColDef}
          rows={records}
          slots={{ columnMenu: BulkEditGridColMenu }}
          processRowUpdate={processRowUpdate}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
        />
      </Container>
      <TextField
        label={"Bulk Update Note"}
        fullWidth
        onChange={(event) => setNote(event.target.value)}
      />
      <Button
        variant="contained"
        disabled={mutation.isLoading || !hasChanges}
        onClick={commit}
      >
        Commit
      </Button>
    </Stack>
  );
}
