import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Layout, Page, Spinner, Thumbnail } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import file_placeholder_text from "../../assets/file_placeholder_text.png";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  FormField,
  FormFields,
  FormSection,
} from "../Form";
import Skeleton from "../Skeleton";
import * as api from "../api/shared";
import {
  addWWIServicePDF,
  useDeleteWWIServiceRecord,
  useUpdateWWIServiceRecord,
  useWWIServiceRecord,
} from "../hooks/wwiServiceHooks";
import {
  wwiServiceInputSchema,
  wwiServiceLabels,
  WWIServiceRecord,
  WWIServiceRecordInput,
} from "../schemas/wwiService";
import FileDrop, { FileDropResult } from "../utils/FileDrop";
import { DefaultPageProps } from "../utils/shared";

export default function WWIServiceForm(
  props: BaseFormCompProps<WWIServiceRecord>,
) {
  const wwiServiceRecord = props.record;

  const [pdfLoading, setPDFLoading] = useState(false);
  const [pdf, setPDF] = useState<string | null>();
  const [pdfFilename, setPDFFilename] = useState<string | null>();
  const [lat, setLat] = useState<string | undefined>(
    wwiServiceRecord?.residenceCoordinates?.lat.toString(),
  );
  const [lon, setLon] = useState<string | undefined>(
    wwiServiceRecord?.residenceCoordinates?.lon.toString(),
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<WWIServiceRecordInput>({
    resolver: yupResolver(wwiServiceInputSchema),
  });

  useEffect(() => {
    if (!wwiServiceRecord) {
      return;
    }
    if (wwiServiceRecord.residenceCoordinates) {
      setLat(wwiServiceRecord.residenceCoordinates.lat.toString());
      setLon(wwiServiceRecord.residenceCoordinates.lon.toString());
    }
    setPDF(wwiServiceRecord.pdfSHA256);
    reset({
      ...wwiServiceInputSchema.cast(wwiServiceRecord),
    });
  }, [setPDF, wwiServiceRecord, reset]);

  const onSubmit = (inputData: WWIServiceRecordInput) => {
    inputData.pdfSHA256 = pdf || undefined;
    if (lat && lon) {
      inputData.residenceCoordinates = {
        lat: Number(lat),
        lon: Number(lon),
      };
    }
  };

  const pdfDrop = !pdf && (
    <FileDrop
      mimeType="application/pdf"
      onDrop={async (files) => {
        setPDFLoading(true);
        const result: Record<string, FileDropResult> = {};
        for (const sha256 in files) {
          const serverSHA256 = await addWWIServicePDF(
            props.currentUserData.accessToken,
            files[sha256],
          );
          if (serverSHA256 instanceof api.Error) {
            result[sha256] = {
              serverSHA256: null,
              error: serverSHA256.message,
            };
          } else {
            result[sha256] = {
              serverSHA256,
              error: null,
            };
            setPDF(serverSHA256);
            setPDFFilename(files[sha256].name);
          }
        }
        setPDFLoading(false);
        return result;
      }}
      onRemove={() => {}}
    />
  );

  const pdfDisplay = pdf && (
    <Stack align="flex-start">
      <Stack direction="row" align="center">
        <Thumbnail
          source={file_placeholder_text}
          alt={`Uploaded file ${pdfFilename}`}
        />
        <PText>{pdfFilename}</PText>
      </Stack>
      <Button
        tone="critical"
        variant="primary"
        onClick={() => {
          setPDF(null);
          setPDFFilename(null);
        }}
      >
        Remove PDF
      </Button>
    </Stack>
  );

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={wwiServiceInputSchema}
      initialValues={wwiServiceRecord}
      mutateHook={useUpdateWWIServiceRecord}
      deleteHook={useDeleteWWIServiceRecord}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <Subheading>Associated PDF</Subheading>
      {pdfLoading ? <Spinner /> : pdfDrop || pdfDisplay}
      <FormFields
        control={control}
        spec={{
          prefix: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text", required: true },
          suffix: { type: "text" },
          birthDateStart: { type: "date" },
          birthDateEnd: { type: "date" },
          locatorNumber: { type: "text", required: true },
          notes: { type: "text", lines: 6 },
          residencePriorToService: { type: "text" },
        }}
        labels={wwiServiceLabels}
        errors={{
          familyName: errors.familyName,
          locatorNumber: errors.locatorNumber,
        }}
        reset={reset}
        getValues={getValues}
      />
      <Subheading>Residence Prior to Service Coordinates</Subheading>
      <Stack direction="row">
        <FormField
          type="number"
          label="Latitude"
          value={lat}
          onChange={(value) => setLat(value)}
        />
        <FormField
          type="number"
          label="Longitude"
          value={lon}
          onChange={(value) => setLon(value)}
        />
      </Stack>
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
    </FormSection>
  );
}

export function WWIServiceAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <WWIServiceForm
              currentUserData={props.currentUserData}
              onSubmitSuccess={(id) => navigate(`../${id}`)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export function WWIServiceEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: wwiServiceRecord } = useWWIServiceRecord(
    props.currentUserData.accessToken,
    uuid,
  );

  const skeleton = !wwiServiceRecord && <Skeleton lines={15} />;

  const form = wwiServiceRecord && (
    <WWIServiceForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={wwiServiceRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
