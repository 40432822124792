import { Layout, Page } from "@shopify/polaris";
import { PText } from "../../shared/TextComponents";
import { getCount, searchRecords } from "../api/genericEndpoints";
import { hasPermissions } from "../auth/authutils";
import { vetsGraveFilters } from "../schemas/vetsGrave";
import { vetsGraveSorts } from "../schemas/vetsGrave";
import { vetsGraveLabels } from "../schemas/vetsGrave";
import { VetsGraveRecord } from "../schemas/vetsGrave";
import Search, { BaseSearchPageComponentProps } from "../Search";
import { SearchQueryProps } from "../search/searchutils";
import { VetsGraveDetail } from "./VetsGraveDetail";
import VetsGraveForm from "./VetsGraveForm";
import { getVitalRecordsDisplayName } from "./shared";

function renderVetsGraveHit(record: VetsGraveRecord) {
  const displayName = getVitalRecordsDisplayName(record);
  const { deathDateStart, burialLocation } = record;

  return (
    <>
      <PText weight="bold">{displayName}</PText>
      <PText>
        {deathDateStart && new Date(deathDateStart).toLocaleDateString()}{" "}
        {burialLocation}
      </PText>
    </>
  );
}

export default function VetsGraveSearchPage(
  props: BaseSearchPageComponentProps<VetsGraveRecord>
) {
  return (
    <Page>
      <Layout sectioned>
        <Search
          currentUserData={props.currentUserData}
          persistentState={props.persistentState}
          labels={vetsGraveLabels}
          renderSearchHit={{
            render: renderVetsGraveHit,
            hitRenderMedia: "person",
            accessibilityLabel: (record) =>
              `View details for ${getVitalRecordsDisplayName(record)}`,
          }}
          renderSearchNavDetail={(record, onEditBtnClick) =>
            VetsGraveDetail({
              currentUserData: props.currentUserData,
              record,
              onEditBtnClick,
            })
          }
          renderSearchNavForm={(record, onSubmitSuccess, onDeleteSuccess) =>
            VetsGraveForm({
              currentUserData: props.currentUserData,
              record,
              onSubmitSuccess,
              onDeleteSuccess,
            })
          }
          sortFields={vetsGraveSorts}
          filterFields={vetsGraveFilters}
          searchQuery={async (props: SearchQueryProps) =>
            searchRecords<VetsGraveRecord>(props, "vets-graves")
          }
          countQuery={async (accessToken: string) =>
            getCount(accessToken, "vets-graves")
          }
          recordName="Veterans Grave Record"
          contextualPrefs={props.contextualPrefs}
          allowEditMode={hasPermissions(
            props.currentUserData,
            "add_edit_remove_vets_grave_records"
          )}
        />
      </Layout>
    </Page>
  );
}
