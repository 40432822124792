import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ChoiceList,
  Layout,
  Page,
  Spinner,
  Thumbnail,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import file_placeholder_text from "../../assets/file_placeholder_text.png";
import Card from "../../shared/Card";
import Stack from "../../shared/Stack";
import { PText, Subheading } from "../../shared/TextComponents";
import BreadcrumbPage from "../BreadcrumbPage";
import {
  AccumulativeTextField,
  BaseFormCompProps,
  FormField,
  FormFields,
  FormSection,
} from "../Form";
import Skeleton from "../Skeleton";
import * as api from "../api/shared";
import {
  addWWIBonusPDF,
  useDeleteWWIBonusRecord,
  useUpdateWWIBonusRecord,
  useWWIBonusRecord,
} from "../hooks/wwiBonusHooks";
import {
  wwiBonusInputSchema,
  wwiBonusLabels,
  WWIBonusRecord,
  WWIBonusRecordInput,
} from "../schemas/wwiBonus";
import FileDrop, { FileDropResult } from "../utils/FileDrop";
import { DefaultPageProps } from "../utils/shared";

export default function WWIBonusForm(props: BaseFormCompProps<WWIBonusRecord>) {
  const wwiBonusRecord = props.record;

  const [pdfLoading, setPDFLoading] = useState(false);
  const [pdf, setPDF] = useState<string | null>();
  const [pdfFilename, setPDFFilename] = useState<string | null>();
  const [lat, setLat] = useState<string | undefined>(
    wwiBonusRecord?.residenceCoordinates?.lat.toString(),
  );
  const [lon, setLon] = useState<string | undefined>(
    wwiBonusRecord?.residenceCoordinates?.lon.toString(),
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    register,
  } = useForm<WWIBonusRecordInput>({
    resolver: yupResolver(wwiBonusInputSchema),
  });

  useEffect(() => {
    if (!wwiBonusRecord) {
      return;
    }
    if (wwiBonusRecord.residenceCoordinates) {
      setLat(wwiBonusRecord.residenceCoordinates.lat.toString());
      setLon(wwiBonusRecord.residenceCoordinates.lon.toString());
    }
    setPDF(wwiBonusRecord.pdfSHA256);
    reset({
      ...wwiBonusInputSchema.cast(wwiBonusRecord),
    });
  }, [setPDF, wwiBonusRecord, reset]);

  const onSubmit = (inputData: WWIBonusRecordInput) => {
    inputData.pdfSHA256 = pdf || undefined;
    if (lat && lon) {
      inputData.residenceCoordinates = {
        lat: Number(lat),
        lon: Number(lon),
      };
    }
  };

  const pdfDrop = !pdf && (
    <FileDrop
      mimeType="application/pdf"
      onDrop={async (files) => {
        setPDFLoading(true);
        const result: Record<string, FileDropResult> = {};
        for (const sha256 in files) {
          const serverSHA256 = await addWWIBonusPDF(
            props.currentUserData.accessToken,
            files[sha256],
          );
          if (serverSHA256 instanceof api.Error) {
            result[sha256] = {
              serverSHA256: null,
              error: serverSHA256.message,
            };
          } else {
            result[sha256] = {
              serverSHA256,
              error: null,
            };
            setPDF(serverSHA256);
            setPDFFilename(files[sha256].name);
          }
        }
        setPDFLoading(false);
        return result;
      }}
      onRemove={() => {}}
    />
  );

  const pdfDisplay = pdf && (
    <Stack align="flex-start">
      <Stack direction="row" align="center">
        <Thumbnail
          source={file_placeholder_text}
          alt={`Uploaded file ${pdfFilename}`}
        />
        <PText>{pdfFilename}</PText>
      </Stack>
      <Button
        tone="critical"
        variant="primary"
        onClick={() => {
          setPDF(null);
          setPDFFilename(null);
        }}
      >
        Remove PDF
      </Button>
    </Stack>
  );

  return (
    <FormSection
      currentUserData={props.currentUserData}
      handleSubmit={handleSubmit}
      inputSchema={wwiBonusInputSchema}
      initialValues={wwiBonusRecord}
      mutateHook={useUpdateWWIBonusRecord}
      deleteHook={useDeleteWWIBonusRecord}
      onSubmit={onSubmit}
      onMutateSuccess={props.onSubmitSuccess}
      onDeleteSuccess={props.onDeleteSuccess}
    >
      <Subheading>Associated PDF</Subheading>
      {pdfLoading ? <Spinner /> : pdfDrop || pdfDisplay}
      <FormFields
        control={control}
        spec={{
          prefix: { type: "text" },
          firstName: { type: "text" },
          middleName: { type: "text" },
          familyName: { type: "text", required: true },
          suffix: { type: "text" },
          certificateId: { type: "text", required: true },
          locatorNumber: { type: "text", required: true },
          residence: { type: "text" },
          notes: { type: "text", lines: 6 },
        }}
        labels={wwiBonusLabels}
        errors={{
          familyName: errors.familyName,
          locatorNumber: errors.locatorNumber,
          certificateId: errors.certificateId,
        }}
        reset={reset}
        getValues={getValues}
      />
      <Controller
        name="outcome"
        control={control}
        defaultValue="allowed"
        render={({ field }) => (
          <ChoiceList
            title="Outcome"
            choices={[
              { label: "Allowed", value: "allowed" },
              { label: "Disallowed", value: "disallowed" },
            ]}
            selected={[field.value]}
            onChange={(selected) => field.onChange(selected[0])}
          />
        )}
      />
      <Subheading>Residence Coordinates</Subheading>
      <Stack direction="row">
        <FormField
          type="number"
          label="Latitude"
          value={lat}
          onChange={(value) => setLat(value)}
        />
        <FormField
          type="number"
          label="Longitude"
          value={lon}
          onChange={(value) => setLon(value)}
        />
      </Stack>
      <Subheading>Alternate Names</Subheading>
      <AccumulativeTextField
        fieldName={"alternateNames"}
        control={control}
        register={register}
        errors={errors.alternateNames as FieldError}
      />
    </FormSection>
  );
}

export function WWIBonusAddPage(props: DefaultPageProps) {
  const navigate = useNavigate();

  return (
    <Page>
      <Layout sectioned>
        <Layout.Section>
          <Card>
            <WWIBonusForm
              currentUserData={props.currentUserData}
              onSubmitSuccess={(id) => navigate(`../${id}`)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export function WWIBonusEditPage(props: DefaultPageProps) {
  const search = useLocation().search;
  const editMode = new URLSearchParams(search).get("editMode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: wwiBonusRecord } = useWWIBonusRecord(
    props.currentUserData.accessToken,
    uuid,
  );

  const skeleton = !wwiBonusRecord && <Skeleton lines={15} />;

  const form = wwiBonusRecord && (
    <WWIBonusForm
      currentUserData={props.currentUserData}
      onSubmitSuccess={() => (editMode === "true" ? null : navigate("../"))}
      onDeleteSuccess={() => navigate("../../")}
      record={wwiBonusRecord}
    />
  );

  return (
    <BreadcrumbPage
      breadcrumbs="both"
      breadcrumbAction={() =>
        navigate(`${editMode === "true" ? "../../" : "../"}`)
      }
    >
      <Card>{form || skeleton}</Card>
    </BreadcrumbPage>
  );
}
