const counties = [
  {
    CountyID: 0,
    EntityName: "Unknown",
  },
  {
    CountyID: 1,
    EntityName: "Aitkin County",
    VRDBCntyID: "MA3",
    PlaceNameID: 18,
    FIPSEntityCode: 99001,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "AK",
    RegionID: 4,
    FIPSCode: 27001,
    USGSFeatureID: 663198,
    longitude: "-93.4169026",
    latitude: "46.5999509",
  },
  {
    CountyID: 2,
    EntityName: "Anoka County",
    VRDBCntyID: "MA6",
    PlaceNameID: 74,
    FIPSEntityCode: 99003,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "AN",
    RegionID: 2,
    FIPSCode: 27003,
    USGSFeatureID: 659447,
    longitude: "-93.2507843",
    latitude: "45.2505207",
  },
  {
    CountyID: 3,
    EntityName: "Becker County",
    VRDBCntyID: "MB3",
    PlaceNameID: 206,
    FIPSEntityCode: 99005,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-18 00:00:00",
    SICode: "BK",
    RegionID: 5,
    FIPSCode: 27005,
    USGSFeatureID: 659448,
    longitude: "-95.6836505",
    latitude: "46.9499592",
  },
  {
    CountyID: 4,
    EntityName: "Beltrami County",
    VRDBCntyID: "MB4",
    PlaceNameID: 223,
    FIPSEntityCode: 99007,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1866-02-28 00:00:00",
    SICode: "BL",
    RegionID: 5,
    FIPSCode: 27007,
    USGSFeatureID: 659449,
    longitude: "-94.9002663",
    latitude: "46.9499592",
  },
  {
    CountyID: 5,
    EntityName: "Benton County",
    VRDBCntyID: "MB5",
    PlaceNameID: 239,
    FIPSEntityCode: 99009,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "BN",
    RegionID: 4,
    FIPSCode: 27009,
    USGSFeatureID: 659450,
    longitude: "-93.9835942",
    latitude: "45.6999668",
  },
  {
    CountyID: 6,
    EntityName: "Big Stone County",
    VRDBCntyID: "MB7",
    PlaceNameID: 261,
    FIPSEntityCode: 99011,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: "BS",
    RegionID: 7,
    FIPSCode: 27011,
    USGSFeatureID: 659451,
    longitude: "-96.4503362",
    latitude: "45.3999643",
  },
  {
    CountyID: 7,
    EntityName: "Blue Earth County",
    VRDBCntyID: "MB8",
    PlaceNameID: 306,
    FIPSEntityCode: 99013,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "BE",
    RegionID: 6,
    FIPSCode: 27013,
    USGSFeatureID: 659452,
    longitude: "-94.0669",
    latitude: "44.0332977",
  },
  {
    CountyID: 8,
    EntityName: "Brown County",
    VRDBCntyID: "MB9",
    PlaceNameID: 384,
    FIPSEntityCode: 99015,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "BW",
    RegionID: 7,
    FIPSCode: 27015,
    USGSFeatureID: 659453,
    longitude: "-94.6835912",
    latitude: "44.2332939",
  },
  {
    CountyID: 9,
    EntityName: "Carlton County",
    VRDBCntyID: "MC1",
    PlaceNameID: 468,
    FIPSEntityCode: 99017,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "CL",
    RegionID: 4,
    FIPSCode: 27017,
    USGSFeatureID: 659454,
    longitude: "-92.6668633",
    latitude: "46.5832789",
  },
  {
    CountyID: 10,
    EntityName: "Carver County",
    VRDBCntyID: "MC2",
    PlaceNameID: 477,
    FIPSEntityCode: 99019,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "CR",
    RegionID: 2,
    FIPSCode: 27019,
    USGSFeatureID: 659455,
    longitude: "-93.7835748",
    latitude: "44.7999624",
  },
  {
    CountyID: 11,
    EntityName: "Cass County",
    VRDBCntyID: "MC3",
    PlaceNameID: 486,
    FIPSEntityCode: 99021,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1851-09-01 00:00:00",
    SICode: "CA",
    RegionID: 4,
    FIPSCode: 27021,
    USGSFeatureID: 659456,
    longitude: "-94.2835929",
    latitude: "46.9166238",
  },
  {
    CountyID: 12,
    EntityName: "Chippewa County",
    VRDBCntyID: "MC4",
    PlaceNameID: 534,
    FIPSEntityCode: 99023,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: "CP",
    RegionID: 7,
    FIPSCode: 27023,
    USGSFeatureID: 659457,
    longitude: "-95.5836354",
    latitude: "45.0332912",
  },
  {
    CountyID: 13,
    EntityName: "Chisago County",
    VRDBCntyID: "MC5",
    PlaceNameID: 537,
    FIPSEntityCode: 99025,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1851-09-01 00:00:00",
    SICode: "CH",
    RegionID: 3,
    FIPSCode: 27025,
    USGSFeatureID: 659458,
    longitude: "-92.9002164",
    latitude: "45.5005176",
  },
  {
    CountyID: 14,
    EntityName: "Clay County",
    VRDBCntyID: "MC6",
    PlaceNameID: 558,
    FIPSEntityCode: 99027,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-03-08 00:00:00",
    SICode: "CY",
    RegionID: 5,
    FIPSCode: 27027,
    USGSFeatureID: 659459,
    longitude: "-96.4836813",
    latitude: "46.8832944",
  },
  {
    CountyID: 15,
    EntityName: "Clearwater County",
    VRDBCntyID: "MC7",
    PlaceNameID: 565,
    FIPSEntityCode: 99029,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1902-12-20 00:00:00",
    SICode: "CE",
    RegionID: 5,
    FIPSCode: 27029,
    USGSFeatureID: 659460,
    longitude: "-95.3669582",
    latitude: "47.5666228",
  },
  {
    CountyID: 16,
    EntityName: "Cook County",
    VRDBCntyID: "MC8",
    PlaceNameID: 617,
    FIPSEntityCode: 99031,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1874-03-09 00:00:00",
    SICode: "CK",
    RegionID: 4,
    FIPSCode: 27031,
    USGSFeatureID: 659461,
    longitude: "-95.3669582",
    latitude: "47.9165574",
  },
  {
    CountyID: 17,
    EntityName: "Cottonwood County",
    VRDBCntyID: "MC9",
    PlaceNameID: 641,
    FIPSEntityCode: 99033,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "CO",
    RegionID: 7,
    FIPSCode: 27033,
    USGSFeatureID: 659462,
    longitude: "-95.2002708",
    latitude: "44.0005126",
  },
  {
    CountyID: 18,
    EntityName: "Crow Wing County",
    VRDBCntyID: "MC10",
    PlaceNameID: 665,
    FIPSEntityCode: 99035,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "CW",
    RegionID: 4,
    FIPSCode: 27035,
    USGSFeatureID: 659463,
    longitude: "-94.0836005",
    latitude: "46.4666315",
  },
  {
    CountyID: 19,
    EntityName: "Dakota County",
    VRDBCntyID: "MD2",
    PlaceNameID: 686,
    FIPSEntityCode: 99037,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "DK",
    RegionID: 2,
    FIPSCode: 27037,
    USGSFeatureID: 659464,
    longitude: "-93.0668807",
    latitude: "44.6666331",
  },
  {
    CountyID: 20,
    EntityName: "Dodge County",
    VRDBCntyID: "MD4",
    PlaceNameID: 762,
    FIPSEntityCode: 99039,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "DO",
    RegionID: 6,
    FIPSCode: 27039,
    USGSFeatureID: 659465,
    longitude: "-92.8668606",
    latitude: "44.0332974",
  },
  {
    CountyID: 21,
    EntityName: "Douglas County",
    VRDBCntyID: "MD6",
    PlaceNameID: 776,
    FIPSEntityCode: 99041,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-08 00:00:00",
    SICode: "DL",
    RegionID: 5,
    FIPSCode: 27041,
    USGSFeatureID: 659466,
    longitude: "-92.8668606",
    latitude: "45.9332947",
  },
  {
    CountyID: 22,
    EntityName: "Faribault County",
    VRDBCntyID: "MF2",
    PlaceNameID: 976,
    FIPSEntityCode: 99043,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "FA",
    RegionID: 6,
    FIPSCode: 27043,
    USGSFeatureID: 659467,
    longitude: "-93.9335649",
    latitude: "43.6666214",
  },
  {
    CountyID: 23,
    EntityName: "Fillmore County",
    VRDBCntyID: "MF4",
    PlaceNameID: 1001,
    FIPSEntityCode: 99045,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "FL",
    RegionID: 6,
    FIPSCode: 27045,
    USGSFeatureID: 659468,
    longitude: "-92.1001607",
    latitude: "43.6666312",
  },
  {
    CountyID: 24,
    EntityName: "Freeborn County",
    VRDBCntyID: "MF6",
    PlaceNameID: 1065,
    FIPSEntityCode: 99047,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "FE",
    RegionID: 6,
    FIPSCode: 27047,
    USGSFeatureID: 659469,
    longitude: "-93.3502095",
    latitude: "43.6666242",
  },
  {
    CountyID: 25,
    EntityName: "Goodhue County",
    VRDBCntyID: "MG6",
    PlaceNameID: 1152,
    FIPSEntityCode: 99049,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "GD",
    RegionID: 3,
    FIPSCode: 27049,
    USGSFeatureID: 659470,
    longitude: "-92.7168626",
    latitude: "44.4166327",
  },
  {
    CountyID: 26,
    EntityName: "Grant County",
    VRDBCntyID: "MG8",
    PlaceNameID: 1194,
    FIPSEntityCode: 99051,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1868-03-06 00:00:00",
    SICode: "GR",
    RegionID: 5,
    FIPSCode: 27051,
    USGSFeatureID: 659471,
    longitude: "-96.0008883",
    latitude: "45.9332934",
  },
  {
    CountyID: 27,
    EntityName: "Hennepin County",
    VRDBCntyID: "MH5",
    PlaceNameID: 1340,
    FIPSEntityCode: 99053,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1852-03-06 00:00:00",
    SICode: "HE",
    RegionID: 2,
    FIPSCode: 27053,
    USGSFeatureID: 659472,
    longitude: "-93.4502314",
    latitude: "45.0166306",
  },
  {
    CountyID: 28,
    EntityName: "Houston County",
    VRDBCntyID: "MH8",
    PlaceNameID: 1418,
    FIPSEntityCode: 99055,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1854-02-23 00:00:00",
    SICode: "HU",
    RegionID: 6,
    FIPSCode: 27055,
    USGSFeatureID: 659473,
    longitude: "-91.5006967",
    latitude: "43.6666352",
  },
  {
    CountyID: 29,
    EntityName: "Hubbard County",
    VRDBCntyID: "MH9",
    PlaceNameID: 1422,
    FIPSEntityCode: 99057,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1883-02-26 00:00:00",
    SICode: "HB",
    RegionID: 5,
    FIPSCode: 27057,
    USGSFeatureID: 659474,
    longitude: "-94.9002897",
    latitude: "47.0999573",
  },
  {
    CountyID: 30,
    EntityName: "Isanti County",
    VRDBCntyID: "MI6",
    PlaceNameID: 1473,
    FIPSEntityCode: 99059,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-02-13 00:00:00",
    SICode: "IA",
    RegionID: 3,
    FIPSCode: 27059,
    USGSFeatureID: 659475,
    longitude: "-93.3002284",
    latitude: "45.5499635",
  },
  {
    CountyID: 31,
    EntityName: "Itasca County",
    VRDBCntyID: "MI8",
    PlaceNameID: 1481,
    FIPSEntityCode: 99061,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "IC",
    RegionID: 4,
    FIPSCode: 27061,
    USGSFeatureID: 659476,
    longitude: "-93.5835345",
    latitude: "47.5004974",
  },
  {
    CountyID: 32,
    EntityName: "Jackson County",
    VRDBCntyID: "MJ2",
    PlaceNameID: 1485,
    FIPSEntityCode: 99063,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "JK",
    RegionID: 7,
    FIPSCode: 27063,
    USGSFeatureID: 659477,
    longitude: "-94.8747087",
    latitude: "43.6255127",
  },
  {
    CountyID: 33,
    EntityName: "Kanabec County",
    VRDBCntyID: "MK1",
    PlaceNameID: 1525,
    FIPSEntityCode: 99065,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-13 00:00:00",
    SICode: "KA",
    RegionID: 4,
    FIPSCode: 27065,
    USGSFeatureID: 659478,
    longitude: "-93.3002239",
    latitude: "45.949957",
  },
  {
    CountyID: 34,
    EntityName: "Kandiyohi County",
    VRDBCntyID: "MK2",
    PlaceNameID: 1527,
    FIPSEntityCode: 99067,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-20 00:00:00",
    SICode: "KH",
    RegionID: 7,
    FIPSCode: 27067,
    USGSFeatureID: 659479,
    longitude: "-95.0008412",
    latitude: "45.1332968",
  },
  {
    CountyID: 35,
    EntityName: "Kittson County",
    VRDBCntyID: "MK6",
    PlaceNameID: 1577,
    FIPSEntityCode: 99069,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1878-03-09 00:00:00",
    SICode: "KT",
    RegionID: 5,
    FIPSCode: 27069,
    USGSFeatureID: 659480,
    longitude: "-96.7836613",
    latitude: "48.7666466",
  },
  {
    CountyID: 36,
    EntityName: "Koochiching County",
    VRDBCntyID: "MK8",
    PlaceNameID: 1588,
    FIPSEntityCode: 99071,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1906-12-19 00:00:00",
    SICode: "KC",
    RegionID: 4,
    FIPSCode: 27071,
    USGSFeatureID: 659481,
    longitude: "-93.7835099",
    latitude: "48.2332852",
  },
  {
    CountyID: 37,
    EntityName: "Lac qui Parle County",
    VRDBCntyID: "ML1",
    PlaceNameID: 1604,
    FIPSEntityCode: 99073,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1871-03-06 00:00:00",
    SICode: "LP",
    RegionID: 7,
    FIPSCode: 27073,
    USGSFeatureID: 659482,
    longitude: "-93.7835099",
    latitude: "44.9666286",
  },
  {
    CountyID: 38,
    EntityName: "Lake County",
    VRDBCntyID: "ML3",
    PlaceNameID: 1607,
    FIPSEntityCode: 99075,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-03-01 00:00:00",
    SICode: "LA",
    RegionID: 4,
    FIPSCode: 27075,
    USGSFeatureID: 659484,
    longitude: "-91.4168152",
    latitude: "47.6332415",
  },
  {
    CountyID: 39,
    EntityName: "Lake of the Woods County",
    VRDBCntyID: "ML4",
    PlaceNameID: 1643,
    FIPSEntityCode: 99077,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1922-11-28 00:00:00",
    SICode: "LW",
    RegionID: 5,
    FIPSCode: 27077,
    USGSFeatureID: 659483,
    longitude: "-91.4168152",
    latitude: "48.7505352",
  },
  {
    CountyID: 40,
    EntityName: "Le Sueur County",
    VRDBCntyID: "ML5",
    PlaceNameID: 1703,
    FIPSEntityCode: 99079,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "LE",
    RegionID: 3,
    FIPSCode: 27079,
    USGSFeatureID: 659485,
    longitude: "-93.7335669",
    latitude: "44.3832987",
  },
  {
    CountyID: 41,
    EntityName: "Lincoln County",
    VRDBCntyID: "ML7",
    PlaceNameID: 1754,
    FIPSEntityCode: 99081,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1873-03-06 00:00:00",
    SICode: "LN",
    RegionID: 7,
    FIPSCode: 27081,
    USGSFeatureID: 659486,
    longitude: "-96.2669852",
    latitude: "44.4166323",
  },
  {
    CountyID: 42,
    EntityName: "Lyon County",
    VRDBCntyID: "ML9",
    PlaceNameID: 1833,
    FIPSEntityCode: 99083,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1868-03-06 00:00:00",
    SICode: "LY",
    RegionID: 7,
    FIPSCode: 27083,
    USGSFeatureID: 659487,
    longitude: "-95.8503004",
    latitude: "44.4166269",
  },
  {
    CountyID: 43,
    EntityName: "McLeod County",
    VRDBCntyID: "MM1",
    PlaceNameID: 1944,
    FIPSEntityCode: 99085,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-03-01 00:00:00",
    SICode: "MC",
    RegionID: 3,
    FIPSCode: 27085,
    USGSFeatureID: 659491,
    longitude: "-94.2508128",
    latitude: "44.8332959",
  },
  {
    CountyID: 44,
    EntityName: "Mahnomen County",
    VRDBCntyID: "MM2",
    PlaceNameID: 1847,
    FIPSEntityCode: 99087,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1906-12-27 00:00:00",
    SICode: "MH",
    RegionID: 5,
    FIPSCode: 27087,
    USGSFeatureID: 659488,
    longitude: "-95.81698",
    latitude: "47.3332935",
  },
  {
    CountyID: 45,
    EntityName: "Marshall County",
    VRDBCntyID: "MM3",
    PlaceNameID: 1904,
    FIPSEntityCode: 99089,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1879-02-25 00:00:00",
    SICode: "MA",
    RegionID: 5,
    FIPSCode: 27089,
    USGSFeatureID: 659489,
    longitude: "-96.3669842",
    latitude: "48.3499735",
  },
  {
    CountyID: 46,
    EntityName: "Martin County",
    VRDBCntyID: "MM4",
    PlaceNameID: 1907,
    FIPSEntityCode: 99091,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "MR",
    RegionID: 7,
    FIPSCode: 27091,
    USGSFeatureID: 659490,
    longitude: "-94.5502525",
    latitude: "43.666622",
  },
  {
    CountyID: 47,
    EntityName: "Meeker County",
    VRDBCntyID: "MM5",
    PlaceNameID: 1955,
    FIPSEntityCode: 99093,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-02-23 00:00:00",
    SICode: "ME",
    RegionID: 7,
    FIPSCode: 27093,
    USGSFeatureID: 659492,
    longitude: "-94.516936",
    latitude: "45.1332959",
  },
  {
    CountyID: 48,
    EntityName: "Mille Lacs County",
    VRDBCntyID: "MM6",
    PlaceNameID: 1986,
    FIPSEntityCode: 99095,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "ML",
    RegionID: 4,
    FIPSCode: 27095,
    USGSFeatureID: 659493,
    longitude: "-93.758582",
    latitude: "45.5833007",
  },
  {
    CountyID: 49,
    EntityName: "Morrison County",
    VRDBCntyID: "MM7",
    PlaceNameID: 2057,
    FIPSEntityCode: 99097,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-02-25 00:00:00",
    SICode: "MO",
    RegionID: 4,
    FIPSCode: 27097,
    USGSFeatureID: 659494,
    longitude: "-94.300278",
    latitude: "46.0166325",
  },
  {
    CountyID: 50,
    EntityName: "Mower County",
    VRDBCntyID: "MM8",
    PlaceNameID: 2074,
    FIPSEntityCode: 99099,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "MW",
    RegionID: 6,
    FIPSCode: 27099,
    USGSFeatureID: 659495,
    longitude: "-92.7668509",
    latitude: "43.6666307",
  },

  {
    CountyID: 51,
    EntityName: "Murray County",
    VRDBCntyID: "MM9",
    PlaceNameID: 2088,
    FIPSEntityCode: 99101,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "MU",
    RegionID: 7,
    FIPSCode: 27101,
    USGSFeatureID: 659496,
    longitude: "-95.7669581",
    latitude: "44.0332969",
  },
  {
    CountyID: 52,
    EntityName: "Nicollet County",
    VRDBCntyID: "MN2",
    PlaceNameID: 2148,
    FIPSEntityCode: 99103,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "NL",
    RegionID: 6,
    FIPSCode: 27103,
    USGSFeatureID: 659497,
    longitude: "-94.2835807",
    latitude: "44.3499654",
  },
  {
    CountyID: 53,
    EntityName: "Nobles County",
    VRDBCntyID: "MN7",
    PlaceNameID: 2157,
    FIPSEntityCode: 99105,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "NO",
    RegionID: 7,
    FIPSCode: 27105,
    USGSFeatureID: 659498,
    longitude: "-95.7669587",
    latitude: "44.3499654",
  },
  {
    CountyID: 54,
    EntityName: "Norman County",
    VRDBCntyID: "MN8",
    PlaceNameID: 2169,
    FIPSEntityCode: 99107,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1881-02-17 00:00:00",
    SICode: "NR",
    RegionID: 5,
    FIPSCode: 27107,
    USGSFeatureID: 659499,
    longitude: "-96.4670107",
    latitude: "47.3332995",
  },
  {
    CountyID: 55,
    EntityName: "Olmsted County",
    VRDBCntyID: "MO5",
    PlaceNameID: 2260,
    FIPSEntityCode: 99109,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "OL",
    RegionID: 6,
    FIPSCode: 27109,
    USGSFeatureID: 659500,
    longitude: "-92.4501754",
    latitude: "44.0005193",
  },
  {
    CountyID: 56,
    EntityName: "Otter Tail County",
    VRDBCntyID: "MO8",
    PlaceNameID: 2306,
    FIPSEntityCode: 99111,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-18 00:00:00",
    SICode: "OT",
    RegionID: 5,
    FIPSCode: 27111,
    USGSFeatureID: 659501,
    longitude: "-95.7003241",
    latitude: "44.0005193",
  },
  {
    CountyID: 57,
    EntityName: "Pennington County",
    VRDBCntyID: "MP3",
    PlaceNameID: 2362,
    FIPSEntityCode: 99113,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1910-11-23 00:00:00",
    SICode: "PE",
    RegionID: 5,
    FIPSCode: 27113,
    USGSFeatureID: 659502,
    longitude: "-96.03364",
    latitude: "48.0666321",
  },
  {
    CountyID: 58,
    EntityName: "Pine County",
    VRDBCntyID: "MP4",
    PlaceNameID: 2401,
    FIPSEntityCode: 99115,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-03-01 00:00:00",
    SICode: "PN",
    RegionID: 4,
    FIPSCode: 27115,
    USGSFeatureID: 659503,
    longitude: "-92.2507488",
    latitude: "46.0832758",
  },
  {
    CountyID: 59,
    EntityName: "Pipestone County",
    VRDBCntyID: "MP5",
    PlaceNameID: 2418,
    FIPSEntityCode: 99117,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "PP",
    RegionID: 7,
    FIPSCode: 27117,
    USGSFeatureID: 659504,
    longitude: "-92.2507488",
    latitude: "44.0333031",
  },
  {
    CountyID: 60,
    EntityName: "Polk County",
    VRDBCntyID: "MP7",
    PlaceNameID: 2438,
    FIPSEntityCode: 99119,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-07-20 00:00:00",
    SICode: "PL",
    RegionID: 5,
    FIPSCode: 27119,
    USGSFeatureID: 659505,
    longitude: "-96.583676",
    latitude: "47.7505268",
  },
  {
    CountyID: 61,
    EntityName: "Pope County",
    VRDBCntyID: "MP8",
    PlaceNameID: 2447,
    FIPSEntityCode: 99121,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: "PO",
    RegionID: 7,
    FIPSCode: 27121,
    USGSFeatureID: 659506,
    longitude: "-95.4503143",
    latitude: "45.566628",
  },
  {
    CountyID: 62,
    EntityName: "Ramsey County",
    VRDBCntyID: "MR2",
    PlaceNameID: 2501,
    FIPSEntityCode: 99123,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "RA",
    RegionID: 2,
    FIPSCode: 27123,
    USGSFeatureID: 659507,
    longitude: "-93.0835529",
    latitude: "45.033299",
  },
  {
    CountyID: 63,
    EntityName: "Red Lake County",
    VRDBCntyID: "MR4",
    PlaceNameID: 2518,
    FIPSEntityCode: 99125,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1896-12-24 00:00:00",
    SICode: "RL",
    RegionID: 5,
    FIPSCode: 27125,
    USGSFeatureID: 659508,
    longitude: "-96.0836479",
    latitude: "47.8832978",
  },
  {
    CountyID: 64,
    EntityName: "Redwood County",
    VRDBCntyID: "MR5",
    PlaceNameID: 2527,
    FIPSEntityCode: 99127,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-06 00:00:00",
    SICode: "RW",
    RegionID: 7,
    FIPSCode: 27127,
    USGSFeatureID: 659509,
    longitude: "-95.2336101",
    latitude: "44.4166245",
  },
  {
    CountyID: 65,
    EntityName: "Renville County",
    VRDBCntyID: "MR6",
    PlaceNameID: 2538,
    FIPSEntityCode: 99129,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "RN",
    RegionID: 7,
    FIPSCode: 27129,
    USGSFeatureID: 659510,
    longitude: "-94.966942",
    latitude: "44.7166277",
  },
  {
    CountyID: 66,
    EntityName: "Rice County",
    VRDBCntyID: "MR7",
    PlaceNameID: 2543,
    FIPSEntityCode: 99131,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "RC",
    RegionID: 3,
    FIPSCode: 27131,
    USGSFeatureID: 659511,
    longitude: "-93.3335525",
    latitude: "44.3499636",
  },
  {
    CountyID: 67,
    EntityName: "Rock County",
    VRDBCntyID: "MR8",
    PlaceNameID: 2587,
    FIPSEntityCode: 99133,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: "RK",
    RegionID: 7,
    FIPSCode: 27133,
    USGSFeatureID: 659512,
    longitude: "-96.1003026",
    latitude: "43.6666347",
  },
  {
    CountyID: 68,
    EntityName: "Roseau County",
    VRDBCntyID: "MR9",
    PlaceNameID: 2616,
    FIPSEntityCode: 99135,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1894-12-31 00:00:00",
    SICode: "RO",
    RegionID: 5,
    FIPSCode: 27135,
    USGSFeatureID: 659513,
    longitude: "-95.8002699",
    latitude: "48.7833124",
  },
  {
    CountyID: 69,
    EntityName: "St. Louis County",
    VRDBCntyID: "MS2",
    PlaceNameID: 2901,
    FIPSEntityCode: 99137,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-03-03 00:00:00",
    SICode: "SL",
    RegionID: 4,
    FIPSCode: 27137,
    USGSFeatureID: 662850,
    longitude: "-92.5007361",
    latitude: "47.583258",
  },
  {
    CountyID: 70,
    EntityName: "Scott County",
    VRDBCntyID: "MS3",
    PlaceNameID: 2711,
    FIPSEntityCode: 99139,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "SC",
    RegionID: 2,
    FIPSCode: 27139,
    USGSFeatureID: 659514,
    longitude: "-93.5007853",
    latitude: "44.6332964",
  },
  {
    CountyID: 71,
    EntityName: "Sherburne County",
    VRDBCntyID: "MS4",
    PlaceNameID: 2747,
    FIPSEntityCode: 99141,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1856-02-25 00:00:00",
    SICode: "SH",
    RegionID: 3,
    FIPSCode: 27141,
    USGSFeatureID: 659515,
    longitude: "-93.766916",
    latitude: "45.4499662",
  },
  {
    CountyID: 72,
    EntityName: "Sibley County",
    VRDBCntyID: "MS5",
    PlaceNameID: 2767,
    FIPSEntityCode: 99143,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: "SB",
    RegionID: 3,
    FIPSCode: 27143,
    USGSFeatureID: 659516,
    longitude: "-94.2002528",
    latitude: "44.566631",
  },
  {
    CountyID: 73,
    EntityName: "Stearns County",
    VRDBCntyID: "MS6",
    PlaceNameID: 2935,
    FIPSEntityCode: 99145,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "SN",
    RegionID: 7,
    FIPSCode: 27145,
    USGSFeatureID: 659517,
    longitude: "-94.5502799",
    latitude: "45.5499643",
  },
  {
    CountyID: 74,
    EntityName: "Steele County",
    VRDBCntyID: "MS7",
    PlaceNameID: 2936,
    FIPSEntityCode: 99147,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "ST",
    RegionID: 6,
    FIPSCode: 27147,
    USGSFeatureID: 659518,
    longitude: "-93.1335389",
    latitude: "44.0332966",
  },
  {
    CountyID: 75,
    EntityName: "Stevens County",
    VRDBCntyID: "MS8",
    PlaceNameID: 2944,
    FIPSEntityCode: 99149,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: "SE",
    RegionID: 7,
    FIPSCode: 27149,
    USGSFeatureID: 659519,
    longitude: "-93.1335389",
    latitude: "45.5666281",
  },
  {
    CountyID: 76,
    EntityName: "Swift County",
    VRDBCntyID: "MS9",
    PlaceNameID: 3000,
    FIPSEntityCode: 99151,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1870-02-18 00:00:00",
    SICode: "SW",
    RegionID: 7,
    FIPSCode: 27151,
    USGSFeatureID: 659520,
    longitude: "-95.6669804",
    latitude: "45.266627",
  },
  {
    CountyID: 77,
    EntityName: "Todd County",
    VRDBCntyID: "MT6",
    PlaceNameID: 3056,
    FIPSEntityCode: 99153,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "TO",
    RegionID: 5,
    FIPSCode: 27153,
    USGSFeatureID: 659521,
    longitude: "-94.8836338",
    latitude: "46.0666308",
  },
  {
    CountyID: 78,
    EntityName: "Traverse County",
    VRDBCntyID: "MT7",
    PlaceNameID: 3075,
    FIPSEntityCode: 99155,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: "TR",
    RegionID: 7,
    FIPSCode: 27155,
    USGSFeatureID: 659522,
    longitude: "-96.433676",
    latitude: "45.7999609",
  },
  {
    CountyID: 79,
    EntityName: "Wabasha County",
    VRDBCntyID: "MW1",
    PlaceNameID: 3166,
    FIPSEntityCode: 99157,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "WB",
    RegionID: 6,
    FIPSCode: 27157,
    USGSFeatureID: 659523,
    longitude: "-92.2168395",
    latitude: "44.2833001",
  },
  {
    CountyID: 80,
    EntityName: "Wadena County",
    VRDBCntyID: "MW2",
    PlaceNameID: 3172,
    FIPSEntityCode: 99159,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-06-11 00:00:00",
    SICode: "WD",
    RegionID: 5,
    FIPSCode: 27159,
    USGSFeatureID: 659524,
    longitude: "-94.9669655",
    latitude: "46.5832938",
  },
  {
    CountyID: 81,
    EntityName: "Waseca County",
    VRDBCntyID: "MW3",
    PlaceNameID: 3211,
    FIPSEntityCode: 99161,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-02-27 00:00:00",
    SICode: "WE",
    RegionID: 6,
    FIPSCode: 27161,
    USGSFeatureID: 659525,
    longitude: "-93.5835552",
    latitude: "44.0332979",
  },
  {
    CountyID: 82,
    EntityName: "Washington County",
    VRDBCntyID: "MW4",
    PlaceNameID: 3213,
    FIPSEntityCode: 99163,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: "WA",
    RegionID: 2,
    FIPSCode: 27163,
    USGSFeatureID: 659526,
    longitude: "-92.9168793",
    latitude: "45.0332996",
  },
  {
    CountyID: 83,
    EntityName: "Watonwan County",
    VRDBCntyID: "MW5",
    PlaceNameID: 3224,
    FIPSEntityCode: 99165,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1860-02-25 00:00:00",
    SICode: "WW",
    RegionID: 7,
    FIPSCode: 27165,
    USGSFeatureID: 659527,
    longitude: "-94.6169176",
    latitude: "43.9666243",
  },
  {
    CountyID: 84,
    EntityName: "Wilkin County",
    VRDBCntyID: "MW6",
    PlaceNameID: 3315,
    FIPSEntityCode: 99167,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1868-03-06 00:00:00",
    SICode: "WL",
    RegionID: 5,
    FIPSCode: 27167,
    USGSFeatureID: 659528,
    longitude: "-96.4670148",
    latitude: "46.2505151",
  },
  {
    CountyID: 85,
    EntityName: "Winona County",
    VRDBCntyID: "MW7",
    PlaceNameID: 3344,
    FIPSEntityCode: 99169,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1854-02-23 00:00:00",
    SICode: "WN",
    RegionID: 6,
    FIPSCode: 27169,
    USGSFeatureID: 659529,
    longitude: "-96.4670148",
    latitude: "43.9666297",
  },
  {
    CountyID: 86,
    EntityName: "Wright County",
    VRDBCntyID: "MW9",
    PlaceNameID: 3375,
    FIPSEntityCode: 99171,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: "WR",
    RegionID: 3,
    FIPSCode: 27171,
    USGSFeatureID: 659530,
    longitude: "-96.4670148",
    latitude: "45.1832979",
  },
  {
    CountyID: 87,
    EntityName: "Yellow Medicine County",
    VRDBCntyID: "MY2",
    PlaceNameID: 3388,
    FIPSEntityCode: 99173,
    ClassCode: "H1",
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1871-03-06 00:00:00",
    SICode: "YM",
    RegionID: 7,
    FIPSCode: 27173,
    USGSFeatureID: 659531,
    longitude: "-95.8003044",
    latitude: "45.1832979",
  },
  {
    CountyID: 88,
    EntityName: "Andy Johnson County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-18 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 89,
    EntityName: "Big Sioux County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 90,
    EntityName: "Breckenridge County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-18 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 91,
    EntityName: "Buchanan County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 92,
    EntityName: "Davis County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 94,
    EntityName: "Doty County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 95,
    EntityName: "Mankahto County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 96,
    EntityName: "Manomin County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1857-05-23 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 97,
    EntityName: "Monongalia County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1861-03-08 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 98,
    EntityName: "Newton County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 99,
    EntityName: "Pembina County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 100,
    EntityName: "Pierce County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1853-03-05 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 101,
    EntityName: "St. Croix County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1840-08-03 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 102,
    EntityName: "Superior County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 103,
    EntityName: "Toombs County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1858-03-18 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 104,
    EntityName: "Wahnata County",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1849-10-27 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 105,
    EntityName: "Lac qui Parle County (old)",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1862-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 106,
    EntityName: "Lincoln County (old)",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1861-10-08 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 107,
    EntityName: "St. Louis County (old)",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: "1855-02-20 00:00:00",
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 108,
    EntityName: "Unorganized Territory",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 25,
    EstabDate: null,
    SICode: null,
    RegionID: null,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
  {
    CountyID: 109,
    EntityName: "Wisconson Territory",
    VRDBCntyID: null,
    PlaceNameID: null,
    FIPSEntityCode: null,
    ClassCode: null,
    PostalUSGSMatch: null,
    StateID: 0,
    EstabDate: null,
    SICode: null,
    RegionID: 0,
    FIPSCode: null,
    USGSFeatureID: null,
    longitude: null,
    latitude: null,
  },
];

export const countiesById = counties.reduce<Record<number, any>>(
  (result, county) => ({ ...result, [county.CountyID]: county }),
  {}
);

export const countyNames = counties.map(({ EntityName }) => EntityName);

export default counties;
