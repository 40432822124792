import { Text } from "@shopify/polaris";

interface TextComponentProps {
  children: React.ReactNode;
}

export function Heading(props: TextComponentProps) {
  return (
    <Text variant="headingLg" as="h2" numeric={true}>
      {props.children}
    </Text>
  );
}

export function Subheading(props: TextComponentProps) {
  return (
    <Text variant="bodySm" as="h6">
      <div
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {props.children}
      </div>
    </Text>
  );
}

interface PTextProps extends TextComponentProps {
  tone?: "subdued" | "critical" | "success" | "text-inverse" | "caution";
  weight?: "regular" | "medium" | "bold";
  breakWord?: boolean;
}

export function PText(props: PTextProps) {
  return (
    <Text
      variant="bodyMd"
      as="p"
      tone={props.tone}
      fontWeight={props.weight}
      breakWord={props.breakWord}
    >
      {props.children}
    </Text>
  );
}
