import { Button, Pagination } from "@shopify/polaris";
import { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
// Must import these react-pdf styles as some pdfs have annotations and text layers:
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Stack from "../shared/Stack";

// Must configure pdfjs worker to render pdfs successfully. This is one of the
// methods recommended by react-pdf (under "Use external CDN" in their docs as of 
// 7/24/24), no idea if it's the best approach but it's the one that worked.
// They do seem to subtly change this almost every major version, which is 
// obnoxious. So, check this first if you get errors after an upgrade.
pdfjs.GlobalWorkerOptions.workerSrc =
  "//unpkg.com/pdfjs-dist@" + pdfjs.version + "/build/pdf.worker.min.mjs";

interface ZoomablePDFProps {
  src: Blob;
  fileName?: string | null;
}

export default function ZoomablePDF(props: ZoomablePDFProps) {
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState<number>();

  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (numPages && page < numPages) {
      setPage((current) => current + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((current) => current - 1);
    }
  };

  const url = URL.createObjectURL(props.src);

  return (
    <Stack>
      <div id="pdf-display-wrapper">
        <Document file={props.src} onLoadSuccess={onDocLoadSuccess}>
          <TransformWrapper>
            <TransformComponent>
              <Page pageNumber={page} />
            </TransformComponent>
          </TransformWrapper>
        </Document>
      </div>
      <Stack direction="row" justify="flex-end">
        <Button variant="plain" onClick={() => window.open(url)}>
          Open in New Tab
        </Button>
        {props.fileName ? (
          <Button variant="plain" url={url} download={props.fileName}>
            Download
          </Button>
        ) : null}
      </Stack>
      <Stack direction="row">
        <Pagination
          hasPrevious={page > 1}
          hasNext={numPages !== undefined && page < numPages}
          onPrevious={prevPage}
          onNext={nextPage}
          label={`Page ${page} / ${numPages}`}
        />
      </Stack>
    </Stack>
  );
}
