import { Pagination as PolarisPagination, Spinner } from "@shopify/polaris";
import Stack from "../../shared/Stack";
import { PagedResults } from "../schemas/core";

interface PaginationProps<T> {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  results?: PagedResults<T>;
  queryRefetching?: boolean;
  alwaysVisible?: boolean;
}

/**
 * Simple wrapper for a PagedResults object to provide pagination controls.
 * @param setPage the page state setter from useState, will be used by Pagination
 * to control page value when buttons are clicked.
 * @param results PagedResults object, Pagination can handle an undefined results
 * object so you don't have to worry about checking if the query has finished
 * loading before rendering Pagination.
 * @param queryRefetching boolean indicating if the query is refetching after
 * initial load. Will cause Pagination to display an inline spinner while
 * the query is refetching. Don't pass it if you don't want the spinner.
 * @param alwaysVisible By default Pagination will hide itself if the totalCount
 * of the results object is less than the page size (i.e. no pagination is
 * necessary for data of that size), but you can pass this to turn this behavior
 * off.
 * @returns
 */
export default function Pagination<T>({
  results,
  setPage,
  queryRefetching,
  alwaysVisible,
}: PaginationProps<T>) {
  const prevPage = () => setPage((old) => Math.max(old - 1, 1));

  const nextPage = () => setPage((old) => (results?.hasNext ? old + 1 : old));

  const hidePagination = results
    ? results.totalCount <= results.pageSize && !alwaysVisible
    : false;

  return (
    results &&
    !hidePagination && (
      <Stack direction="row" justify="center" align="center">
        <PolarisPagination
          hasPrevious={results.hasPrevious}
          hasNext={results.hasNext}
          onNext={nextPage}
          onPrevious={prevPage}
          label={`Page ${results.pageNum || "?"} / 
            ${Math.max(1, Math.ceil(results.totalCount / results.pageSize))}`}
        />
        {queryRefetching && <Spinner size="small" />}
      </Stack>
    )
  );
}
