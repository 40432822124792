import { Banner, Button, Divider, Tooltip } from "@shopify/polaris";
import Stack from "../../shared/Stack";
import { PText } from "../../shared/TextComponents";
import {
  CommentSub,
  CommentSubInput,
  commentSubInputSchema,
  commentSubLabels,
  Domain,
} from "../schemas/comment";
import { CurrentUserData, PagedResults } from "../schemas/core";
import { renderValueAsString } from "../utils/shared";
import Prompt from "../utils/Prompt";
import { useState } from "react";
import { DeletePrompt, DeleteSuccessPrompt } from "../utils/DeleteComponents";
import {
  useAddCmtSub,
  useCheckCmtSub,
  useDeleteCmtSub,
  useRequestCmtSub,
  useRequestCmtUnsub,
} from "../hooks/commentHooks";
import { hasPermissions } from "../auth/authutils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFields, FormSection } from "../Form";
import { AlertCircleIcon } from "@shopify/polaris-icons";

interface CommentSubsProps {
  currentUserData: CurrentUserData;
  recordId: string;
  recordDomain: Domain;
  subs: PagedResults<CommentSub>;
}

export default function CommentSubs({
  subs,
  recordId,
  recordDomain,
  currentUserData,
}: CommentSubsProps) {
  const [modalActive, setModalActive] = useState(false);
  const [deletePromptActive, setDeletePromptActive] = useState(false);
  const [deleteConfirmActive, setDeleteConfirmActive] = useState(false);
  const [deleteSub, setDeleteSub] = useState<CommentSub | undefined>();

  const requestCmtSubMutation = useRequestCmtSub();
  const requestCmtUnsubMutation = useRequestCmtUnsub();
  const deleteMutation = useDeleteCmtSub();
  const { data: currentlySubscribed } = useCheckCmtSub(
    currentUserData.accessToken,
    recordId,
    currentUserData.user.email,
  );

  const subscribeBtnTooltip = currentlySubscribed
    ? "You are currently subscribed to comments on this record."
    : "Triggers the email-based subscription process for your email.";

  const subDefaults: CommentSubInput = {
    recordId: recordId,
    domain: recordDomain,
    email: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<CommentSubInput>({
    resolver: yupResolver(commentSubInputSchema),
    defaultValues: subDefaults,
  });

  const closeFormModal = () => {
    setModalActive(false);
    reset(subDefaults);
  };

  const subFormModal = (
    <Prompt
      active={modalActive}
      title="Direct Add Subscription"
      onClose={closeFormModal}
    >
      <Stack>
        <Banner tone="warning" icon={AlertCircleIcon}>
          Note that using this form will circumvent the email subscription
          verification process.
        </Banner>
        <FormSection
          currentUserData={currentUserData}
          handleSubmit={handleSubmit}
          inputSchema={commentSubInputSchema}
          mutateHook={useAddCmtSub}
          onMutateSuccess={closeFormModal}
          additionalActions={
            <Button
              onClick={() => {
                reset({
                  ...subDefaults,
                  email: currentUserData.user.email,
                });
              }}
            >
              Use My Email
            </Button>
          }
        >
          <FormFields
            control={control}
            spec={{
              email: { type: "text", required: true },
            }}
            labels={commentSubLabels}
            errors={errors}
            reset={reset}
            getValues={getValues}
          />
        </FormSection>
      </Stack>
    </Prompt>
  );

  const toggleDeletePrompt = () => setDeletePromptActive((current) => !current);

  const deletePrompt = deleteSub && (
    <DeletePrompt
      id={deleteSub.id}
      active={deletePromptActive}
      togglePrompt={toggleDeletePrompt}
      loading={deleteMutation.isLoading}
      promptText={`Really delete subscription for ${deleteSub.email}? This cannot be undone`}
      onDelete={(id: string) => {
        deleteMutation.mutate(
          {
            accessToken: currentUserData.accessToken,
            uuid: id,
          },
          {
            onSuccess: () => {
              setDeleteConfirmActive(true);
              toggleDeletePrompt();
            },
          },
        );
      }}
    />
  );

  const deleteSuccessPrompt = deleteSub && (
    <DeleteSuccessPrompt
      id={deleteSub}
      active={deleteConfirmActive}
      promptText={`Successfully deleted subscription for ${deleteSub.email}.`}
      onAck={() => {
        setDeleteConfirmActive(false);
      }}
    />
  );

  const canAddRemove = hasPermissions(
    currentUserData,
    "add_remove_cmt_subscriptions",
  );

  const canManage = hasPermissions(
    currentUserData,
    "manage_comment_subscriptions",
  );

  return (
    <Stack>
      {deleteSuccessPrompt}
      {deletePrompt}
      {subFormModal}
      {subs.results.map((sub, idx) => (
        <Stack key={sub.id}>
          {idx !== 0 && <Divider />}
          <Stack direction="row" align="center">
            <PText>{sub.email}</PText>
            <PText>{renderValueAsString(sub.subscribeDt, "datetime")}</PText>
            {canManage && (
              <Tooltip content="Triggers the email-based unsubscription process for this email.">
                <Button
                  onClick={async () => {
                    await requestCmtUnsubMutation.mutateAsync({
                      accessToken: currentUserData.accessToken,
                      inputData: {
                        email: currentUserData.user.email,
                        recordId: recordId,
                        domain: recordDomain,
                      },
                    });
                  }}
                >
                  Unsubscribe
                </Button>
              </Tooltip>
            )}
            {canAddRemove && (
              <Tooltip content="Unsubscribe this email without triggering the email-based process.">
                <Button
                  tone="critical"
                  onClick={() => {
                    setDeleteSub(sub);
                    toggleDeletePrompt();
                  }}
                >
                  Direct Unsubscribe
                </Button>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      ))}
      {subs.totalCount === 0 && (
        <PText>No subscriptions found for this record.</PText>
      )}
      <Stack direction="row" justify="flex-end" align="center">
        {canManage && (
          <Tooltip content={subscribeBtnTooltip}>
            <Button
              disabled={requestCmtSubMutation.isLoading || currentlySubscribed}
              onClick={async () => {
                await requestCmtSubMutation.mutateAsync({
                  accessToken: currentUserData.accessToken,
                  inputData: {
                    email: currentUserData.user.email,
                    recordId: recordId,
                    domain: recordDomain,
                  },
                });
              }}
            >
              Subscribe
            </Button>
          </Tooltip>
        )}
        {canAddRemove && (
          <Tooltip content="Subscribe an email without triggering the email-based subscription process.">
            <Button onClick={() => setModalActive(true)}>
              Direct Subscribe
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
