import * as yup from "yup";
import {
    APIError,
    axiosInstance,
    baseAxiosConfig,
    handleAPIErrors,
} from "./shared";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { RQUERY_STALE_TIME } from "../../env";
import * as schemas from "../schemas/core";
import { SearchQuery, SearchQueryProps } from "../search/searchutils";
import { formatDurationNeatly } from "../utils/shared";

export type BaseRMAMediaEndpoint =
  | "birth-records"
  | "death-records"
  | "gold-star"
  | "vets-graves"
  | "census/pages"
  | "wwi-service"
  | "wwi-bonuses";

export type BaseRMAEndpoint = BaseRMAMediaEndpoint | "census";

export type BaseEndpoint =
  | BaseRMAEndpoint
  | "collections"
  | "wwi-soldiers-photos";

export type BaseUpdateEndpoint = BaseRMAEndpoint | "comments";

export type BaseDeleteEndpoint = BaseUpdateEndpoint | BaseEndpoint;

export type RMAMediaType = "pdf" | "image";

export function useRecord<T>(
  base: BaseEndpoint,
  schema: yup.Schema,
  accessToken: string,
  uuid?: string | null,
) {
  const getRecord = async <T>(
    accessToken: string,
    uuid: string,
  ): Promise<T> => {
    //   try {
    const resp = await axiosInstance.get(
      `/${base}/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    const record = await schema.validate(resp.data);
    return record;
    //   } catch (e) {
    //     return handleAPIErrors(e);
    //   }
  };

  return useQuery(
    [base, accessToken, uuid],
    async () =>
      accessToken && uuid ? await getRecord<T>(accessToken, uuid) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}

export const searchRecords = async <T>(
  { accessToken, query, sorts, filters, pageNum, pageSize }: SearchQueryProps,
  base: BaseEndpoint,
): Promise<schemas.SearchResult<T>> => {
  const searchQuery: SearchQuery = {
    query: query,
    sorts: sorts || [],
    filters: filters || [],
  };

  const resp = await axiosInstance.post(`/${base}/`, searchQuery, {
    ...baseAxiosConfig(accessToken),
    params: { pageNum, pageSize },
  });
  const result = await schemas.searchResultSchema.validate(resp.data);
  return result;
};

export const getCount = async (
  accessToken: string,
  base: BaseEndpoint,
  query?: string,
): Promise<number> => {
  const resp = await axiosInstance.get(`/${base}/count`, {
    ...baseAxiosConfig(accessToken),
    params: { query },
  });
  return resp.data;
};

export const requestSearchResultsEmail = async (
  query: SearchQuery,
  accessToken: string,
  base: BaseEndpoint,
) => {
  await axiosInstance.post(`/${base}/reports/search-results`, query, {
    ...baseAxiosConfig(accessToken),
  });
};

export const getMedia = async (
  accessToken: string,
  hash: string,
  base: BaseEndpoint,
  setProgressState?: (
    progress: number,
    elapsed: string,
    timeRemaining: string,
  ) => void,
): Promise<schemas.MediaObject> => {
  const t0 = new Date();
  let result = new Blob();
  let hasNext = true;
  let chunk = 1;
  let loaded = 0;
  let total = 0;
  let mimeType: string | undefined = undefined;
  while (hasNext) {
    const resp = await axiosInstance.get(`collections/media/${hash}`, {
      ...baseAxiosConfig(accessToken),
      params: { chunk },
      responseType: "blob",
    });
    // Axios transforms all headers to lowercase:
    mimeType = resp.headers["content-type"];
    let range: string = resp.headers["content-range"];
    let parts = range.match(/bytes [0-9]*-([0-9]*)\/([0-9]*)/);
    result = new Blob([result, resp.data], { type: mimeType });
    if (!parts) {
      throw Error("Response header Content-Range has unexpected format.");
    }
    let end = parseInt(parts[1]);
    total = parseInt(parts[2]);
    hasNext = end < total;

    loaded += parseInt(resp.headers["content-length"]);

    if (setProgressState) {
      let elapsed = Date.now() - t0.getTime();
      let progress = Math.floor((loaded / total) * 100);
      let estRemainder = (elapsed * total) / loaded - elapsed;
      setProgressState(
        progress,
        formatDurationNeatly(elapsed),
        formatDurationNeatly(estRemainder),
      );
    }

    chunk += 1;
  }
  if (!mimeType) {
    throw Error("Response header missing Content-Type.");
  }
  return { content: result, mimeType: mimeType };
};

export function useDeleteRecord(base: BaseDeleteEndpoint) {
  const deleteRecord = async (
    accessToken: string,
    uuid: string,
  ): Promise<boolean> => {
    const resp = await axiosInstance.delete(
      `/${base}/${uuid}`,
      baseAxiosConfig(accessToken),
    );
    return resp.status === 204 ? true : false;
  };

  return useMutation(
    ({ accessToken, uuid }: { accessToken: string; uuid: string }) => {
      return deleteRecord(accessToken, uuid);
    },
  );
}

export function useUpdateRecord<T extends { id?: string | null | undefined }>(
  base: BaseUpdateEndpoint,
) {
  const queryClient = useQueryClient();

  const updateRecord = async <T extends { id?: string | null | undefined }>(
    accessToken: string,
    inputModel: T,
  ): Promise<string | undefined> => {
    try {
      const resp = await axiosInstance.post(
        inputModel.id ? `/${base}/${inputModel.id}/edit` : `${base}/add`,
        inputModel,
        baseAxiosConfig(accessToken),
      );
      return resp.data;
    } catch (e) {
      console.error(e);
      throw Error;
    }
  };

  return useMutation(
    ({ accessToken, inputData }: { accessToken: string; inputData: T }) => {
      return updateRecord(accessToken, inputData);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([base]);
      },
    },
  );
}

export const addRMAMedia = async (
  accessToken: string,
  mediaFile: File,
  base: BaseRMAEndpoint,
): Promise<string | APIError> => {
  try {
    const formData = new FormData();
    formData.append("media", mediaFile);
    const resp = await axiosInstance.post(
      `/${base}/media`,
      formData,
      baseAxiosConfig(accessToken),
    );
    return resp.data;
  } catch (e) {
    return handleAPIErrors(e);
  }
};

export function useRMAEvents<T>(
  base: BaseRMAEndpoint,
  schema: yup.Schema,
  accessToken: string,
  uuid?: string,
) {
  const getEvents = async <T>(
    accessToken: string,
    uuid: string,
  ): Promise<schemas.Event<T>[]> => {
    const resp = await axiosInstance.get(`/${base}/events/${uuid}`, {
      ...baseAxiosConfig(accessToken),
    });
    const result = await schema.validate(resp.data);
    return result;
  };

  return useQuery(
    [base, "events", uuid],
    async () => (uuid ? await getEvents<T>(accessToken, uuid) : null),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export function useBulkRMAUpdateEntries(
  accessToken: string,
  base: BaseRMAEndpoint,
) {
  const getBulkRMAUpdateEntries = async (
    accessToken: string,
  ): Promise<schemas.BulkUpdateEntryList> => {
    const resp = await axiosInstance.get(`${base}/bulk/`, {
      ...baseAxiosConfig(accessToken),
    });
    const result = await schemas.bulkUpdateEntryList.validate(resp.data);
    return result;
  };

  return useQuery(
    ["bulkUpdateEntries", base],
    async () => getBulkRMAUpdateEntries(accessToken),
    { staleTime: RQUERY_STALE_TIME },
  );
}

export const performBulkRMAUpdate = async <T>(
  accessToken: string,
  base: BaseRMAEndpoint,
  records: T[],
  note?: string,
) => {
  const bulkInput: schemas.BulkUpdateEntryInput<T> = {
    note,
    records,
  };
  await axiosInstance.post(`/${base}/bulk/`, bulkInput, {
    ...baseAxiosConfig(accessToken),
  });
};

export const revertBulkRMAUpdate = async (
  accessToken: string,
  uuid: string,
  base: BaseRMAEndpoint,
) => {
  await axiosInstance.put(`/${base}/bulk/revert/${uuid}`, {
    ...baseAxiosConfig(accessToken),
  });
};

export function useRMAMedia(
  accessToken: string,
  base: BaseEndpoint,
  hash?: string | null,
) {
  return useQuery(
    [base, "media", accessToken, hash],
    async () =>
      accessToken && hash ? await getMedia(accessToken, hash, base) : null,
    { staleTime: RQUERY_STALE_TIME },
  );
}
